import React from "react";
import styled from "styled-components";

const Heading = ({ name, desc }) => {
  return (
    <Wrapper>
      <h3>{name}</h3>
      <p>{desc}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
margin: 0 0 40px 0;

h3 {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0 0 10px 0;
}

p {
    color: #444444;
    font-size: .9rem;
}
`;

export default Heading;
