import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

//components
import Header from "../components/Header";
import AppLogin from "./AppLogin";
import Heading from "../components/Heading";
import { toast } from "react-toastify";

//config
import { addUserApi, inviteHref } from "../firebase/globalConfig";

//icons
import { ArrowIosDownward } from "@styled-icons/evaicons-solid/ArrowIosDownward";
import { Copy } from "@styled-icons/boxicons-regular/Copy";

//redux
import { selectUser, selectUserData } from "../redux/appSlice";
import { useSelector } from "react-redux";

const AppUsersAdd = () => {
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);

  const roles = ["user", "admin"];
  const [roleOptions, setRoleOptions] = useState(false);
  const [inviteSent, isInviteSent] = useState(false);
  const [inviteUrl, setInviteUrl] = useState("");

  function copyTextToClipboard() {
    // Select the text you want to copy
    const textToCopy = document.querySelector(".invite-url");

    // Create a text area element and set its value to the text you want to copy
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy.textContent;

    // Append the text area to the document
    document.body.appendChild(textArea);

    // Select the text in the text area
    textArea.select();

    // Execute the copy command to copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary text area from the document
    document.body.removeChild(textArea);

    toast.success("Lietotāja uzaicinājama adrese ir nokopēta.");
  }

  const [formData, setFormData] = useState({
    email: "",
    class: "user",
    invitedBy: `${userData?.display_name}`,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${addUserApi}`, formData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      setInviteUrl(`${inviteHref}${response?.data.inviteId}`);
      isInviteSent(!inviteSent);
      toast.success("Izveidots jauns uzaicinājums.");
      // console.log(response);
    } catch (error) {
      // console.error(error);
      toast.error("Nevarējām izveidot uzaicinājumu. Pārbaudi e-pastu vēlreiz.");
    }
  };

  return (
    <Wrapper>
      {user ? (
        <>
          <Header />
          <div className="container">
            <Heading
              name={"Pievienot jaunu lietotāju"}
              desc={"Izveido piekļuves adresi jaunam lietotājam"}
            />

            <div className="add-container">
              {inviteSent ? (
                <>
                  <h3 className="invite-sent-alert">
                    Izveidota sistēmas piekļuve e-pastam
                  </h3>
                  <p className="invite-sent-email">{formData?.email}</p>

                  <div className="invite-url-container">
                    <p className="invite-url">{inviteUrl}</p>
                    <div
                      className="invite-url-copy"
                      onClick={() => copyTextToClipboard()}
                    >
                      <Copy className="icon" />
                    </div>
                  </div>
                </>
              ) : (
                <form
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                  onSubmit={handleSubmit}
                >
                  <div className="add-container-input-container">
                    <input
                      type="email"
                      name="email"
                      placeholder="E-pasts"
                      className="add-container-input"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div
                    className="add-container-options"
                    onClick={() => setRoleOptions(!roleOptions)}
                  >
                    <p className="add-container-selected">{formData?.class}</p>
                    <ArrowIosDownward
                      className="icon"
                      style={{
                        transform: roleOptions
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </div>

                  {roleOptions && (
                    <div className="add-container-option-list">
                      {roles.map((item, index) => (
                        <p
                          key={index}
                          onClick={() => {
                            setFormData({ ...formData, class: item });
                            setRoleOptions(!roleOptions);
                          }}
                        >
                          {item}
                        </p>
                      ))}
                    </div>
                  )}

                  <button type="submit" className="add-container-submit-btn">
                    <p>Pievienot lietotāju</p>
                  </button>
                </form>
              )}
            </div>
          </div>
        </>
      ) : (
        <AppLogin />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .add-container {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid var(--lightgrey);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .invite-url-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--grey);
        border-radius: 5px;
        margin: 20px 0 0 0;
        width: 100%;

        .invite-url {
          font-size: 0.9rem;
          padding: 0 0 0 15px;
          overflow: hidden;
        }

        .invite-url-copy {
          background: var(--green);
          height: 100%;
          padding: 12px 15px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          cursor: pointer;

          .icon {
            color: var(--white);
          }
        }

        .invite-url-copy:hover {
          opacity: 0.7;
        }
      }

      .invite-sent-alert {
        font-size: 1.2rem;
        color: var(--black);
      }

      .invite-sent-email {
        font-size: 0.9rem;
        color: var(--black);
      }

      .add-container-option-list {
        border: 1px solid var(--lightgrey);
        border-radius: 5px;

        p {
          font-size: 0.9rem;
          color: var(--black);
          padding: 15px 20px;
          cursor: pointer;
          text-transform: capitalize;
        }

        p:hover {
          background: var(--lightgrey);
        }
      }

      .add-container-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--lightgrey);
        border-radius: 5px;
        padding: 15px 20px;
        cursor: pointer;
        position: relative;

        .add-container-selected {
          font-size: 0.9rem;
          color: var(--black);
          text-transform: capitalize;
        }
      }

      .icon {
        max-width: 24px;
        min-width: 24px;
        width: 100%;
        color: var(--black);
        transition: 0.1s ease-in-out;
      }

      .add-container-input-container {
        width: 100%;

        .add-container-input {
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
        }
      }
      .add-container-submit-btn {
        width: 100%;
        padding: 15px;
        background: var(--orange);
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        outline: none !important;
        border: none !important;

        p {
          font-size: 0.9rem;
          color: var(--white);
        }
      }

      .add-container-submit-btn:hover {
        opacity: 0.7;
      }
    }
  }
`;

export default AppUsersAdd;
