import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

//config
import {
  appVersion,
  getPanelDataApi,
  getPanelActivitiesApi,
} from "../firebase/globalConfig";

//components
import Header from "../components/Header";
import AppLogin from "./AppLogin";
import Heading from "../components/Heading";
import Banner from "../components/Banner";
import GridLoader from "react-spinners/GridLoader";

//icons
import { Category } from "@styled-icons/material-twotone/Category";
import { Tags } from "@styled-icons/bootstrap/Tags";
import { Users } from "@styled-icons/heroicons-outline/Users";

//redux
import { useSelector } from "react-redux";
import { selectUser, selectUserData } from "../redux/appSlice";

//router
import { Link } from "react-router-dom";

//hemlet
import { Helmet } from "react-helmet";

const AppPanel = () => {
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);

  const [loading, setLoading] = useState(true);
  const [welcomeMsg, setWelcomeMsg] = useState();
  const [data, setData] = useState(null);

  //badges
  const [productsAddedToday, setProductsAddedToday] = useState(0);

  function getGreeting() {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour < 12) {
      return "Labrīt"; // Morning
    } else if (currentHour < 18) {
      return "Labdien"; // Afternoon
    } else {
      return "Labvakar"; // Evening
    }
  }

  function countItemsCreatedToday(data) {
    const today = new Date();
    const todayString = today.toISOString().split("T")[0]; // Get today's date in 'YYYY-MM-DD' format

    const createdToday = data.filter((item) => {
      const createdDate = new Date(item.product_created);
      const createdDateString = createdDate.toISOString().split("T")[0]; // Get item's creation date in 'YYYY-MM-DD' format
      return createdDateString === todayString;
    });

    setProductsAddedToday(createdToday.length);
  }

  const getData = async () => {
    const response = await axios.get(`${getPanelDataApi}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;
    setData(data);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [activities, setActivities] = useState(null);
  const [notificationsAddedToday, setNotificationsAddedToday] = useState(0);

  function countNotificationsCreatedToday(data) {
    const today = new Date();
    const todayString = today.toISOString().split("T")[0]; // Get today's date in 'YYYY-MM-DD' format

    const createdToday = data.filter((item) => {
      const createdDate = new Date(item.created);
      const createdDateString = createdDate.toISOString().split("T")[0]; // Get item's creation date in 'YYYY-MM-DD' format
      return createdDateString === todayString;
    });

    setNotificationsAddedToday(createdToday.length);
  }

  const getActivities = async () => {
    const response = await axios.get(`${getPanelActivitiesApi}${currentPage}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;
    setActivities(data);
    setTotalPages(data?.totalPages);

    // console.log(data);
    setLoading(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const pageNumbers = [...Array(totalPages).keys()].map((page) => page + 1);

  useEffect(() => {
    const greeting = getGreeting();
    setWelcomeMsg(greeting); //welcoming message left corner
    getData(); //getting data

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getActivities();

    //eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (data) {
      countItemsCreatedToday(data?.last_products);
    } //count items created today
  }, [data]);

  useEffect(() => {
    if (activities) {
      countNotificationsCreatedToday(activities?.notifications);
    } //count items created today
  }, [activities]);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Instrumentu panelis - Citrent</title>
      </Helmet>

      {user ? (
        <>
          <Header />
          <div className="container">
            <Heading
              name={`${welcomeMsg}!`}
              desc={"Šeit ir pārskats par jūsu produktu sistēmu"}
            />

            {loading ? (
              <>
                <LoadingContainer>
                  <GridLoader color="#ff6a00" />
                </LoadingContainer>
              </>
            ) : (
              <>
                <div className="banners">
                  <Banner
                    Icon={Category}
                    name={"Kategorijas"}
                    count={data?.row_counts?.category_count}
                  />
                  <Banner
                    Icon={Tags}
                    name={"Tehnika"}
                    count={data?.row_counts?.product_count}
                  />
                  {(userData?.class === "admin" ||
                    userData?.class === "dev") && (
                    <Banner
                      Icon={Users}
                      name={"Lietotāji"}
                      count={data?.row_counts?.user_count}
                    />
                  )}
                </div>

                <div className="info-boxes">
                  <div className="info-boxes-left">
                    <div className="info-boxes-data">
                      <div className="info-boxes-heading">
                        <h3>Pēdējās pievienotās tehnikas</h3>
                        {productsAddedToday > 0 && (
                          <div className="info-boxes-heading-badge">
                            {productsAddedToday}
                          </div>
                        )}
                      </div>
                      <div className="info-boxes-data-list">
                        {data?.last_products?.map((item, index) => {
                          const imageLink = item?.product_image;
                          const cleanedImageLink = imageLink.replace(
                            /^\/var\/www/,
                            "/"
                          );

                          const created = `${item?.product_created}`;
                          const createdDate = new Date(created);
                          const createdMonth = String(
                            createdDate.getMonth() + 1
                          ).padStart(2, "0"); // Month is zero-based
                          const createdDay = String(
                            createdDate.getDate()
                          ).padStart(2, "0");
                          const createdHours = String(
                            createdDate.getHours()
                          ).padStart(2, "0");
                          const createdMinutes = String(
                            createdDate.getMinutes()
                          ).padStart(2, "0");

                          const months = [
                            "JAN",
                            "FEB",
                            "MAR",
                            "APR",
                            "MAI",
                            "JŪN",
                            "JŪL",
                            "AUG",
                            "SEP",
                            "OKT",
                            "NOV",
                            "DEC",
                          ];

                          return (
                            <div className="info-boxes-item" key={index}>
                              <div className="info-boxes-item-badge">
                                <div className="info-boxes-item-badge-container">
                                  <h4>{months[createdMonth - 1]}</h4>
                                  <p>{createdDay}</p>
                                </div>
                              </div>
                              <div className="info-boxes-item-img">
                                <img src={cleanedImageLink} alt="item-img" />
                              </div>
                              <div className="info-boxes-item-data">
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  to={`/rent/${item?.product_category_slug}/${item?.product_slug}`}
                                >
                                  <h3>{item?.product_name}</h3>
                                </Link>
                                <p>Preces kods: {item?.product_number}</p>
                                <p>
                                  {`${createdHours}:${createdMinutes}`} •{" "}
                                  <img
                                    src={item?.avatar}
                                    alt="user-avatar"
                                    className="user-avatar"
                                  />{" "}
                                  {item?.display_name}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="info-boxes-data">
                      <div className="info-boxes-heading">
                        <h3>Vairāk apskatītās tehnikas</h3>
                      </div>
                      <div className="info-boxes-data-list">
                        {data?.top_viewed_products?.map((item, index) => {
                          const imageLink = item?.product_image;
                          const cleanedImageLink = imageLink.replace(
                            /^\/var\/www/,
                            "/"
                          );

                          const created = `${item?.product_created}`;
                          const createdDate = new Date(created);
                          const createdYear = createdDate.getFullYear();
                          const createdMonth = String(
                            createdDate.getMonth() + 1
                          ).padStart(2, "0"); // Month is zero-based
                          const createdDay = String(
                            createdDate.getDate()
                          ).padStart(2, "0");
                          const createdHours = String(
                            createdDate.getHours()
                          ).padStart(2, "0");
                          const createdMinutes = String(
                            createdDate.getMinutes()
                          ).padStart(2, "0");

                          return (
                            <div className="info-boxes-item" key={index}>
                              <div className="info-boxes-item-badge">
                                <div className="info-boxes-item-badge-container">
                                  <h4>SKATĪTS</h4>
                                  <p>{item?.product_views}</p>
                                </div>
                              </div>
                              <div className="info-boxes-item-img">
                                <img src={cleanedImageLink} alt="item-img" />
                              </div>
                              <div
                                className="info-boxes-item-data"
                                style={{ maxWidth: 250 }}
                              >
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  to={`/rent/${item?.product_category_slug}/${item?.product_slug}`}
                                >
                                  <h3>{item?.product_name}</h3>
                                </Link>
                                <p>Preces kods: {item?.product_number}</p>
                                <p>
                                  {`${createdHours}:${createdMinutes} ${createdDay}.${createdMonth}.${createdYear}`}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="info-boxes-right">
                    <div className="info-boxes-data">
                      <div className="info-boxes-heading">
                        <h3>Jaunākās aktivitātes</h3>
                        {notificationsAddedToday > 0 && (
                          <div className="info-boxes-heading-badge">
                            {notificationsAddedToday}
                          </div>
                        )}
                      </div>

                      <div className="pagination">
                        <div className="pages">
                          {pageNumbers?.map((page) => (
                            <p
                              key={page}
                              className={page === currentPage ? "active" : ""}
                              onClick={() => handlePageChange(page)}
                            >
                              {page}
                            </p>
                          ))}
                        </div>
                      </div>

                      <div
                        className="info-boxes-data-list"
                        style={{ maxHeight: 665 }}
                      >
                        {activities?.notifications?.map((item, index) => {
                          const created = `${item?.created}`;
                          const createdDate = new Date(created);
                          const createdYear = createdDate.getFullYear();
                          const createdMonth = String(
                            createdDate.getMonth() + 1
                          ).padStart(2, "0"); // Month is zero-based
                          const createdDay = String(
                            createdDate.getDate()
                          ).padStart(2, "0");
                          const createdHours = String(
                            createdDate.getHours()
                          ).padStart(2, "0");
                          const createdMinutes = String(
                            createdDate.getMinutes()
                          ).padStart(2, "0");

                          return (
                            <div className="info-boxes-item" key={index}>
                              <div className="info-boxes-item-avatar">
                                <img
                                  src={item?.avatar}
                                  alt="user-avatar"
                                  className="user-avatar"
                                  style={{ marginLeft: 2 }}
                                />
                              </div>

                              <div
                                className="info-boxes-item-data"
                                style={{ maxWidth: "400px" }}
                              >
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  to={item?.noti_link}
                                >
                                  <div className="info-boxes-notification-text">
                                    <p>
                                      {item?.display_name} {item?.noti_text}
                                    </p>
                                  </div>
                                </Link>
                                <p>{`${createdHours}:${createdMinutes} ${createdDay}.${createdMonth}.${createdYear}`}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                <p
                  style={{
                    textAlign: "center",
                    color: "var(--grey)",
                    fontSize: 12,
                  }}
                >
                  citrentapp version: {appVersion}
                </p>
              </>
            )}
          </div>
        </>
      ) : (
        <AppLogin />
      )}
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .pagination {
      margin: 20px 0 0 0;

      .pages {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        gap: 10px;
        margin: 0 0 20px 0;

        p {
          border-radius: 50%;
          background: var(--lightgrey);
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.9rem;
          cursor: pointer;
        }

        .active {
          background-color: var(--orange);
          color: white;
        }
      }
    }

    .banners {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }

    .info-boxes {
      margin: 40px 0 60px 0;
      display: flex;
      gap: 20px;
      justify-content: space-between;

      @media only screen and (max-width: 820px) {
        flex-direction: column-reverse;
      }

      .info-boxes-data {
        .info-boxes-heading {
          display: flex;
          gap: 20px;
          justify-content: space-between;
          margin: 0 0 20px 0;

          h3 {
            color: var(--black);
            font-size: 1.2rem;
          }

          .info-boxes-heading-badge {
            display: flex;
            font-size: 0.9rem;
            background: var(--orange);
            padding: 3px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--white);
            border-radius: 5px;
            font-weight: 600;
          }
        }

        .info-boxes-data-list {
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          padding: 20px;
          max-height: 300px;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .info-boxes-item {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            border-bottom: 1px solid var(--lightgrey);
            padding: 0 0 20px 0;

            .info-boxes-item-avatar {
              .user-avatar {
                height: 40px;
                width: 40px;
                border-radius: 50%;
              }
            }

            .info-boxes-notification-text {
              p {
                color: var(--black) !important;
                line-height: 1.4;
              }
            }

            .info-boxes-item-badge {
              .info-boxes-item-badge-container {
                border: 1px solid var(--lightgrey);
                text-align: center;
                display: inline-block;
                border-radius: 5px;

                h4 {
                  font-size: 0.9rem;
                  color: #444444;
                  background-color: var(--grey);
                  padding: 3px 6px;
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                }

                p {
                  font-size: 0.9rem;
                  padding: 5px 3px;
                  font-weight: 600;
                }
              }
            }

            .info-boxes-item-img {
              max-width: 70px;
              width: 100%;

              img {
                width: 100%;
                max-height: 40px;
                object-fit: contain;
                border-radius: 5px;
              }
            }

            .info-boxes-item-data {
              display: flex;
              flex-direction: column;
              gap: 5px;
              max-width: 300px;
              width: 100%;

              a {
                text-decoration: none;
                color: var(--black);
                transition: 0.3s ease-in-out;
              }

              a:hover {
                opacity: 0.5;
              }

              h3 {
                font-size: 0.9rem;
              }

              p {
                color: #898989;
                font-size: 0.9rem;
                display: flex;
                align-items: center;
                gap: 5px;

                .user-avatar {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }

      .info-boxes-left,
      .info-boxes-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
`;

export default AppPanel;
