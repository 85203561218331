import React from "react";
import styled from "styled-components";

//components
import PageHeading from "../components/PageHeading";

//hemlet
import { Helmet } from "react-helmet";

const CookiePolicy = () => {
  const text = `
  Pēdējās izmaiņas: 13.12.2023

  Šajā sīkdatņu politikā ir aprakstīts, kā mēs izmantojam sīkdatnes un citus līdzīgus tehnoloģiskus risinājumus mūsu vietnē. Tā ir izstrādāta, lai sniegtu detalizētu informāciju par to, kā mēs apkopojam, izmantojam un aizsargājam informāciju, ko iegūstam no sīkdatnēm.
  
  1. Kas ir sīkdatnes?
  Sīkdatne ir neliels teksta fails, ko vietne saglabā jūsu ierīcē, kad jūs to apmeklējat. Šie faili satur informāciju par jūsu vietnes izmantošanu, kas noderīga turpmākai pieredzes uzlabošanai un pielāgošanai lietotāja vajadzībām.
  
  2. Kāpēc mēs izmantojam sīkdatnes?
  Mēs izmantojam sīkdatnes, lai:
  
  Nodrošinātu vietnes darbību: Sīkdatnes ir nepieciešamas, lai nodrošinātu pareizu vietnes darbību, tai skaitā, lai ļautu jums pārvietoties pa lapām un izmantot tās funkcijas.
  
  Uzlabotu jūsu pieredzi: Mēs izmantojam sīkdatnes, lai saprastu, kā jūs izmantojat mūsu vietni, un piedāvātu jums pielāgotu saturu un piedāvājumus.
  
  Analizējam datus: Izmantojam Google Analytics un līdzīgus rīkus, lai analizētu anonīmus datus par vietnes lietošanu un sniegtu mums ieskatu par to, kā uzlabot lietotāja pieredzi.
  
  3. Kāda informācija tiek apkopota?
  Mēs varam apkopot šādu informāciju, izmantojot sīkdatnes:
  
  Lietotāja identifikatori: Unikālie identifikatori, kas ļauj mums atpazīt atsevišķus lietotājus.
  
  Lietotāja uzvedība: Informācija par to, kā lietotāji mijiedarbojas ar vietni, piemēram, lapu apmeklējumi, klikšķi un citi uzvedības modeļi.
  
  Ierīces informācija: Dati par ierīcēm, kurās tiek piekļūts vietnei, tostarp ierīces tips, operētājsistēma un ekrāna izšķirtspēja.
  
  4. Mūsu kontaktu formas un produktu interešu saglabāšana
  Ja jūs izmantojat mūsu vietni, iesniedzot kontaktu formas vai pievienojot produktus savām interesēm, mēs saglabāsim šādus datus:
  
  Kontaktu formas dati: Vārds, e-pasta adrese, tālruņa numurs (ja tas tiek sniegts), un ziņojuma teksts, ko jūs iesniedzat.
  
  Produktu interešu dati: Informācija par produktiem, ko jūs pievienojat savām interesēm, kas var tikt saglabāta vietnē vai mūsu lietotnes vietējā krātuvē (Redux).
  
  5. Kā jūs varat kontrolēt sīkdatnes?
  Jūs varat kontrolēt un dzēst sīkdatnes pēc savas izvēles. Parasti šo iestatījumu var veikt jūsu pārlūkprogrammā. Lūdzu, ņemiet vērā, ka, atteikšanās no sīkdatnēm var ietekmēt jūsu pieredzi, samazinot pieejamās funkcijas un personalizāciju.
  
  6. Atjauninājumi un saites
  Mēs paturam tiesības periodiski atjaunināt šo sīkdatņu politiku, lai atspoguļotu izmaiņas mūsu datu apstrādes praksēs vai likumdošanā. Jauniem noteikumiem stājas spēkā, tie tiks publicēti mūsu vietnē.
  
  7. Kontakti
  Ja jums ir jautājumi par mūsu sīkdatņu politiku, lūdzu, sazinieties ar mums:
  
  CitRent SIA
  Adrese: Brāļu Kaudzīšu iela 43, Latgales priekšpilsēta, Rīga, LV-1021, Latvija
  E-pasts: citrent@citrent.lv
  Tālrunis: +371 28 744 299
  `;

  const paragraphs = text.split("\n").map((line, index) => (
    <p
      key={index}
      style={{
        marginTop: line.trim().match(/^\d+\.\s/) ? "1em" : 0,
        fontWeight: line.trim().match(/^\d+\.\s/) ? "bold" : "normal",
        fontSize: line.trim().match(/^\d+\.\s/) ? "1.2rem" : ".9rem",
      }}
    >
      {line}
    </p>
  ));

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sīkfailu politika - Citrent</title>
      </Helmet>
      <PageHeading
        pageName={"Sīkfailu politika"}
        pageImage={"./images/pageHeading/about.png"}
      />
      <div className="wrapper">{paragraphs}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 0 200px 0;

    @media only screen and (max-width: 1140px) {
      margin: 0 20px;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.6;
    }
  }
`;

export default CookiePolicy;
