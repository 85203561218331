import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axios from "axios";

//config
import {
  getProductsApi,
  searchProductApi,
  deleteProductApi,
  getCategoriesApi,
} from "../firebase/globalConfig";

//components
import Header from "../components/Header";
import AppLogin from "./AppLogin";
import Heading from "../components/Heading";
import Banner from "../components/Banner";
import GridLoader from "react-spinners/GridLoader";
import UserBar from "../components/UserBar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";

//icons
import { Tags } from "@styled-icons/bootstrap/Tags";

//redux
import { useSelector } from "react-redux";
import { selectUser, selectUserData } from "../redux/appSlice";

const AppProducts = () => {
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showPagination, setShowPagination] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const [categories, setCategories] = useState(null);

  const getCategories = async () => {
    const response = await axios.get(`${getCategoriesApi}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;
    setCategories(data);
    // console.log(data);
  };

  const getProducts = async () => {
    getCategories();

    const response = await axios.get(`${getProductsApi}${currentPage}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;
    // console.log(data);

    setData(data);
    setTotalPages(data?.totalPages);
    setLoading(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const pageNumbers = [...Array(totalPages).keys()].map((page) => page + 1);

  const [searchText, setSearchText] = useState("");

  const searchProducts = async () => {
    const response = await axios.get(`${searchProductApi}${searchText}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;
    // console.log(data);
    setData(data);
  };

  const options = {
    title: "Vai dzēst tehniku?",
    message: "Tehnika tiks izdzēsta neatgriezeniski.",
    buttons: [
      {
        label: "Dzēst",
        onClick: () => {
          deleteProduct();
        },
      },
      {
        label: "Atcelt",
        onClick: () => {
          selectedProduct = null;
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    // willUnmount: () => {},
    // afterClose: () => {},
    // onClickOutside: () => {},
    // onKeypress: () => {},
    // onKeypressEscape: () => {},
    overlayClassName: "confirm-modal",
  };

  let selectedProduct = null;

  const deleteProduct = async () => {
    // console.log(selectedProduct);

    const created = new Date();
    created.setHours(created.getHours() + 2);

    // Format the date as needed
    const formattedCreated = created
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const deleteData = {
      id: selectedProduct?.id,
      email: user?.email,
      display_name: userData?.display_name,
      noti_type: "category_deleted",
      avatar: user?.photoURL,
      noti_text: `Izdzēsa tehniku ar nosaukumu ${selectedProduct?.product_name}`,
      created: formattedCreated,
    };

    try {
      await axios.post(`${deleteProductApi}`, deleteData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      getProducts();
      toast.success("Tehnika izdzēsta.");
    } catch (error) {
      toast.error("Nevarējām izdzēst tehniku. Mēģini vēlreiz.");
    }
  };

  useEffect(() => {
    getProducts();
    //eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (searchText?.length > 0) {
      setShowPagination(false);
      searchProducts();
    } else {
      getProducts();
      setShowPagination(true);
    }
    //eslint-disable-next-line
  }, [searchText]);

  return (
    <Wrapper>
      {user ? (
        <>
          <Header />
          <div className="container">
            <Heading name={"Tehnika"} desc={"Tehnikas saraksts"} />
            {loading ? (
              <>
                <LoadingContainer>
                  <GridLoader color="#ff6a00" />
                </LoadingContainer>
              </>
            ) : (
              <>
                <div className="container-top">
                  <Banner
                    Icon={Tags}
                    name={"Tehnika"}
                    count={data?.totalProducts || data?.products?.length}
                  />
                  <div className="search-box">
                    <div className="search-box-input">
                      <h3>Meklēt</h3>
                      <input
                        type="text"
                        className="search-input"
                        placeholder="Meklēt pēc tehnikas nosaukuma vai tehnikas numura"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>

                    <div className="search-box-btn">
                      <Link to="/app/products/add">
                        <div className="search-box-btn-action">
                          <p>Pievienot</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                {showPagination && (
                  <>
                    <div className="pagination">
                      <div className="pages">
                        {pageNumbers?.map((page) => (
                          <p
                            key={page}
                            className={page === currentPage ? "active" : ""}
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </p>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                <div className="container-bottom">
                  <div className="table-top">
                    <p>ID</p>
                    <p>Skatījumi</p>
                    <p>Attēls</p>
                    <p>Nosaukums</p>
                    <p style={{ marginLeft: 15, marginRight: 15 }}>
                      Kategorija
                    </p>
                    <p style={{ marginLeft: 15, marginRight: 15 }}>
                      Pievienoja
                    </p>
                    <p>Iespējas</p>
                  </div>

                  <div className="table-data">
                    {data?.products?.map((item, index) => {
                      const created = `${item?.product_created}`;
                      const edited = `${item?.product_edited}`;
                      const createdDate = new Date(created);
                      const editedDate = new Date(edited);

                      const createdYear = createdDate.getFullYear();
                      const createdMonth = String(
                        createdDate.getMonth() + 1
                      ).padStart(2, "0"); // Month is zero-based
                      const createdDay = String(createdDate.getDate()).padStart(
                        2,
                        "0"
                      );
                      const createdHours = String(
                        createdDate.getHours()
                      ).padStart(2, "0");
                      const createdMinutes = String(
                        createdDate.getMinutes()
                      ).padStart(2, "0");

                      const editedYear = editedDate.getFullYear();
                      const editedMonth = String(
                        editedDate.getMonth() + 1
                      ).padStart(2, "0");
                      const editedDay = String(editedDate.getDate()).padStart(
                        2,
                        "0"
                      );
                      const editedHours = String(
                        editedDate.getHours()
                      ).padStart(2, "0");
                      const editedMinutes = String(
                        editedDate.getMinutes()
                      ).padStart(2, "0");

                      const formattedCreated = `${createdDay}.${createdMonth}.${createdYear} ${createdHours}:${createdMinutes}`;
                      const formattedEdited = `${editedDay}.${editedMonth}.${editedYear} ${editedHours}:${editedMinutes}`;

                      let editedDatetime = null;

                      if (formattedCreated !== formattedEdited) {
                        editedDatetime = `${formattedEdited}`;
                      }

                      const link = item?.product_image;
                      const cleanedLink = link.replace(/^\/var\/www/, "/");

                      const categoryId = item?.product_category_id;
                      const category = categories?.filter(
                        (item) => item?.id === categoryId
                      );

                      return (
                        <div className="table-data-single" key={index}>
                          <div className="table-data-single-box">
                            {item?.id}
                          </div>
                          <div className="table-data-single-box">
                            {item?.product_views}
                          </div>
                          <div className="table-data-single-box">
                            <img
                              src={cleanedLink}
                              alt="product-img"
                              className="product-img"
                            />
                          </div>
                          <div className="table-data-single-box">
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              to={`/rent/${item?.product_category_slug}/${item?.product_slug}`}
                            >
                              <p
                                className="table-data-single-box-top"
                                style={{ marginBottom: 10 }}
                              >
                                {item?.product_name}
                              </p>
                            </Link>
                            <p className="table-data-single-box-bottom">
                              Pievienots: {formattedCreated}
                            </p>
                            <p className="table-data-single-box-bottom">
                              {editedDatetime !== null &&
                                ` Labots: ${formattedEdited}`}
                            </p>
                          </div>
                          <div
                            className="table-data-single-box"
                            style={{ marginLeft: 15, marginRight: 15 }}
                          >
                            {category[0]?.category_name}
                          </div>
                          <div
                            className="table-data-single-box"
                            style={{ marginLeft: 15, marginRight: 15 }}
                          >
                            <UserBar
                              img={item?.avatar}
                              name={item?.display_name}
                            />
                          </div>
                          <div className="table-data-single-box">
                            <Link to={`/app/products/edit/${item?.id}`}>
                              <div className="table-data-single-box-btn">
                                Labot
                              </div>
                            </Link>
                            <div
                              className="table-data-single-box-btn"
                              onClick={() => {
                                confirmAlert(options);
                                selectedProduct = item;
                              }}
                            >
                              Dzēst
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <AppLogin />
      )}
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .pagination {
      margin: 20px 0 0 0;

      .pages {
        display: flex;
        justify-content: end;
        flex-wrap: wrap;
        gap: 10px;

        p {
          border-radius: 50%;
          background: var(--lightgrey);
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.9rem;
          cursor: pointer;
        }

        .active {
          background-color: var(--orange);
          color: white;
        }
      }
    }

    .container-bottom {
      margin: 20px 0 0 0;
      border: 1px solid var(--lightgrey);
      border-radius: 5px;
      overflow: scroll;
      max-height: 600px;

      .table-top {
        padding: 20px;
        border-bottom: 1px solid var(--lightgrey);
        display: flex;
        gap: 10px;
        position: sticky;
        top: 0px;
        background: var(--white);
        min-width: 1050px;
        z-index: 80;

        p {
          font-size: 0.9rem;
          font-weight: 600;
          max-width: 200px;
          min-width: 120px;
          width: 100%;
        }

        p:nth-child(1) {
          max-width: 80px;
          min-width: 80px;
        }

        p:nth-child(2) {
          max-width: 100px;
          min-width: 100px;
        }

        p:nth-child(3) {
          max-width: 120px;
          min-width: 120px;
          margin-right: 20px;
        }

        p:nth-child(4) {
          max-width: 150px;
          min-width: 150px;
        }

        p:nth-child(5) {
          max-width: 140px;
          min-width: 140px;
        }
      }

      .table-data {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .table-data-single:hover {
          .product-img {
            transform: scale(1.4);
            height: 140px;
          }
        }

        .table-data-single {
          display: flex;
          gap: 10px;

          .table-data-single-box {
            font-size: 0.9rem;
            max-width: 200px;
            min-width: 120px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            a {
              color: var(--black);
              text-decoration: none;
              transition: 0.3s ease-in-out;
            }

            a:hover {
              opacity: 0.5;
            }

            .product-img {
              width: 100%;
              height: 60px;
              object-fit: contain;
              transition: 0.3s ease-in-out;
              border-radius: 5px;
            }

            .table-data-single-box-top {
              font-size: 0.9rem;
              font-weight: 600;
            }

            .table-data-single-box-bottom {
              font-size: 0.7rem;
              color: var(--grey);
            }

            .table-data-single-box-btn {
              font-size: 0.8rem;
              background: var(--black);
              color: var(--white);
              border-radius: 5px;
              display: inline-block;
              padding: 5px 10px;
              cursor: pointer;
              transition: 0.3s ease-in-out;
              margin: 0 10px 10px 0;
            }

            .table-data-single-box-btn:hover {
              opacity: 0.7;
            }
          }

          .table-data-single-box:nth-child(1) {
            max-width: 80px;
            min-width: 80px;
          }

          .table-data-single-box:nth-child(2) {
            max-width: 100px;
            min-width: 100px;
          }

          .table-data-single-box:nth-child(3) {
            max-width: 120px;
            min-width: 120px;
            margin-right: 20px;
            height: 60px;
            border-radius: 5px;
          }

          .table-data-single-box:nth-child(4) {
            max-width: 150px;
            min-width: 150px;
          }

          .table-data-single-box:nth-child(5) {
            max-width: 140px;
            min-width: 140px;
          }
        }
      }
    }

    .container-top {
      display: flex;
      gap: 10px;

      @media only screen and (max-width: 650px) {
        flex-direction: column;
      }

      .search-box {
        display: flex;
        flex: 1 0 0;
        gap: 10px;

        .search-box-input {
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
          justify-content: end;

          h3 {
            font-size: 1.2rem;
            color: var(--black);
            margin: 0 0 10px 0;
          }

          input {
            width: 100%;
            outline: none;
            border: 1px solid var(--lightgrey);
            border-radius: 5px;
            font-size: 0.9rem;
            padding: 10px 20px;
          }

          input::placeholder {
            font-size: 0.9rem;
            color: var(--grey);
          }
        }

        .search-box-btn {
          display: flex;
          align-items: flex-end;

          a {
            text-decoration: none;
            color: var(--white);
          }

          .search-box-btn-action {
            background: var(--orange);
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            transition: 0.3s ease-in-out;

            p {
              color: var(--white);
              font-size: 0.9rem;
            }
          }

          .search-box-btn-action:hover {
            background: var(--lightorange);
          }
        }
      }
    }
  }
`;

export default AppProducts;
