import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

//config
import { checkInviteApi, createUserApi } from "../firebase/globalConfig";

//icons
import { Google } from "@styled-icons/boxicons-logos/Google";

//components
import GridLoader from "react-spinners/GridLoader";
import { ToastContainer } from "react-toastify";

//firebase
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";

//redux
import { useSelector, useDispatch } from "react-redux";
import { selectLoading, updateLoading } from "../redux/appSlice";

const AppMain = ({ authType }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const globalLoading = useSelector(selectLoading);

  const { id } = params;
  const [inviteId] = useState(id);
  const [exists, setExists] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleCheckInviteId = async () => {
    try {
      const response = await axios.post(
        `${checkInviteApi}`,
        {
          inviteId,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      setExists({
        exists: response.data.exists,
        class: response.data.class,
        invitedBy: response.data.invitedBy,
        inviteId: response.data.inviteId,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  //google auth
  const googleProvider = new GoogleAuthProvider();

  const handleGoogleRegistration = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const userJoined = new Date();
      userJoined.setHours(userJoined.getHours() + 2);
      const formattedJoined = userJoined
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      const userData = {
        id: user.uid,
        email: user.email,
        displayName: user.displayName,
        class: exists?.class,
        invitedBy: exists?.invitedBy,
        inviteId: exists?.inviteId,
        avatar: user.photoURL,
        joined: formattedJoined,
      };

      const response = await axios.post(`${createUserApi}`, userData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });

      if (response.data.exists) {
        // Handle existing user
        // console.log("Lietotājs jau ir reģistrēts sistēmā.");
      } else {
        // Handle new user registration
        // console.log("Jauns lietotājs reģistrēts.");
        dispatch(updateLoading(true));
        navigate("/app");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoogleAuth = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (inviteId) {
      handleCheckInviteId();
    }

    //eslint-disable-next-line
  }, [inviteId]);

  return (
    <Wrapper>
      {authType === "invite" ? (
        <>
          {loading ? (
            <>
              <GridLoader color="#ff6a00" />
            </>
          ) : (
            <>
              <div className="login-container">
                {exists?.exists ? (
                  <>
                    <p className="invite-heading invite-heading-success">
                      Tu esi saņēmis uzaicinājumu pievienoties citrent sistēmā.
                    </p>

                    <img
                      src="/images/main/logo.png"
                      alt="citrent-logo"
                      className="login-logo"
                    />
                    <div
                      className="login-btn"
                      onClick={handleGoogleRegistration}
                    >
                      <Google className="icon" />
                      <p>Reģistrēties sistēmā izmantojot Google</p>
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src="/images/main/logo.png"
                      alt="citrent-logo"
                      className="login-logo"
                    />

                    <p className="invite-heading invite-heading-denied">
                      Izskatās, ka neesi saņēmis uzaicinājumu.
                    </p>
                  </>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {globalLoading ? (
            <GridLoader color="#ff6a00" />
          ) : (
            <>
              <div className="login-container">
                <img
                  src="/images/main/logo.png"
                  alt="citrent-logo"
                  className="login-logo"
                />
                <div className="login-btn" onClick={handleGoogleAuth}>
                  <Google className="icon" />
                  <p>Ienākt sistēmā izmantojot Google</p>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* background-color: var(--black); */
  background-color: var(--white);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  .login-container {
    border: 1px solid var(--grey);
    background-color: var(--black);
    border-radius: 10px;
    padding: 20px;
    display: inline-block;
    text-align: center;
    max-width: 350px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

    .invite-heading {
      font-size: 0.9rem;
      /* font-weight: 600; */
      margin: 0 0 10px 0;
      padding: 5px 10px;
      color: var(--black);
    }

    .invite-heading-success {
      background-color: #62ba61;
    }

    .invite-heading-denied {
      background-color: #dd1a42;
      color: var(--white);
    }

    .login-logo {
      width: 200px;
      margin: 0 0 20px 0;
    }

    .login-btn {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      background-color: var(--orange);
      padding: 10px 20px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.4s ease-in-out;

      p {
        font-size: 0.9rem;
        color: var(--white);
      }

      .icon {
        max-width: 24px;
        min-width: 24px;
        width: 100%;
        color: var(--white);
      }
    }

    .login-btn:hover {
      background: var(--lightorange);
    }
  }
`;

export default AppMain;
