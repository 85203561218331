import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

//config
import { checkUserApi, updateUserApi } from "../firebase/globalConfig";

//components
import Header from "../components/Header";
import AppLogin from "./AppLogin";
import Heading from "../components/Heading";
import GridLoader from "react-spinners/GridLoader";
import { toast } from "react-toastify";

//icons
import { ArrowIosDownward } from "@styled-icons/evaicons-solid/ArrowIosDownward";

//redux
import { useSelector } from "react-redux";
import { selectUser } from "../redux/appSlice";

const AppUsersEdit = () => {
  const user = useSelector(selectUser);
  const params = useParams();
  const { id } = params;
  const [selectedId] = useState(id);
  const navigate = useNavigate();

  const categories = ["user", "admin"];
  const [category, setCategory] = useState(null);
  const [categoriesOptions, setCategoriesOptions] = useState(false);
  const [name, setName] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUser = async () => {
    const response = await axios.post(
      `${checkUserApi}`,
      {
        googleId: selectedId,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      }
    );
    if (response.data.exists) {
      // If the Google ID exists in the database, update the user state
      setCategory(response.data.userData.class);
      setName(response.data.userData.display_name);
      setAvatar(response.data.userData.avatar);
      setEmail(response.data.userData.email);
      setLoading(false);
    }
  };

  const editUser = async () => {
    setLoading(true);
    const editData = {
      google_id: selectedId,
      display_name: name,
      class: category,
      email: email,
    };

    try {
      const response = await axios.put(`${updateUserApi}`, editData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      console.log(response.data);
      toast.success("Izmaiņas saglabātas veiksmīgi.");

      setTimeout(() => {
        navigate("/app/users");
      }, 2000);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  useEffect(() => {
    if (selectedId !== null) {
      getUser();
    }

    //eslint-disable-next-line
  }, [selectedId]);

  return (
    <Wrapper>
      {user ? (
        <>
          <Header />
          <div className="container">
            <Heading
              name={"Labot lietotāja profilu"}
              desc={"Šeit tu vari labot informāciju lietotāja profilam"}
            />

            {loading ? (
              <>
                <LoadingContainer>
                  <GridLoader color="#ff6a00" />
                </LoadingContainer>
              </>
            ) : (
              <>
                <div className="add-container">
                  <>
                    <div className="add-container-input-container image">
                      <img src={avatar} alt="user-avatar" />
                    </div>

                    <div className="add-container-input-container">
                      <input
                        type="text"
                        placeholder="Vārds"
                        className="add-container-input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="add-container-input-container">
                      <input
                        type="text"
                        placeholder="E-pasts"
                        className="add-container-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled
                      />
                    </div>

                    <div
                      className="add-container-options"
                      onClick={() => setCategoriesOptions(!categoriesOptions)}
                    >
                      <p className="add-container-selected">{category}</p>
                      <ArrowIosDownward
                        className="icon"
                        style={{
                          transform: categoriesOptions
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </div>

                    {categoriesOptions && (
                      <div className="add-container-option-list">
                        {categories.map((item, index) => (
                          <p
                            key={index}
                            onClick={() => {
                              setCategory(item);
                              setCategoriesOptions(!categoriesOptions);
                            }}
                          >
                            {item}
                          </p>
                        ))}
                      </div>
                    )}

                    <div className="add-container-input-container">
                      <div
                        className="add-container-input-placeholder"
                        style={{ marginTop: 0 }}
                      >
                        Lietotāja kategorija
                      </div>
                    </div>

                    <div
                      className="add-container-submit-btn"
                      onClick={() => editUser()}
                    >
                      <p>Labot informāciju</p>
                    </div>
                  </>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <AppLogin />
      )}
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .add-container {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid var(--lightgrey);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .image {
        display: flex;
        justify-content: center;
        margin: 20px 0;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: contain;
        }
      }

      .invite-url-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--grey);
        border-radius: 5px;
        margin: 20px 0 0 0;
        width: 100%;

        .invite-url {
          font-size: 0.9rem;
          padding: 0 0 0 15px;
          overflow: hidden;
        }

        .invite-url-copy {
          background: var(--green);
          height: 100%;
          padding: 12px 15px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          cursor: pointer;

          .icon {
            color: var(--white);
          }
        }

        .invite-url-copy:hover {
          opacity: 0.7;
        }
      }

      .invite-sent-alert {
        font-size: 1.2rem;
        color: var(--black);
      }

      .invite-sent-email {
        font-size: 0.9rem;
        color: var(--black);
      }

      .add-container-option-list {
        border: 1px solid var(--lightgrey);
        border-radius: 5px;

        p {
          font-size: 0.9rem;
          color: var(--black);
          padding: 15px 20px;
          cursor: pointer;
          text-transform: capitalize;
        }

        p:hover {
          background: var(--lightgrey);
        }
      }

      .add-container-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--lightgrey);
        border-radius: 5px;
        padding: 15px 20px;
        cursor: pointer;
        position: relative;

        .add-container-selected {
          font-size: 0.9rem;
          color: var(--black);
          text-transform: capitalize;
        }
      }

      .icon {
        max-width: 24px;
        min-width: 24px;
        width: 100%;
        color: var(--black);
        transition: 0.1s ease-in-out;
      }

      .add-container-input-container {
        width: 100%;

        .add-container-input-textarea {
          resize: vertical;
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
          min-height: 150px;
          font-family: Inter;
        }

        .add-container-input-image {
          width: 100%;
          height: 250px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .no-image {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--lightgrey);

            .icon {
              max-width: 24px;
              min-width: 24px;
              color: var(--black);
            }
          }
        }

        .add-container-input {
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
        }

        .add-container-input:disabled::placeholder {
          color: lightgrey;
        }

        .add-container-input-placeholder {
          font-size: 0.9rem;
          margin: 10px 0 20px 0;
          color: var(--grey);
        }
      }
      .add-container-submit-btn {
        width: 100%;
        padding: 15px;
        background: var(--orange);
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        p {
          font-size: 0.9rem;
          color: var(--white);
        }
      }

      .add-container-submit-btn:hover {
        opacity: 0.7;
      }
    }
  }
`;

export default AppUsersEdit;
