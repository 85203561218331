import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

//config
import { checkCategoryApi, updateCategoryApi } from "../firebase/globalConfig";

//components
import Header from "../components/Header";
import AppLogin from "./AppLogin";
import Heading from "../components/Heading";
import GridLoader from "react-spinners/GridLoader";
import { toast } from "react-toastify";

//redux
import { useSelector } from "react-redux";
import { selectUser } from "../redux/appSlice";

const AppCategoriesEdit = () => {
  const user = useSelector(selectUser);
  const params = useParams();
  const { id } = params;
  const [selectedId] = useState(id);
  const navigate = useNavigate();

  const [categoryName, setCategoryName] = useState(null);
  const [categoryLink, setCategoryLink] = useState(null);
  const [categoryIcon, setCategoryIcon] = useState(null);
  const [loading, setLoading] = useState(true);

  const getCategory = async () => {
    const response = await axios.post(
      `${checkCategoryApi}`,
      {
        id: selectedId,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      }
    );
    if (response.data.exists) {
      // console.log(response.data);
      setCategoryName(response.data.data.category_name);
      setCategoryLink(response.data.data.link);
      setCategoryIcon(response.data.data.category_icon);
      setLoading(false);
    }
  };

  const editCategory = async () => {
    const created = new Date();
    created.setHours(created.getHours() + 2);
    const formattedCreated = created
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setLoading(true);

    const editData = {
      id: `${selectedId}`,
      category_link: `${categoryLink}`,
      category_name: `${categoryName}`,
      category_icon: `${categoryIcon}`,
      display_name: `${user?.displayName}`,
      avatar: `${user?.photoURL}`,
      email: `${user?.email}`,
      noti_text: `Laboja informāciju kategorijai ${categoryName}`,
      noti_link: `/rent/${categoryLink}`,
      created: formattedCreated,
      edited: formattedCreated,
    };

    console.log(editData);

    try {
      const response = await axios.put(`${updateCategoryApi}`, editData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      // console.log(response.data);
      toast.success("Izmaiņas saglabātas veiksmīgi.");

      setTimeout(() => {
        navigate("/app/categories");
      }, 2000);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const categoryImages = Array.from({ length: 49 }, (_, i) => ({
    path: `${i + 1}.svg`,
    selected: false,
  }));

  const [images, setImages] = useState(categoryImages);

  const selectImage = (index) => {
    const updatedImages = categoryImages;
    updatedImages[index].selected = true;
    setCategoryIcon(updatedImages[index].path);

    setImages(() => {
      const updatedImages = [...categoryImages];
      updatedImages[index].selected = true;
      return updatedImages;
    });
  };

  useEffect(() => {
    if (selectedId !== null) {
      getCategory();
    }

    //eslint-disable-next-line
  }, [selectedId]);

  return (
    <Wrapper>
      {user ? (
        <>
          <Header />
          <div className="container">
            <Heading
              name={`Labot ${categoryName} kategoriju`}
              desc={"Šeit tu vari labot kategorijas informāciju"}
            />

            {loading ? (
              <>
                <LoadingContainer>
                  <GridLoader color="#ff6a00" />
                </LoadingContainer>
              </>
            ) : (
              <>
                <div className="add-container">
                  <>
                    <div className="add-container-input-container">
                      <input
                        type="text"
                        placeholder="Nosaukums"
                        className="add-container-input"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="add-container-input-container">
                      <input
                        type="text"
                        placeholder="Kategorijas links"
                        className="add-container-input"
                        value={categoryLink}
                        onChange={(e) => setCategoryLink(e.target.value)}
                      />
                    </div>

                    <div className="add-container-input-container">
                      <div
                        className="add-container-input-placeholder"
                        style={{ marginTop: 0 }}
                      >
                        Kategorijas links (jābūt bez mīkstinājuma zīmēm).{" "}
                        <span style={{ color: "#D0312D" }}>
                          Ja kategorijā jau ir pievienota kāda tehnika, šo lauku
                          nemainīt!
                        </span>
                      </div>
                    </div>

                    <div className="add-container-input-container">
                      <div className="add-container-input-category-list">
                        {images.map((item, index) => (
                          <div
                            className="add-container-input-category-container"
                            key={index}
                            style={{
                              backgroundColor:
                                item?.selected && "var(--orange)",
                            }}
                            onClick={() => selectImage(index)}
                          >
                            <img
                              src={`/images/categories/${item?.path}`}
                              className="add-container-input-category-container"
                              alt="category-img"
                              style={{
                                backgroundColor:
                                  item?.selected && "var(--orange)",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>

                    <div
                      className="add-container-submit-btn"
                      onClick={() => editCategory()}
                    >
                      <p>Labot kategoriju</p>
                    </div>
                  </>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <AppLogin />
      )}
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .add-container {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid var(--lightgrey);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .invite-url-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--grey);
        border-radius: 5px;
        margin: 20px 0 0 0;
        width: 100%;

        .invite-url {
          font-size: 0.9rem;
          padding: 0 0 0 15px;
          overflow: hidden;
        }

        .invite-url-copy {
          background: var(--green);
          height: 100%;
          padding: 12px 15px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          cursor: pointer;

          .icon {
            color: var(--white);
          }
        }

        .invite-url-copy:hover {
          opacity: 0.7;
        }
      }

      .invite-sent-alert {
        font-size: 1.2rem;
        color: var(--black);
      }

      .invite-sent-email {
        font-size: 0.9rem;
        color: var(--black);
      }

      .add-container-option-list {
        border: 1px solid var(--lightgrey);
        border-radius: 5px;

        p {
          font-size: 0.9rem;
          color: var(--black);
          padding: 15px 20px;
          cursor: pointer;
          text-transform: capitalize;
        }

        p:hover {
          background: var(--lightgrey);
        }
      }

      .add-container-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--lightgrey);
        border-radius: 5px;
        padding: 15px 20px;
        cursor: pointer;
        position: relative;

        .add-container-selected {
          font-size: 0.9rem;
          color: var(--black);
          text-transform: capitalize;
        }
      }

      .icon {
        max-width: 24px;
        min-width: 24px;
        width: 100%;
        color: var(--black);
        transition: 0.1s ease-in-out;
      }

      .add-container-input-container {
        width: 100%;

        .add-container-input-category-list {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          .add-container-input-category-container {
            padding: 5px;
            border-radius: 5px;
            background-color: #ebebeb;
            width: 54px;
            min-width: 54px;
            height: 54px;
            max-height: 54px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 0 0;
            cursor: pointer;
            transition: 0.3s ease-in-out;

            img {
              width: 44px;
              max-width: 44px;
              height: 44px;
              object-fit: contain;
              color: var(--white);
              transition: 0.3s ease-in-out;
            }
          }

          .add-container-input-category-container:hover {
            background-color: var(--grey);

            img {
              background-color: var(--grey);
            }
          }
        }

        .add-container-input-textarea {
          resize: vertical;
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
          min-height: 150px;
          font-family: Inter;
        }

        .add-container-input-image {
          width: 100%;
          height: 250px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .no-image {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--lightgrey);

            .icon {
              max-width: 24px;
              min-width: 24px;
              color: var(--black);
            }
          }
        }

        .add-container-input {
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
        }

        .add-container-input-placeholder {
          font-size: 0.9rem;
          margin: 10px 0 20px 0;
          color: var(--grey);
        }
      }
      .add-container-submit-btn {
        width: 100%;
        padding: 15px;
        background: var(--orange);
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        p {
          font-size: 0.9rem;
          color: var(--white);
        }
      }

      .add-container-submit-btn:hover {
        opacity: 0.7;
      }
    }
  }
`;

export default AppCategoriesEdit;
