const apiLink = process.env.REACT_APP_API_LINK;

const getUsersApi = `${apiLink}/get_users.php`;
const deleteUserApi = `${apiLink}/delete_user.php`;

const addUserApi = `${apiLink}/add_invite.php`;
const inviteHref = "http://citrent.lv/app/invite/";

const checkUserApi = `${apiLink}/check_user.php`;
const updateUserApi = `${apiLink}/update_user.php`;

const checkInviteApi = `${apiLink}/check_invite.php`;
const createUserApi = `${apiLink}/create_user.php`;

const getCategoriesApi = `${apiLink}/categories/get_categories.php`;
const deleteCategoryApi = `${apiLink}/categories/delete_category.php`;

const addCategoryApi = `${apiLink}/categories/create_category.php`;

const checkCategoryApi = `${apiLink}/categories/check_category.php`;

const updateCategoryApi = `${apiLink}/categories/update_category.php`;

const createProductApi = `${apiLink}/products/create_product.php`;

const getProductsApi = `${apiLink}/products/get_products.php?per_page=15&page=`;

const searchProductApi = `${apiLink}/products/search_product.php?q=`;

const deleteProductApi = `${apiLink}/products/delete_product.php`;

const checkProductApi = `${apiLink}/products/check_product.php`;

const updateProductApi = `${apiLink}/products/update_product.php`;

const getPanelDataApi = `${apiLink}/panel/get_panel_data.php`;

const getPanelActivitiesApi = `${apiLink}/panel/get_panel_activities.php?per_page=50&page=`;

const checkProductIdApi = `${apiLink}/products/check_product_id.php?q=`;

const checkProductNameApi = `${apiLink}/products/check_product_name.php?q=`;

const checkCategoryNameApi = `${apiLink}/categories/check_category_name.php?q=`;

const getUserNotifications = `${apiLink}/get_user_notifications.php`;

const getLandingCategoriesApi = `${apiLink}/landing/get_categories.php`;

const getLandingRandomItems = `${apiLink}/landing/get_random_items.php`;

const searchLandingProduct = `${apiLink}/landing/search_product.php`;

const getProductsByCategory = `${apiLink}/rent/get_products.php`;

const getProductByCategoryAndName = `${apiLink}/rent/get_product.php`;

const sendEmailApi = `${apiLink}/email/send_email.php`;

const generateProductId = `${apiLink}/products/generate_product_id.php`;

const updateCategoryPositionApi = `${apiLink}/categories/update_category_position.php`;

const appVersion = "v1.0.4";

export {
  appVersion,
  getUsersApi,
  deleteUserApi,
  addUserApi,
  inviteHref,
  checkUserApi,
  updateUserApi,
  checkInviteApi,
  createUserApi,
  getCategoriesApi,
  deleteCategoryApi,
  addCategoryApi,
  checkCategoryApi,
  updateCategoryApi,
  createProductApi,
  getProductsApi,
  searchProductApi,
  deleteProductApi,
  checkProductApi,
  updateProductApi,
  getPanelDataApi,
  getPanelActivitiesApi,
  checkProductIdApi,
  checkCategoryNameApi,
  getUserNotifications,
  getLandingCategoriesApi,
  getLandingRandomItems,
  searchLandingProduct,
  getProductsByCategory,
  getProductByCategoryAndName,
  checkProductNameApi,
  sendEmailApi,
  generateProductId,
  updateCategoryPositionApi,
};

// LOGS BY APP VERSIONS

// V1.0.4
// Contact number, address update. Category height fix

// V1.0.3
// Transfered api's, auth to citrent

// V1.0.2
// Added API keys to all the API's available.

// V1.0.1
// Showcase version of the app, added some input check ups and changed links for the test version

// V1.0.0
// Early access version of the app, main functionalities created
