import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";

//components
import PageHeading from "../components/PageHeading";

//icons
import { KeyboardArrowRight } from "@styled-icons/material/KeyboardArrowRight";
import { KeyboardArrowLeft } from "@styled-icons/material/KeyboardArrowLeft";

//hemlet
import { Helmet } from "react-helmet";

const Services = () => {
  const params = useParams();

  let servicesDefault = [
    {
      name: "Mini ekskavatora pakalpojumi",
      active: false,
      link: "/services/mini-ekskavatora-pakalpojumi",
      href: "mini-ekskavatora-pakalpojumi",
      description: [
        "Mēs specializējamies dažādu zemes darbu veikšanā, piedāvājot mini ekskavatora pakalpojumus. Mūsu ekspertīze ietver tranšejās rakšanu kabeļu ieguldīšanai, teritorijas planēšanu, pamatu atrakšanu un vispārējus zemes darbus, kas nepieciešami kanalizācijas sistēmu izbūvei. Mini ekskavators ir aprīkots ar 1100 mm plakanu kausu, kā arī 500 mm un 300 mm rokamajiem kausiem, nodrošinot precīzu un efektīvu darbu.",
      ],
    },
    {
      name: "Ūdensvadu izbūve",
      active: false,
      link: "/services/udensvadu-izbuve",
      href: "udensvadu-izbuve",
      description: [
        "Mēs veicam ēku izbūvi un pieslēdzam tās gan pie pilsētas centralizētā ūdensvada sistēmas, gan pie esošajām akām vai dziļurbumiem, nodrošinot plašas un uzticamas ūdens piegādes iespējas.",
      ],
    },
    {
      name: "Lietus ūdens novadīšanas sistēmas izbūve",
      active: false,
      link: "/services/lietus-udens-novadisanas-sistemas-izbuve",
      href: "lietus-udens-novadisanas-sistemas-izbuve",
      description: [
        "Mūsu uzņēmums piedāvā klientiem iespēju uzstādīt lietus ūdens novadīšanas sistēmas, kas efektīvi aizsargā ēkas no mitruma un pelējuma, kā arī novērš gruntis un zālāju no izskalošanās, kas var izraisīt bruģakmens vai asfalta bojājumus. Šī sistēma ir paredzēta, lai lietus laikā savāktu ūdeni un novadītu to prom no ēkas, tādējādi samazinot ūdens daudzumu apkārt ēkai, kas var nonākt ēkas pamatos un zem tās. Liels lietus ūdens daudzums var izraisīt ēkas pamatu (betona) eroziju vai sabrukšanu, kā arī siltinātajām ēkām var sākt atdalīties no pamata armējums un dekoratīvais apmetums. Mēs nodrošinām efektīvu risinājumu, lai saglabātu ēku ilgtermiņā.",
      ],
    },
    {
      name: "Kanalizācijas sistēmas izbūve",
      active: false,
      link: "/services/kanalizacijas-sistemas-izbuve",
      href: "kanalizacijas-sistemas-izbuve",
      description: [
        "Mēs piedāvājam augstas kvalitātes kanalizācijas sistēmu izveidi, nodrošinot savienojumu ar pilsētas centralizēto kanalizācijas tīklu. Mūsu profesionāļi veiks bioloģiskās kanalizācijas attīrīšanas sistēmu uzstādīšanu, septiķu ieviešanu un infiltrācijas lauku izbūvi, nodrošinot visaptverošu risinājumu jūsu ēkas vajadzībām.",
      ],
    },
  ];

  const [title, setTitle] = useState('Pakalpojumi - Citrent');

  const [services, updateServices] = useState();
  
  useEffect(() => {

    if (services) {
      services.forEach((item) => {
        if (item.active) {
          setTitle(`${item.name} - Citrent`);
        }
      });
    }

    //eslint-disable-next-line
  }, [params, services]);

  useEffect(() => {
    const { id } = params;

    if (id === undefined) {
      servicesDefault.forEach((item) => {
        if (item.href === "mini-ekskavatora-pakalpojumi") {
          item.active = true;
        } else {
          item.active = false;
        }
      });

      updateServices(servicesDefault);
    } else {
      servicesDefault.forEach((item) => {
        if (item.href === `${id}`) {
          item.active = true;
        } else {
          item.active = false;
        }
      });

      updateServices(servicesDefault);
    }

    //eslint-disable-next-line
  }, [params]);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>

      <PageHeading
        pageName={"Pakalpojumi"}
        pageImage={"/images/pageHeading/services.png"}
      />

      <div className="wrapper">
        <div className="navigator">
          <p>Pakalpojumi</p>
          <KeyboardArrowRight className="icon" />
          {services?.map(
            (item, index) =>
              item?.active && (
                <Link to={item?.link} key={index}>
                  {item?.name}
                </Link>
              )
          )}
        </div>

        <div className="wrapper-content">
          <div className="wrapper-navigator">
            <h3>Pakalpojumu saraksts</h3>

            <div className="wrapper-navigator-list">
              {services?.map((item, index) => (
                <Link
                  to={item?.link}
                  key={index}
                  style={{
                    color: item?.active ? "var(--orange)" : "var(--black)",
                  }}
                >
                  {item?.active ? (
                    <KeyboardArrowRight className="icon" />
                  ) : (
                    <KeyboardArrowLeft className="icon" />
                  )}
                  {item?.name}
                </Link>
              ))}
            </div>
          </div>

          <div className="wrapper-data">
            <div className="wrapper-header">
              {services
                ?.filter((item) => item?.active)
                .map((item, index) => {
                  return (
                    <div className="wrapper-text" key={index}>
                      <h3>{item?.name}</h3>
                      <p>{item?.description}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .wrapper {
    max-width: 1100px;
    margin: 0 auto;

    @media only screen and (max-width: 1140px) {
      margin: 0 20px;
    }

    .wrapper-content {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      margin: 0 0 20px 0;

      @media only screen and (max-width: 700px) {
        flex-direction: column;
      }

      .wrapper-navigator {
        max-width: 300px;
        width: 100%;

        h3 {
          font-size: 1.2rem;
          margin: 0 0 20px 0;
        }

        .wrapper-navigator-list {
          a {
            display: flex;
            gap: 10px;
            align-items: flex-start;
            color: var(--black);
            text-decoration: none;
            margin: 0 0 10px 0;
            font-size: 0.9rem;

            .icon {
              max-width: 24px;
              width: 100%;
              position: relative;
              bottom: 1px;
            }
          }
        }
      }

      .wrapper-data {
        flex: 1;

        .wrapper-header {
          .wrapper-text {
            h3 {
              font-size: 1.2rem;
              margin: 0 0 20px 0;
            }

            p {
              font-size: 0.9rem;
              line-height: 1.4;
            }
          }
        }
      }
    }

    .navigator {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 3px;
      margin: 0 0 20px 0;

      p,
      a {
        font-size: 0.9rem;
        color: var(--black);
      }

      a {
        text-decoration: none;
        color: var(--grey) !important;
      }

      .icon {
        max-width: 24px;
        width: 100%;
        position: relative;
        top: 2px;
      }
    }
  }
`;

export default Services;
