import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

//icons
import { KeyboardArrowRight } from "@styled-icons/material/KeyboardArrowRight";
import { KeyboardArrowLeft } from "@styled-icons/material/KeyboardArrowLeft";
import { ArrowRightShort } from "@styled-icons/bootstrap/ArrowRightShort";
import { ShoppingCart } from "@styled-icons/heroicons-solid/ShoppingCart";

//components
import GridLoader from "react-spinners/GridLoader";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectCategories,
  updateContactItems,
  selectContactItems,
} from "../../app/redux/appSlice";

//config
import { searchLandingProduct } from "../../app/firebase/globalConfig";

//hemlet
import { Helmet } from "react-helmet";

const Rent = () => {
  const categoriesRedux = useSelector(selectCategories);
  const [categories, setCategories] = useState([]);

  const [products, setProducts] = useState([]);
  const [productsFound, setProductsFound] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedCategory] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  // Get a specific parameter
  const searchTextQuery = searchParams.get("query");
  const [searchText, setSearchText] = useState("");
  const [searchTextDebounced] = useDebounce(searchText, 400);

  const searchProducts = async (searchQuery) => {
    setLoading(true);
    const response = await axios.get(
      `${searchLandingProduct}?q=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      }
    );
    const data = response.data;
    setProducts(data?.products);

    if (data?.products?.length > 0) {
      setProductsFound(true);
    } else {
      setProductsFound(false);
    }

    // console.log(data);

    setLoading(false);
  };

  //redux
  const dispatch = useDispatch();
  const contactItems = useSelector(selectContactItems);

  const addContactItem = (item) => {
    window.scrollTo(0, 0);
    const contactItemsList = contactItems;

    const contactItemsFiltered = contactItemsList.filter(
      (i) => i?.product_number === item?.product_number
    );

    if (contactItemsFiltered?.length < 1) {
      const contactItemsUpdated = [...contactItemsList, item];

      dispatch(updateContactItems(contactItemsUpdated));
    }
  };

  useEffect(() => {
    if (searchTextQuery) {
      setSearchText(searchTextQuery);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchTextDebounced) {
      setSearchParams(`?query=${searchTextDebounced}`);
      searchProducts(searchTextDebounced);
    }

    //eslint-disable-next-line
  }, [searchTextDebounced]);

  useEffect(() => {
    if (categoriesRedux?.length > 0) {
      setCategories(categoriesRedux);
      // console.log(categoriesRedux);
    }
  }, [categoriesRedux]);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Meklēt - Citrent</title>
      </Helmet>
      <div className="wrapper">
        <div className="navigator">
          <p>Nomas katalogs</p>

          {selectedCategory !== undefined && (
            <>
              <KeyboardArrowRight className="icon" />{" "}
              <Link to={`/rent/${selectedCategory?.link}`}>
                {selectedCategory?.category_name}
              </Link>
            </>
          )}
        </div>

        <div className="wrapper-content">
          <div className="wrapper-navigator">
            <h3>Produktu grupas</h3>

            <div className="wrapper-navigator-list">
              {categories?.length > 0 && (
                <>
                  {categories?.map((item, index) => {
                    return (
                      <NavLink to={`/rent/${item?.link}`} key={index}>
                        {({ isActive }) => (
                          <>
                            {isActive ? (
                              <>
                                <KeyboardArrowRight className="icon" />
                                {item?.category_name}
                              </>
                            ) : (
                              <>
                                <KeyboardArrowLeft className="icon" />
                                {item?.category_name}
                              </>
                            )}
                          </>
                        )}
                      </NavLink>
                    );
                  })}
                </>
              )}
            </div>
          </div>

          <div className="wrapper-data">
            <div className="wrapper-header">
              <div className="wrapper-text">
                <h3>Meklēt</h3>
                <input
                  type="text"
                  className="search-input"
                  placeholder="Tehnikas meklēšana"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <div className="wrapper-text-filter">
                  {/* <p>Mūsu piedāvājumā ir {products?.length} produkti</p> */}
                </div>
              </div>
              {loading ? (
                <>
                  <LoadingContainer>
                    <GridLoader color="#ff6a00" />
                  </LoadingContainer>
                </>
              ) : (
                <>
                  {productsFound ? (
                    <>
                      <div className="item-list">
                        {products?.map((item, index) => {
                          const link = item?.product_image;
                          const cleanedLink = link.replace(/^\/var\/www/, "/");

                          return (
                            <div className="carousel-item" key={index}>
                              <div className="carousel-img-container">
                                <Link
                                  to={`/rent/${item?.product_category_slug}/${item?.product_slug}`}
                                  className="carousel-item-name"
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  <img src={cleanedLink} alt="carousel-img" />
                                </Link>
                              </div>
                              <div className="carousel-item-data">
                                <p className="carousel-item-code">
                                  Preces kods: {item?.product_number}
                                </p>
                                <Link
                                  to={`/rent/${item?.product_category_slug}/${item?.product_slug}`}
                                  className="carousel-item-name"
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  <h3>{item?.product_name}</h3>
                                  <ArrowRightShort className="icon arrow-right-short" />
                                </Link>

                                <div className="product-price-amount-wrapper">
                                  {parseInt(item?.product_price) === 0 ? (
                                    <>
                                      <p className="product-price-amount">
                                        Cena pēc pieteikuma
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="product-price-amount-large">
                                        €{item?.product_price}
                                      </p>

                                      <p className="product-price-amount-small">
                                        {`ar PVN 21% €${(
                                          item?.product_price * 1.21
                                        ).toFixed(2)}`}
                                      </p>
                                    </>
                                  )}
                                </div>

                                <Link
                                  to={`/contact`}
                                  onClick={() => addContactItem(item)}
                                >
                                  <div className="carousel-btn">
                                    <ShoppingCart className="icon" />
                                    <p className="carousel-btn-text">
                                      Uzzināt vairāk
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className="wrapper">
                      <div
                        style={{
                          padding: 10,
                          width: "100%",
                          textAlign: "center",
                          backgroundColor: "#D0312D",
                          marginTop: 20,
                        }}
                      >
                        <p style={{ fontSize: ".9rem", color: "var(--white)" }}>
                          Nav atrastu produktu vadoties pēc atslēgas vārda {""}"
                          {searchTextDebounced}"
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 20px auto 300px;

  @media only screen and (max-width: 1140px) {
    margin: 20px;
  }

  .navigator {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
    margin: 0 0 20px 0;

    p,
    a {
      font-size: 0.9rem;
      color: var(--black);
    }

    a {
      text-decoration: none;
      color: var(--grey) !important;
    }

    .icon {
      max-width: 24px;
      width: 100%;
      position: relative;
      top: 2px;
    }
  }

  .wrapper-content {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin: 0 0 20px 0;

    @media only screen and (max-width: 840px) {
      flex-direction: column;
    }

    .wrapper-navigator {
      max-width: 300px;
      width: 100%;

      h3 {
        font-size: 1.2rem;
        margin: 0 0 20px 0;
      }

      .wrapper-navigator-list {
        position: sticky;
        top: 150px;

        .active {
          color: var(--orange);
        }

        a {
          display: flex;
          gap: 10px;
          align-items: flex-start;
          color: var(--black);
          text-decoration: none;
          margin: 0 0 10px 0;
          font-size: 0.9rem;
          text-transform: uppercase;

          .icon {
            max-width: 24px;
            width: 100%;
            position: relative;
            bottom: 1px;
          }
        }
      }
    }

    .wrapper-data {
      flex: 1;

      .wrapper-header {
        .wrapper-text {
          .search-input {
            width: 100%;
            outline: none;
            border: 1px solid var(--lightgrey);
            border-radius: 5px;
            font-size: 0.9rem;
            padding: 15px 20px;
          }

          h3 {
            font-size: 1.2rem;
            margin: 0 0 20px 0;
          }

          p {
            font-size: 0.9rem;
            line-height: 1.4;
          }

          .wrapper-text-filter {
            display: flex;
            gap: 5px;

            #item-filter {
              outline: none;
              border: none;
              font-size: 0.9rem;
              font-weight: 600;
            }
          }
        }
      }

      .item-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 20px;
        margin: 20px 0 60px 0;

        @media only screen and (max-width: 530px) {
          gap: 10px;
        }

        .carousel-item {
          overflow: hidden;
          box-sizing: border-box;
          max-width: 31%;
          min-width: 180px;
          min-height: 260px;
          margin: 0 0 20px 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .product-price-amount-wrapper {
            .product-price-amount-large {
              font-size: 1.2rem;
              color: var(--black);
              font-weight: bold;
            }
          }

          @media only screen and (max-width: 840px) {
            max-height: 330px;
          }

          @media only screen and (max-width: 490px) {
            min-width: 140px;
          }

          .carousel-img-container {
            height: 200px;
            width: 100%;
            overflow: hidden;
            border-radius: 5px;

            @media only screen and (max-width: 840px) {
              height: 160px;
            }

            @media only screen and (max-width: 640px) {
              height: 140px;
            }
          }

          &:hover {
            .carousel-img-container {
              img {
                transform: scale(1.1);
              }
            }
          }

          img {
            object-fit: contain;
            height: 200px;
            width: 100%;
            transition: 0.3s ease-in-out;
            border-radius: 5px;

            @media only screen and (max-width: 840px) {
              height: 160px;
            }

            @media only screen and (max-width: 640px) {
              height: 140px;
            }
          }

          .carousel-item-data {
            margin: 20px 0 0 0;

            h3 {
              font-size: 0.9rem;
              max-width: 80%;
              color: var(--black);
              margin: 0 0 5px 0;
            }

            p {
              font-size: 0.8rem;
              color: var(--grey);
              transition: 0.3s ease;
            }

            a {
              text-decoration: none;
            }

            .carousel-item-name {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              margin: 15px 0;

              .icon {
                min-width: 24px;
                max-width: 24px;
                width: 100%;
                color: var(--orange);
                transition: 0.3s ease-in-out;
                position: relative;
                right: 10px;
              }

              &:hover {
                opacity: 0.7;

                .icon {
                  right: 0;
                }
              }
            }

            .carousel-btn {
              border: 1px solid var(--lightgrey);
              border-radius: 10px;
              padding: 10px 20px;
              display: flex;
              align-items: center;
              gap: 15px;
              margin: 20px 0 0 0;
              transition: 0.3s ease-in-out;

              .carousel-btn-text {
                color: var(--black);
                font-weight: 600;
              }

              .icon {
                min-width: 24px;
                max-width: 24px;
                width: 100%;
                color: var(--black);
                transition: 0.3s ease-in-out;
              }
            }

            .carousel-btn:hover {
              background: var(--green);

              .carousel-btn-text,
              .icon {
                color: var(--white);
              }
            }
          }
        }
      }
    }
  }
`;

export default Rent;
