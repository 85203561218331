import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

//config
import {
  getCategoriesApi,
  createProductApi,
  checkProductIdApi,
  checkProductNameApi,
  generateProductId,
} from "../firebase/globalConfig";

//components
import Header from "../components/Header";
import AppLogin from "./AppLogin";
import Heading from "../components/Heading";
import GridLoader from "react-spinners/GridLoader";
import { toast } from "react-toastify";

//icons
import { ArrowIosDownward } from "@styled-icons/evaicons-solid/ArrowIosDownward";
import { ImageNotSupported } from "@styled-icons/material-twotone/ImageNotSupported";

//redux
import { useSelector } from "react-redux";
import { selectUser, selectUserData } from "../redux/appSlice";

const AppUsersAdd = () => {
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  // console.log(userData);
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState(false);
  const [loading, setLoading] = useState(true);

  //image upload handler -- default is link
  const [uploadOption, setUploadOption] = useState("link");

  const handleOptionChange = (option) => {
    setUploadOption(option);
  };

  //inputs
  const [productNumber, setProductNumber] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState(null);
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [price, setPrice] = useState(0);

  const [descriptionLength, setDescriptionLength] = useState(1500);

  const calculateDescriptionLength = () => {
    let messageString = description.replace(/\s/g, "");
    setDescriptionLength((messageLength) => 1500 - messageString.length);
  };

  const getCategories = async () => {
    const response = await axios.get(`${getCategoriesApi}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;
    // console.log(data);
    setCategories(data);
    setCategory(data[0]);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const created = new Date();
    created.setHours(created.getHours() + 2);
    const formattedCreated = created
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    function replaceAccentedCharacters(inputString) {
      const accentedChars = "āčēģīķļņšūž";
      const replacementChars = "acegiklnsuz";

      return inputString.replace(
        new RegExp(`[${accentedChars}]`, "g"),
        (match) => replacementChars.charAt(accentedChars.indexOf(match))
      );
    }

    function createSlug(categoryName) {
      // Replace accented characters
      const slugWithoutAccents = replaceAccentedCharacters(categoryName);

      // Replace spaces with hyphens and convert to lowercase
      const slug = slugWithoutAccents.toLowerCase().replace(/\s+/g, "-");

      // Remove special characters
      const cleanedSlug = slug.replace(/[^a-z0-9-]/g, "");

      // Remove multiple hyphens
      const finalSlug = cleanedSlug.replace(/-+/g, "-");

      return finalSlug;
    }

    const productSlug = createSlug(name);

    const formData = {
      product_number: `${productNumber}`,
      product_name: `${name}`,
      product_category_id: `${category?.id}`,
      product_category_slug: `${category?.link}`,
      product_slug: `${productSlug}`,
      product_image: `${image}`,
      product_description: `${description}`,
      product_link: `${link}`,
      product_price: `${price}`,
      product_views: 0,
      product_created: formattedCreated,
      product_edited: formattedCreated,
      display_name: `${userData?.display_name}`,
      avatar: `${userData?.avatar}`,
      noti_type: "add_product",
      email: userData?.email,
      noti_text: `Pievienoja jaunu tehniku ar nosaukumu ${name}, kategorijā ${category?.category_name}.`,
      noti_link: `/rent/${category?.link}/${productSlug}`,
    };

    // console.log(formData);

    try {
      const response = await axios.post(`${createProductApi}`, formData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      // console.log(response);
      toast.success(`Pievienota jauna tehnika ar nosaukumu ${name}.`);

      setTimeout(() => {
        navigate("/app/products");
      }, 2000);
    } catch (error) {
      toast.error("Nevarējām pievienot jaunu tehniku. Mēģini vēlreiz.");
    }
  };

  const [productIdCheck, setProductIdCheck] = useState(false);

  const checkProductId = async () => {
    const response = await axios.get(`${checkProductIdApi}${productNumber}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;

    if (data?.products?.length > 0) {
      setProductIdCheck(true);
    } else {
      setProductIdCheck(false);
    }
  };

  const [productNameCheck, setProductNameCheck] = useState(false);

  const checkProductName = async () => {
    const response = await axios.get(`${checkProductNameApi}${name}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;

    if (data?.products?.length > 0) {
      setProductNameCheck(true);
    } else {
      setProductNameCheck(false);
    }
  };

  const generateProductNumber = async () => {
    const response = await axios.get(`${generateProductId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;

    if (data?.productNumber) {
      setProductNumber(data?.productNumber);
    }

    // console.log(data);
  };

  useEffect(() => {
    getCategories();
    generateProductNumber();
  }, []);

  useEffect(() => {
    if (productNumber) {
      checkProductId();
    } else {
      setProductIdCheck(false);
    }

    //eslint-disable-next-line
  }, [productNumber]);

  useEffect(() => {
    if (name) {
      checkProductName();
    } else {
      setProductNameCheck(false);
    }
    //eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    if (category !== null) {
      // console.log(category);
      setPrice((price) => 0);
    }
  }, [category]);

  useEffect(() => {
    setImage("");
  }, [uploadOption]);

  // useEffect(() => {
  //   console.log(image);
  // }, [image]);

  return (
    <Wrapper>
      {user ? (
        <>
          <Header />
          <div className="container">
            <Heading
              name={"Pievienot jaunu tehniku"}
              desc={
                "Šeit tu vari pievienot jaunu tehniku atbilstošajai kategorijai"
              }
            />

            {loading ? (
              <>
                <LoadingContainer>
                  <GridLoader color="#ff6a00" />
                </LoadingContainer>
              </>
            ) : (
              <>
                <form className="add-container" onSubmit={handleSubmit}>
                  <>
                    <div className="add-container-input-container">
                      <input
                        type="text"
                        placeholder="Preces Nr. (piem. CR123123)"
                        className="add-container-input"
                        value={productNumber}
                        onChange={(e) => setProductNumber(e.target.value)}
                        required
                        disabled
                      />

                      <div className="add-container-input-placeholder">
                        Preces Nr. tiek ģenerēts automātiski, vadoties pēc
                        tehnikas kopskaita.
                      </div>
                    </div>

                    {productIdCheck && (
                      <p style={{ fontSize: ".9rem", color: "#D0312D" }}>
                        Prece ar šādu ID ir reģistrēta.
                      </p>
                    )}

                    <div className="add-container-input-container">
                      <input
                        type="text"
                        placeholder="Nosaukums"
                        className="add-container-input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />

                      {productNameCheck && (
                        <p
                          style={{
                            fontSize: ".9rem",
                            color: "#D0312D",
                            marginTop: 10,
                          }}
                        >
                          Prece ar šādu nosaukumu ir pievienota.
                        </p>
                      )}

                      <div className="add-container-input-placeholder">
                        Piemērs, Putekļu sūcējs, 220V, Karcher NT35/1 AP.
                        Kategorija, galvenā specifikācija, produkta Nr.
                      </div>
                    </div>

                    <div
                      className="add-container-options"
                      onClick={() => setCategoriesOptions(!categoriesOptions)}
                    >
                      <p className="add-container-selected">
                        {category?.category_name}
                      </p>
                      <ArrowIosDownward
                        className="icon"
                        style={{
                          transform: categoriesOptions
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </div>

                    {categoriesOptions && (
                      <div className="add-container-option-list">
                        {categories.map((item, index) => (
                          <p
                            key={index}
                            onClick={() => {
                              setCategory(item);
                              setCategoriesOptions(!categoriesOptions);
                            }}
                          >
                            {item?.category_name}
                          </p>
                        ))}
                      </div>
                    )}

                    <div className="add-container-input-container">
                      <div
                        className="add-container-input-placeholder"
                        style={{ marginTop: 0 }}
                      >
                        Tehnikas kategorija
                      </div>
                    </div>

                    <div
                      className="add-container-input-container"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        id="uploadOptionLink"
                        type="radio"
                        name="uploadOption"
                        value="link"
                        checked={uploadOption === "link"}
                        onChange={() => handleOptionChange("link")}
                      />
                      <label
                        className="upload-option-label"
                        htmlFor="uploadOptionLink"
                      >
                        Attēla links
                      </label>

                      <input
                        id="uploadOptionFile"
                        type="radio"
                        name="uploadOption"
                        value="file"
                        checked={uploadOption === "file"}
                        onChange={() => handleOptionChange("file")}
                      />
                      <label
                        className="upload-option-label"
                        htmlFor="uploadOptionFile"
                      >
                        Ielādēt no faila
                      </label>
                    </div>

                    {uploadOption === "link" && (
                      <div className="add-container-input-container">
                        <input
                          type="text"
                          placeholder="Attēla links"
                          className="add-container-input"
                          value={image}
                          onChange={(e) => setImage(e.target.value)}
                          required
                        />
                      </div>
                    )}

                    {uploadOption === "file" && (
                      <div className="add-container-input-container">
                        <label
                          htmlFor="uploadFile"
                          className="upload-file-label"
                        >
                          Izvēlēties attēlu
                        </label>
                        <input
                          id="uploadFile"
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            const file = e.target.files[0];

                            const reader = new FileReader();
                            if (file) {
                              reader.readAsDataURL(e.target.files[0]);
                            }

                            reader.onload = (readerEvent) => {
                              setImage(readerEvent.target.result);
                            };
                          }}
                          required
                        />
                      </div>
                    )}

                    <div className="add-container-input-container">
                      {image ? (
                        <>
                          <div className="add-container-input-image">
                            <img src={image} alt="tehnikas bilde" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="add-container-input-image">
                            <div className="no-image">
                              <ImageNotSupported className="icon" />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="add-container-input-container">
                      <input
                        type="text"
                        placeholder="Tehnikas links (atsauce)"
                        className="add-container-input"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                      />

                      <div
                        className="add-container-input-placeholder placeholder-own-reference"
                        onClick={() => setLink((link) => "https://citrent.lv/")}
                      >
                        https://citrent.lv/
                      </div>
                    </div>

                    {category?.category_name === "Pārdošana" && (
                      <>
                        <div className="add-container-input-container">
                          <input
                            type="number"
                            placeholder="Cena bez pvn"
                            className="add-container-input"
                            min={0}
                            value={price}
                            step={0.01}
                            onChange={(e) => setPrice(e.target.value)}
                            required
                          />

                          <div className="add-container-input-placeholder">
                            Cena{" "}
                            {price > 0 && (
                              <>{`ar PVN 21% €${(price * 1.21).toFixed(2)}`}</>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="add-container-input-container">
                      <textarea
                        placeholder="Apraksts"
                        className="add-container-input-textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="1500"
                        onKeyUp={calculateDescriptionLength}
                        required
                      />
                      <p style={{ fontSize: ".9rem", textAlign: "end" }}>
                        Atlikušās rakstzīmes {descriptionLength}
                      </p>
                    </div>

                    {!productIdCheck && !productNameCheck && (
                      <>
                        <button
                          type="submit"
                          className="add-container-submit-btn"
                        >
                          <p>Pievienot tehniku</p>
                        </button>
                      </>
                    )}
                  </>
                </form>
              </>
            )}
          </div>
        </>
      ) : (
        <AppLogin />
      )}
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .add-container {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid var(--lightgrey);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .invite-url-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--grey);
        border-radius: 5px;
        margin: 20px 0 0 0;
        width: 100%;

        .invite-url {
          font-size: 0.9rem;
          padding: 0 0 0 15px;
          overflow: hidden;
        }

        .invite-url-copy {
          background: var(--green);
          height: 100%;
          padding: 12px 15px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          cursor: pointer;

          .icon {
            color: var(--white);
          }
        }

        .invite-url-copy:hover {
          opacity: 0.7;
        }
      }

      .invite-sent-alert {
        font-size: 1.2rem;
        color: var(--black);
      }

      .invite-sent-email {
        font-size: 0.9rem;
        color: var(--black);
      }

      .add-container-option-list {
        border: 1px solid var(--lightgrey);
        border-radius: 5px;

        p {
          font-size: 0.9rem;
          color: var(--black);
          padding: 15px 20px;
          cursor: pointer;
          text-transform: capitalize;
        }

        p:hover {
          background: var(--lightgrey);
        }
      }

      .add-container-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--lightgrey);
        border-radius: 5px;
        padding: 15px 20px;
        cursor: pointer;
        position: relative;

        .add-container-selected {
          font-size: 0.9rem;
          color: var(--black);
          text-transform: capitalize;
        }
      }

      .icon {
        max-width: 24px;
        min-width: 24px;
        width: 100%;
        color: var(--black);
        transition: 0.1s ease-in-out;
      }

      .add-container-input-container {
        width: 100%;

        #uploadFile {
          display: none;
        }

        .upload-file-label {
          display: inline-block;
          width: 100%;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          background-color: var(--lightorange);
          padding: 15px;
          cursor: pointer;
          font-size: 0.9rem;
          position: relative;
          text-align: center;
          color: white;
        }

        .upload-option-label {
          display: inline-block;
          width: 48%;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          padding: 15px 10px 15px 35px;
          cursor: pointer;
          font-size: 0.9rem;
          position: relative;
        }

        input[type="radio"] {
          display: none;
        }

        .upload-option-label:before {
          content: "";
          position: absolute;
          left: 10px;
          top: 15px;
          width: 18px;
          height: 18px;
          border: 2px solid var(--lightgrey);
          border-radius: 50%;
          background-color: #fff;
          transition: background-color 0.3s ease;
        }

        [type="radio"]:checked + .upload-option-label:before {
          border-color: var(--orange);
          background-color: var(--orange);
        }

        .add-container-input-textarea {
          resize: vertical;
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
          min-height: 250px;
          font-family: Inter;
        }

        .add-container-input-image {
          width: 100%;
          height: 250px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          .no-image {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--lightgrey);

            .icon {
              max-width: 24px;
              min-width: 24px;
              color: var(--black);
            }
          }
        }

        .add-container-input {
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
        }

        .add-container-input-placeholder {
          font-size: 0.9rem;
          margin: 10px 0 20px 0;
          color: var(--grey);
        }

        .placeholder-own-reference {
          cursor: pointer;
          transition: 0.3s ease-in-out;
        }

        .placeholder-own-reference:hover {
          color: var(--orange);
        }
      }
      .add-container-submit-btn {
        width: 100%;
        padding: 15px;
        background: var(--orange);
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        outline: none !important;
        border: none !important;

        p {
          font-size: 0.9rem;
          color: var(--white);
        }
      }

      .add-container-submit-btn:hover {
        opacity: 0.7;
      }
    }
  }
`;

export default AppUsersAdd;
