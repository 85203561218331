import React from "react";
import styled from "styled-components";

const UserBar = ({ img, name }) => {
  return (
    <Wrapper>
      <div className="wrapper-user">
        <img src={img} alt="user-avatar" className="user-avatar" />
        <p>{name}</p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;

  .wrapper-user {
    display: flex;
    gap: 10px;
    align-items: center;
    /* background-color: var(--lightgrey); */
    /* padding: 5px 10px; */
    border-radius: 5px;

    .user-avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: contain;
    }

    p {
      font-size: 0.9rem;
    }
  }
`;

export default UserBar;
