import React from "react";
import styled from "styled-components";

//components
import PageHeading from "../components/PageHeading";

//hemlet
import { Helmet } from "react-helmet";

//icons
import { TimeFive } from "@styled-icons/boxicons-regular/TimeFive";
import { ArrowTrendingCheckmark } from "@styled-icons/fluentui-system-filled/ArrowTrendingCheckmark";

const About = () => {
  const aboutBlocks = [
    {
      heading: "Pieredze",
      text: "20+ gadu pieredze",
      Icon: TimeFive,
    },

    {
      heading: "Patstāvīgs uzņēmums",
      text: "100% Latvijas uzņēmums",
      Icon: ArrowTrendingCheckmark,
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Par mums - Citrent</title>
      </Helmet>
      <PageHeading
        pageName={"Par mums"}
        pageImage={"./images/pageHeading/about.png"}
      />
      <div className="wrapper">
        <div className="left">
          <p>
            <span>Uzņēmums CitRent</span> dibināts 2018. Gada 3. maijā, tā
            pamatdarbības veids būvtehnikas noma.{" "}
            <span>CitRent darbiniekiem</span> ir vairāk kā{" "}
            <span>divdesmit gadu pieredze</span> nozares segmentā. CitRent ir{" "}
            <span>100% Latvijas uzņēmums</span>, tam nav ārvalstu investoru, tas
            nav kāda liela pasaules koncerna meitas uzņēmums.
          </p>
        </div>

        <div className="right">
          {aboutBlocks?.map((item, index) => {
            const { heading, text, Icon } = item;

            return (
              <div className="about-block" key={index}>
                <div className="about-block-icon">
                  {Icon && <Icon className="icon" />}
                </div>

                <div className="about-block-text">
                  <h3>{heading}</h3>
                  <p>{text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 0 200px 0;

    @media only screen and (max-width: 1140px) {
      margin: 0 20px;
    }

    display: flex;
    gap: 20px;
    justify-content: space-between;

    @media only screen and (max-width: 600px) {
      flex-direction: column;

      .left {
        max-width: unset;
        margin: 20px 0;
      }
    }

    .left {
      max-width: 600px;
      width: 100%;

      p {
        font-size: 0.9rem;
        line-height: 1.6;
      }

      span {
        color: var(--orange);
      }
    }

    .right {
      min-width: 300px;
      width: 100%;

      .about-block {
        display: flex;
        gap: 20px;
        border: 1px solid var(--lightgrey);
        padding: 15px;
        margin: 0 0 10px 0;
        border-radius: 5px;
        align-items: flex-start;

        .about-block-icon {
          .icon {
            max-width: 32px;
            width: 100%;
          }
        }

        .about-block-text {
          h3 {
            font-size: 1.2rem;
            margin: 0 0 5px 0;
          }

          p {
            font-size: 0.9rem;
            color: var(--orange);
          }
        }
      }
    }
  }
`;

export default About;
