import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

//config
import { addCategoryApi, checkCategoryNameApi } from "../firebase/globalConfig";

//components
import Header from "../components/Header";
import AppLogin from "./AppLogin";
import Heading from "../components/Heading";
import { toast } from "react-toastify";
import GridLoader from "react-spinners/GridLoader";

//redux
import { useSelector } from "react-redux";
import { selectUser, selectUserData } from "../redux/appSlice";

const AppCategoriesAdd = () => {
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);

  const navigate = useNavigate();

  const categoryImages = Array.from({ length: 68 }, (_, i) => ({
    path: `${i + 1}.svg`,
    selected: false,
  }));

  const [images, setImages] = useState(categoryImages);
  const [name, setName] = useState("");
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [isSelectedIcon, setIsSelectedIcon] = useState(null);
  const [loading, setLoading] = useState(false);

  const selectImage = (index) => {
    const updatedImages = categoryImages;
    updatedImages[index].selected = true;
    setSelectedIcon(updatedImages[index].path);

    setImages(() => {
      const updatedImages = [...categoryImages];
      updatedImages[index].selected = true;
      return updatedImages;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedIcon === null) {
      setIsSelectedIcon("Nav izvēlēta kategorijas ikona.");
    } else {
      setIsSelectedIcon(null);
      setLoading(true);
      const created = new Date();
      created.setHours(created.getHours() + 2);
      const formattedCreated = created
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      function replaceAccentedCharacters(inputString) {
        const accentedChars = "āčēģīķļņšūž";
        const replacementChars = "acegiklnsuz";

        return inputString.replace(
          new RegExp(`[${accentedChars}]`, "g"),
          (match) => replacementChars.charAt(accentedChars.indexOf(match))
        );
      }

      function createSlug(categoryName) {
        // Replace accented characters
        const slugWithoutAccents = replaceAccentedCharacters(categoryName);

        // Replace spaces with hyphens and convert to lowercase
        const slug = slugWithoutAccents.toLowerCase().replace(/\s+/g, "-");

        // Remove special characters
        const cleanedSlug = slug.replace(/[^a-z0-9-]/g, "");

        // Remove multiple hyphens
        const finalSlug = cleanedSlug.replace(/-+/g, "-");

        return finalSlug;
      }

      const categoryLink = createSlug(name);

      const formData = {
        category_name: `${name}`,
        category_icon: `${selectedIcon}`,
        display_name: `${userData?.display_name}`,
        avatar: `${user?.photoURL}`,
        email: `${user?.email}`,
        link: `${categoryLink}`,
        noti_text: `Pievienoja jaunu kategoriju ar nosaukumu ${name}`,
        noti_link: `/rent/${categoryLink}`,
        created: formattedCreated,
        edited: formattedCreated,
      };

      // console.log(formData);

      try {
        const response = await axios.post(`${addCategoryApi}`, formData, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        });
        // console.log(response);
        toast.success("Izveidota jauna kategorija.");

        setTimeout(() => {
          navigate("/app/categories");
        }, 2000);
      } catch (error) {
        toast.error("Nevarējām izveidot kategoriju. Mēģini vēlreiz.");
      }
    }
  };

  const [categoryNameCheck, setCategoryNameCheck] = useState(false);

  const checkCategoryName = async () => {
    const response = await axios.get(`${checkCategoryNameApi}${name}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;
    // console.log(data);

    if (data?.products?.length > 0) {
      setCategoryNameCheck(true);
    } else {
      setCategoryNameCheck(false);
    }
  };

  useEffect(() => {
    if (name) {
      checkCategoryName();
    } else {
      setCategoryNameCheck(false);
    }

    //eslint-disable-next-line
  }, [name]);

  return (
    <Wrapper>
      {user ? (
        <>
          <Header />
          <div className="container">
            <Heading
              name={"Pievienot jaunu kategoriju"}
              desc={"Šeit tu vari pievienot jaunu kategoriju"}
            />

            {loading ? (
              <>
                <LoadingContainer>
                  <GridLoader color="#ff6a00" />
                </LoadingContainer>
              </>
            ) : (
              <>
                <div className="add-container">
                  <>
                    <form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                      onSubmit={handleSubmit}
                    >
                      <div className="add-container-input-container">
                        <input
                          type="text"
                          placeholder="Nosaukums"
                          className="add-container-input"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>

                      {categoryNameCheck && (
                        <p style={{ fontSize: ".9rem", color: "#D0312D" }}>
                          Kategorija ar šādu nosaukumu ir jau pievienota.
                        </p>
                      )}

                      <div className="add-container-input-container">
                        <div className="add-container-input-category-list">
                          {images.map((item, index) => (
                            <div
                              className="add-container-input-category-container"
                              key={index}
                              style={{
                                backgroundColor:
                                  item?.selected && "var(--orange)",
                              }}
                              onClick={() => selectImage(index)}
                            >
                              <img
                                src={`/images/categories/${item?.path}`}
                                className="add-container-input-category-container"
                                alt="category-img"
                                style={{
                                  backgroundColor:
                                    item?.selected && "var(--orange)",
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>

                      {isSelectedIcon && (
                        <p style={{ fontSize: ".9rem", color: "#D0312D" }}>
                          {isSelectedIcon}
                        </p>
                      )}

                      {!categoryNameCheck && (
                        <button
                          type="submit"
                          className="add-container-submit-btn"
                        >
                          <p>Pievienot kategoriju</p>
                        </button>
                      )}
                    </form>
                  </>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <AppLogin />
      )}
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .add-container {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid var(--lightgrey);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .invite-url-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--grey);
        border-radius: 5px;
        margin: 20px 0 0 0;
        width: 100%;

        .invite-url {
          font-size: 0.9rem;
          padding: 0 0 0 15px;
          overflow: hidden;
        }

        .invite-url-copy {
          background: var(--green);
          height: 100%;
          padding: 12px 15px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          cursor: pointer;

          .icon {
            color: var(--white);
          }
        }

        .invite-url-copy:hover {
          opacity: 0.7;
        }
      }

      .invite-sent-alert {
        font-size: 1.2rem;
        color: var(--black);
      }

      .invite-sent-email {
        font-size: 0.9rem;
        color: var(--black);
      }

      .add-container-option-list {
        border: 1px solid var(--lightgrey);
        border-radius: 5px;

        p {
          font-size: 0.9rem;
          color: var(--black);
          padding: 15px 20px;
          cursor: pointer;
          text-transform: capitalize;
        }

        p:hover {
          background: var(--lightgrey);
        }
      }

      .add-container-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--lightgrey);
        border-radius: 5px;
        padding: 15px 20px;
        cursor: pointer;
        position: relative;

        .add-container-selected {
          font-size: 0.9rem;
          color: var(--black);
          text-transform: capitalize;
        }
      }

      .icon {
        max-width: 24px;
        min-width: 24px;
        width: 100%;
        color: var(--black);
        transition: 0.1s ease-in-out;
      }

      .add-container-input-container {
        width: 100%;

        .add-container-input-category-list {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          max-height: 400px;
          overflow-y: scroll;

          .add-container-input-category-container {
            padding: 5px;
            border-radius: 5px;
            background-color: #ebebeb;
            width: 54px;
            min-width: 54px;
            height: 54px;
            max-height: 54px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 0 0;
            cursor: pointer;
            transition: 0.3s ease-in-out;

            img {
              width: 44px;
              max-width: 44px;
              height: 44px;
              object-fit: contain;
              color: var(--white);
              transition: 0.3s ease-in-out;
            }
          }

          .add-container-input-category-container:hover {
            background-color: var(--grey);

            img {
              background-color: var(--grey);
            }
          }
        }

        .add-container-input-textarea {
          resize: vertical;
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
          min-height: 150px;
          font-family: Inter;
        }

        .add-container-input-image {
          width: 100%;
          height: 250px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .no-image {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--lightgrey);

            .icon {
              max-width: 24px;
              min-width: 24px;
              color: var(--black);
            }
          }
        }

        .add-container-input {
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
        }

        .add-container-input-placeholder {
          font-size: 0.9rem;
          margin: 10px 0 20px 0;
          color: var(--grey);
        }
      }
      .add-container-submit-btn {
        width: 100%;
        padding: 15px;
        background: var(--orange);
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        outline: none !important;
        border: none !important;

        p {
          font-size: 0.9rem;
          color: var(--white);
        }
      }

      .add-container-submit-btn:hover {
        opacity: 0.7;
      }
    }
  }
`;

export default AppCategoriesAdd;
