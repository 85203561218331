import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  userData: null,
  loading: false,
  categories: [],
  randomItems: [],
  contactItems: []
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateUserData: (state, action) => {
      state.userData = action.payload;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateCategories: (state, action) => {
      state.categories = action.payload;
    },
    updateRandomItems: (state, action) => {
      state.randomItems = action.payload;
    },
    updateContactItems: (state, action) => {
      state.contactItems = action.payload;
    },
  },
});

export const { updateUser, updateLoading, updateUserData, updateCategories, updateRandomItems, updateContactItems } = appSlice.actions;
export const selectUser = (state) => state.app.user;
export const selectLoading = (state) => state.app.loading;
export const selectUserData = (state) => state.app.userData;
export const selectCategories = (state) => state.app.categories;
export const selectRandomItems = (state) => state.app.randomItems;
export const selectContactItems = (state) => state.app.contactItems;

export default appSlice.reducer;