import React, { useEffect } from "react";
//global styles
import "./App.css";
import axios from "axios";

//router
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

//config
import {
  checkUserApi,
  getLandingCategoriesApi,
  getLandingRandomItems,
} from "./app/firebase/globalConfig";

//components
import Header from "./main/components/Header";
import Footer from "./main/components/Footer";
import { toast } from "react-toastify";

//pages
import Home from "./main/pages/Home";
import About from "./main/pages/About";
import Contact from "./main/pages/Contact";
import Services from "./main/pages/Services";
import Rent from "./main/pages/Rent";
import RentSingle from "./main/pages/RentSingle";
import Search from "./main/pages/Search";
import RentRules from "./main/pages/RentRules";
import PrivacyPolicy from "./main/pages/PrivacyPolicy";
import CookiePolicy from "./main/pages/CookiePolicy";
import AppPanel from "./app/pages/AppPanel";
import AppLogin from "./app/pages/AppLogin";
import AppCategories from "./app/pages/AppCategories";
import AppCategoriesAdd from "./app/pages/AppCategoriesAdd";
import AppCategoriesEdit from "./app/pages/AppCategoriesEdit";
import AppProducts from "./app/pages/AppProducts";
import AppProductsAdd from "./app/pages/AppProductsAdd";
import AppProductsEdit from "./app/pages/AppProductsEdit";
import AppUsers from "./app/pages/AppUsers";
import AppUsersSingle from "./app/pages/AppUsersSingle";
import AppUsersAdd from "./app/pages/AppUsersAdd";
import AppUsersEdit from "./app/pages/AppUsersEdit";

//firebase
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./app/firebase/firebaseConfig";

//redux
import { useDispatch } from "react-redux";
import {
  updateUser,
  updateLoading,
  updateUserData,
  updateCategories,
  updateRandomItems,
} from "./app/redux/appSlice";

//google
import ReactGA from "react-ga4";
ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`);

function App() {
  const dispatch = useDispatch();

  const checkUser = async () => {
    try {
      // Check if the user is authenticated with Firebase
      onAuthStateChanged(auth, async (user) => {
        dispatch(updateLoading(true));

        if (user) {
          // Assuming you have a function to check the user's Google ID on your server
          setTimeout(async () => {
            const response = await axios.post(
              checkUserApi,
              {
                googleId: user.uid,
              },
              {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
                },
              }
            );

            if (response.data.exists) {
              // If the Google ID exists in the database, update the user state
              const userFirebase = {
                accessToken: user?.accesToken,
                displayName: user?.displayName,
                email: user?.email,
                emailVerified: user?.emailVerified,
                isAnonymous: user?.isAnonymous,
                phoneNumber: user?.phoneNumber,
                photoURL: user?.photoURL,
                providerData: user?.providerData,
                providerId: user?.providerId,
                reloadListener: user?.reloadListener,
                reloadUserInfo: user?.reloadUserInfo,
                uid: user?.uid,
              };
              dispatch(updateUser(userFirebase));
              dispatch(updateUserData(response.data.userData));
              dispatch(updateLoading(false));
            } else {
              await signOut(auth);
              toast.error("Lietotājs nav reģistrēts sistēmā");
            }
          }, 2000);
        } else {
          // No user is authenticated
          dispatch(updateUser(null));
          dispatch(updateLoading(false));
        }
      });
    } catch (error) {
      // console.error(error);
    }
  };

  const getCategories = async () => {
    try {
      const response = await axios.get(getLandingCategoriesApi, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      const data = response.data;
      // console.log(data);

      // Function to filter out objects
      const filterObjectsByCategoryName = (categoryName, array) => {
        return array.filter((obj) => obj.category_name !== categoryName);
      };

      // Take out category "Pārdošana"
      const filteredArray = filterObjectsByCategoryName(
        "Pārdošana",
        data
      );

      // console.log(filteredArray);

      dispatch(updateCategories(filteredArray));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getRandomItems = async () => {
    try {
      const response = await axios.get(getLandingRandomItems, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      const data = response.data;
      // console.log(data);
      dispatch(updateRandomItems(data));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    dispatch(updateLoading(true));
    checkUser();
    getCategories();
    getRandomItems();
    //eslint-disable-next-line
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="container">
            <Header />
            <Outlet />
            <Footer />
          </div>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/services" element={<Services />} />
        <Route path="/services/:id" element={<Services />} /> */}
        <Route path="/rent" element={<Rent />} />
        <Route path="/rent/:categoryId" element={<Rent />} />
        <Route path="/rent/:categoryId/:productId" element={<RentSingle />} />
        <Route path="/search" element={<Search />} />
        <Route path="/rent-rules" element={<RentRules />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Route>
      <Route path="/app" element={<AppPanel />} />
      <Route path="/app/invite" element={<Navigate to="/" />} />
      <Route
        path="/app/invite/:id"
        element={<AppLogin authType={"invite"} />}
      />
      <Route path="/app/categories" element={<AppCategories />} />
      <Route path="/app/categories/add" element={<AppCategoriesAdd />} />
      <Route path="/app/categories/edit/:id" element={<AppCategoriesEdit />} />
      <Route path="/app/products" element={<AppProducts />} />
      <Route path="/app/products/add" element={<AppProductsAdd />} />
      <Route path="/app/products/edit/:id" element={<AppProductsEdit />} />
      <Route path="/app/users" element={<AppUsers />} />
      <Route path="/app/users/:id" element={<AppUsersSingle />} />
      <Route path="/app/users/add" element={<AppUsersAdd />} />
      <Route path="/app/users/edit/:id" element={<AppUsersEdit />} />
    </Routes>
  );
}

export default App;
