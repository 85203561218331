import React from "react";
import styled from "styled-components";

//router
import { Link } from "react-router-dom";

const ContactBanner = () => {
  return (
    <Wrapper>
      <div className="contact-banner-container">
        <div className="banner-image">
          <img src="./images/main/contact-img.png" alt="contact-img" />
        </div>

        <div className="banner-text">
          <div className="banner-text-content">
            <h3>Mūsu klientu apkalpošanas komanda ir jūsu rīcībā</h3>

            <p style={{ margin: "20px 0" }}>
              Vai Jums ir izaicinājums? Sazinieties ar mums, un mūsu
              eksperti palīdzēs jums atrast piemērotu nomas risinājumu!
            </p>

            <Link to="/contact" onClick={() => window.scrollTo(0, 0)}>
              <div className="btn">
                <p>Sazinies</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;

  @media only screen and (max-width: 1140px) {
    padding: 0 20px;
  }

  .contact-banner-container {
    display: flex;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 850px) {
      flex-direction: column;
    }

    .banner-image,
    .banner-text {
      flex-basis: 50%;
    }

    .banner-image {
      img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        @media only screen and (max-width: 850px) {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          height: 300px;
          object-fit: cover;
          object-position: center top;
        }

        @media only screen and (max-width: 600px) {
          height: 200px;
        }
      }
    }

    .banner-text {
      background-color: var(--orange);
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      position: relative;

      @media only screen and (max-width: 850px) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 40px 20px 40px 40px;
        align-items: start;
        bottom: 10px;
      }

      .banner-text-content {
        max-width: 400px;
        width: 100%;
        line-height: 1.3;

        @media only screen and (max-width: 850px) {
          max-width: 700px;
        }

        h3,
        p {
          color: var(--white);
        }

        h3 {
          font-size: 1.6rem;
        }

        p {
          font-size: 0.9rem;
          font-weight: normal;
        }

        .btn {
          padding: 10px 25px;
          background-color: var(--white);
          display: inline-block;
          border-radius: 5px;
          transition: 0.3s ease;

          a {
            text-decoration: none;
          }

          p {
            color: var(--orange);
            font-weight: bold;
            transition: 0.3s ease;
          }

          &:hover {
            background-color: var(--black);

            p {
              color: var(--white);
            }
          }
        }
      }
    }
  }
`;

export default ContactBanner;
