import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

//icons
import { KeyboardArrowRight } from "@styled-icons/material/KeyboardArrowRight";
import { ShoppingCart } from "@styled-icons/heroicons-solid/ShoppingCart";

//components
import ItemSlider from "../components/ItemSlider";
import ReactImageZoom from "react-image-zoom";
import GridLoader from "react-spinners/GridLoader";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectCategories,
  updateContactItems,
  selectContactItems,
} from "../../app/redux/appSlice";

//config
import {
  getProductByCategoryAndName,
  getProductsByCategory,
} from "../../app/firebase/globalConfig";

//hemlet
import { Helmet } from "react-helmet";

const RentSingle = () => {
  const params = useParams();
  const { categoryId, productId } = params;
  const navigate = useNavigate();

  const categoriesRedux = useSelector(selectCategories);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [productUpdated, setProductUpdated] = useState(null);

  const [products, setProducts] = useState([]);
  const [productsFound, setProductsFound] = useState(false);

  const getProduct = async () => {
    const response = await axios.get(
      `${getProductByCategoryAndName}?product_slug=${productId}&product_category_slug=${categoryId}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      }
    );

    const data = response.data;

    if (data?.products?.length > 0) {
      setProduct(data?.products[0]);
      // console.log(data?.products[0]);
    } else {
      navigate(`/rent`);
    }
  };

  const getProducts = async () => {
    const response = await axios.get(
      `${getProductsByCategory}?product_category_slug=${categoryId}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      }
    );
    const data = response.data;

    if (data?.products?.length > 0) {
      setProductsFound(true);
      setProducts(data?.products);
    } else {
      setProductsFound(false);
    }

    setLoading(false);

    // console.log(data?.products);

    setLoading(false);
  };

  //redux
  const dispatch = useDispatch();
  const contactItems = useSelector(selectContactItems);

  const addContactItem = (item) => {
    window.scrollTo(0, 0);
    const contactItemsList = contactItems;

    const contactItemsFiltered = contactItemsList.filter(
      (i) => i?.product_number === item?.product_number
    );

    if (contactItemsFiltered?.length < 1) {
      const contactItemsUpdated = [...contactItemsList, item];

      dispatch(updateContactItems(contactItemsUpdated));
    }
  };

  const [title, setTitle] = useState("Nomas Katalogs - Citrent");

  useEffect(() => {
    if (product) {
      setTitle(`${product.product_name} - Citrent`);
    }

    //eslint-disable-next-line
  }, [params, product]);

  useEffect(() => {
    if (product !== null) {
      let productUpdated = product;

      productUpdated.product_image = product.product_image.replace(
        /^\/var\/www/,
        "/"
      );

      productUpdated.product_description = product.product_description
        .replace(/\n/g, "<br>")
        .replace(/\t/g, ": ");

      // console.log(productUpdated);

      setProductUpdated(productUpdated);
      getProducts();
    }

    //eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    if (productId === undefined && categories?.length > 0) {
      navigate(`/rent`);
    } else if (
      categoryId !== undefined &&
      productId !== undefined &&
      categories?.length > 0
    ) {
      const currentCategory = categories?.filter(
        (item) => item?.link === categoryId
      );

      if (currentCategory[0] === undefined) {
        navigate(`/rent`);
      } else {
        setSelectedCategory(currentCategory[0]);
        getProduct();
      }
    }

    //eslint-disable-next-line
  }, [categoryId, productId, categories]);

  useEffect(() => {
    if (categoriesRedux?.length > 0) {
      setCategories(categoriesRedux);
      // console.log(categoriesRedux);
    }
  }, [categoriesRedux]);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={product?.product_description}></meta>
      </Helmet>
      <div className="wrapper">
        {loading ? (
          <LoadingContainer>
            <GridLoader color="#ff6a00" />
          </LoadingContainer>
        ) : (
          <>
            <div className="navigator">
              <p>Nomas katalogs</p>
              <KeyboardArrowRight className="icon" />
              {selectedCategory !== undefined && (
                <Link to={`/rent/${selectedCategory?.link}`}>
                  {selectedCategory?.category_name}
                </Link>
              )}
              <KeyboardArrowRight className="icon" />
              <Link
                to={`/rent/${selectedCategory?.link}/${product?.product_slug}`}
              >
                {product?.product_name}
              </Link>
            </div>

            <div className="wrapper-content">
              <div className="wrapper-product">
                <div className="wrapper-product-image">
                  <ReactImageZoom
                    width={500}
                    height={500}
                    zoomWidth={700}
                    zoomPosition={"original"}
                    img={productUpdated?.product_image}
                  />
                </div>

                <div className="wrapper-product-image-mobile">
                  <img src={productUpdated?.product_image} alt="product-img" />
                </div>

                <div className="wrapper-product-data">
                  <h1 className="product-name">{product?.product_name}</h1>

                  <p className="product-code">
                    Preces kods: {productUpdated?.product_number}
                  </p>
                  <p
                    className="product-desc"
                    dangerouslySetInnerHTML={{
                      __html: productUpdated?.product_description,
                    }}
                  ></p>

                  {productUpdated?.product_link !== "" && (
                    <>
                      <p className="product-link-heading">Atsauce</p>
                      <a
                        className="product-link"
                        href={productUpdated?.product_link}
                        alt="product-link"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {productUpdated?.product_link}
                      </a>
                    </>
                  )}

                  <div className="product-price">
                    <p className="product-price-heading">Cena</p>

                    <div className="product-price-amount-wrapper">
                      {parseInt(product?.product_price) === 0 ? (
                        <>
                          <p className="product-price-amount">
                            Cena pēc pieteikuma
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="product-price-amount-large">
                            €{product?.product_price}
                          </p>

                          <p className="product-price-amount-small">{`ar PVN 21% €${(product?.product_price * 1.21).toFixed(2)}`}</p>
                        </>
                      )}
                    </div>

                    <Link to="/contact" onClick={() => addContactItem(product)}>
                      <div className="product-order-btn">
                        <ShoppingCart className="icon" />
                        <p className="product-order-btn-text">Uzzināt vairāk</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              {productsFound && (
                <>
                  <div className="wrapper-suggested-items">
                    <ItemSlider heading={"Līdzīgas preces"} items={products} />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 20px auto 90px;

  @media only screen and (max-width: 1140px) {
    margin: 20px;
  }

  .navigator {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
    margin: 0 0 20px 0;

    p,
    a {
      font-size: 0.9rem;
      color: var(--black);
    }

    a {
      text-decoration: none;
      color: var(--grey) !important;
    }

    .icon {
      max-width: 24px;
      width: 100%;
      position: relative;
      top: 2px;
    }
  }

  .wrapper-content {
    .wrapper-product {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      @media only screen and (max-width: 750px) {
        flex-direction: column;
      }
    }

    .wrapper-product-image {
      max-width: 600px;
      min-width: 400px;
      height: 500px;
      max-width: 50%;
      width: 100%;
      overflow: hidden;

      @media only screen and (max-width: 750px) {
        width: 100%;
        max-width: unset;
      }

      img {
        width: 100% !important;
        object-fit: contain;
      }

      .js-image-zoom__zoomed-image {
        width: 100% !important;
        height: 100% !important;
      }

      @media only screen and (max-width: 550px) {
        display: none;
      }
    }

    .wrapper-product-image-mobile {
      display: none;
      width: 100%;
      height: 300px;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 5px;
      }

      @media only screen and (max-width: 550px) {
        display: block;
      }
    }

    .wrapper-product-data {
      width: 40%;

      @media only screen and (max-width: 750px) {
        width: 100% !important;
      }

      h1 {
        font-size: 1.4rem;
      }

      p {
        font-size: 0.9rem;
      }

      .product-code {
        color: var(--grey) !important;
      }

      .product-desc {
        margin: 20px 0 0;
        overflow-x: hidden;
      }

      .product-link-heading {
        margin: 20px 0 0;
        color: var(--grey) !important;
      }

      .product-link {
        color: var(--orange);
        font-size: 0.9rem;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.3s ease-in-out;
      }

      .product-link:hover {
        opacity: 0.6;
      }

      .product-price {
        border: 1px solid var(--grey);
        border-radius: 10px;
        padding: 20px;
        margin: 40px 0 0 0;

        .product-price-heading {
          color: var(--grey);
        }

        .product-price-amount-wrapper {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          align-items: flex-end;
          margin: 10px 0;
        }

        .product-price-amount {
          font-size: 1rem;
        }

        .product-price-amount-large {
          font-size: 2rem;
          font-weight: bold;
        }

        .product-price-amount-small {
          font-size: 0.9rem;
          color: var(--grey);
        }

        a {
          text-decoration: none;

          .product-order-btn {
            border-radius: 10px;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
            margin: 20px 0 0 0;
            transition: 0.3s ease-in-out;
            background: var(--orange);

            .product-order-btn-text {
              color: var(--white);
            }

            .icon {
              max-width: 24px;
              min-width: 24px;
              width: 100%;
              color: var(--white);
            }
          }

          .product-order-btn:hover {
            background: var(--green);
          }
        }
      }
    }
  }
`;

export default RentSingle;
