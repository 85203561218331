import React from "react";
import styled from "styled-components";

//icons
import { MoneyBill1Wave } from "@styled-icons/fa-solid/MoneyBill1Wave";
import { QuestionMark } from "@styled-icons/boxicons-regular/QuestionMark";
import { Phone } from "@styled-icons/boxicons-solid/Phone";
import { ThumbLike } from "@styled-icons/fluentui-system-filled/ThumbLike";

const WhyRent = () => {
  const reasons = [
    {
      icon: MoneyBill1Wave,
      image:
        "/images/whyRent/1.png",
      reason:
        "Nomājot jūs iegūstat piekļuvi profesionāliem instrumentiem, kas darbojas uzticami. Nav uzturēšanas izmaksu vai nevajadzīgas uzglabāšanas.",
    },
    {
      icon: QuestionMark,
      image:
        "/images/whyRent/2.png",
      reason:
        "Mūsu speciālisti palīdzēs jums izvēlēties darbam piemērotākos instrumentus. Mēs varam sniegt norādījumus par iekārtas drošu lietošanu.",
    },
    {
      icon: Phone,
      image: '/images/whyRent/3.png',
      reason:
        "Noma ir vienkārša kā picas pasūtīšana tiešsaistē. Rezervējiet tehniku zvanot un paņemiet to no noliktavas. ",
    },
    {
      icon: ThumbLike,
      image: '/images/whyRent/4.png',
      reason:
        "Īrējot jūs samazināsiet nevajadzīgu patēriņu. Viena nomas tehnika var veikt vairākus darbus.",
    },
  ];

  return (
    <Wrapper>
      <div className="why-rent-banner">
        <h3>Kāpēc izvēlēties īrēt?</h3>
        <div className="reasons">
          {reasons?.map((item, index) => (
            <div className="reason" key={index}>
              {item?.image && <img src={item?.image} alt="banner-img" />}
              <p>{item?.reason}</p>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 20px 0 0;
  width: 100%;
  background: var(--lightgrey);
  padding: 0 0 30px;

  @media only screen and (max-width: 1150px) {
    padding: 0 20px 30px;
  }

  .why-rent-banner {
    max-width: 1100px;
    margin: 0 auto;
    padding: 33px 0 20px;

    h3 {
      text-align: center;
      font-size: 1.6rem;
      color: var(--black);
      text-transform: uppercase;
      margin: 0 0 40px 0;
    }

    .reasons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;

      .reason {
        background-color: var(--black);
        cursor: pointer;
        transition: 0.3s ease;
        flex: 1;
        min-width: 250px;
        max-width: 300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        transition: 0.3s ease;
        cursor: help;
        overflow: hidden;
        border-radius: 5px;

        img {
          width: 100%;
          height: 150px;
          max-height: 150px;
          object-fit: cover;
          transition: 0.3s ease-in-out;
        }

        &:hover {
          box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
          transform: scale(1.02);

          img {
            transform: scale(1.1);
          }
        }

        @media only screen and (max-width: 570px) {
          min-width: 250px;
          max-width: 450px;
        }

        p {
          color: var(--white);
          font-size: 0.9rem;
          line-height: 1.4;
          position: relative;
          padding: 20px 20px;
          background-color: var(--black);
        }

        .icon {
          max-width: 64px;
          max-height: 64px;
          width: 64px;
          width: 100%;
          color: var(--white);
          margin: 0 0 20px 0;
        }

        @media only screen and (max-width: 570px) {
          filter: blur(0px);

          p,
          .icon {
            filter: blur(0px);
          }
        }
      }
    }
  }
`;

export default WhyRent;
