import React, { useState } from "react";
import styled from "styled-components";

//router
import { Link, useNavigate } from "react-router-dom";

//icons
import { Search } from "@styled-icons/ionicons-solid/Search";

const WelcomeBanner = () => {

  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');

  const search = () => {
    if(searchText?.length > 0){
      navigate(`/search?query=${searchText}`);
    } else {
      navigate(`/search`);
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      search();
    }
  }

  return (
    <Wrapper>
      <div className="banner-container">
        <div className="banner-text">
          <p>
            Mēs esam šeit, lai padarītu jūsu projekta vadīšanu vienkāršu un ērtu
          </p>

          <h1>
            Dari savu projektu lielisku ar SIA "Citrent" - tavs uzticamais
            partneris celtniecības instrumentu un tehnikas nomā.
          </h1>

          <Link to="/contact">
            <div className="btn">
              <p>Sazinies</p>
            </div>
          </Link>
        </div>

        <div className="banner-image">
          <img src="./images/main/banner-img.png" alt="banner-img" />
        </div>

        <div className="banner-fade-mobile"></div>
      </div>

      <div className="banner-search">
        <div className="banner-search-left">
          <p>Atrodi labāko tehniku jūsu projektam</p>
          <input
            type="text"
            className="banner-search-input"
            placeholder="Tehnikas nosaukums"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </div>

        <div className="banner-search-right" onClick={() => search()}>
          <Search className="icon" />
        </div>

        {/* <div className="banner-search-results-container">
          <div className='banner-search-results'>
            testers
          </div>
        </div> */}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: var(--orange);
  position: relative;

  @media only screen and (max-width: 650px) {
    background-image: url("./images/main/banner-img.png");
    background-size: cover;
  }

  .banner-fade-mobile {
    display: none;

    @media only screen and (max-width: 650px) {
      display: block;
      position: absolute;
      background: #000000b0;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .banner-search {
    max-width: 800px;
    width: 100%;
    position: absolute;
    display: flex;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    padding: 0 20px;

    .banner-search-results-container {
      width: 1100px;
      height: 300px;
      position: absolute;
      bottom: -310px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 5px;
      z-index: 80;
      background: lightgray;

      .banner-search-results {
        width: 100%;
        height: 100%;
        background: tomato;
      }
    }

    .banner-search-left {
      flex: 1;
      background-color: var(--white);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px;
      gap: 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      box-shadow: var(--orange) -1px 2px 1px 0px;

      @media only screen and (max-width: 650px) {
        padding: 10px 20px;
      }

      p {
        font-size: 0.8rem;
        color: var(--grey);
      }

      input {
        border: none;
        outline: none;
        color: var(--black);
        font-size: 1rem;
      }

      input::placeholder {
        color: var(--grey);
        font-size: 1rem;
      }
    }

    .banner-search-right {
      background-color: var(--orange);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 25px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: var(--orange) -1px 2px 1px 0px;
      cursor: pointer;
      transition: 0.3s ease;

      .icon {
        width: 100%;
        max-width: 24px;
        min-width: 24px;
        color: var(--white);
        transition: 0.3s ease;
      }

      &:hover {
        background-color: var(--white);

        .icon {
          color: var(--orange);
        }
      }
    }
  }

  .banner-container {
    max-width: 1100px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media only screen and (max-width: 650px) {
      flex-direction: column;
    }

    .banner-text,
    .banner-image {
      flex-basis: 50%;
    }

    .banner-text {
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: center;
      max-width: 340px;
      line-height: 1.4;

      @media only screen and (max-width: 1140px) {
        padding: 0 0 0 20px;
      }

      @media only screen and (max-width: 650px) {
        padding: 60px 20px 100px;
        max-width: 550px;
        z-index: 60;
      }

      p,
      h1 {
        color: var(--white);
      }

      p {
        font-size: 0.9rem;
      }

      h1 {
        font-size: 1.2rem;
      }

      .btn {
        padding: 10px 25px;
        background-color: var(--white);
        display: inline-block;
        border-radius: 5px;
        transition: 0.3s ease;

        a {
          text-decoration: none;
        }

        p {
          color: var(--orange);
          font-weight: bold;
          transition: 0.3s ease;
        }

        &:hover {
          background-color: var(--black);

          p {
            color: var(--white);
          }
        }
      }
    }

    .banner-image {
      height: 350px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media only screen and (max-width: 650px) {
        display: none;
      }
    }
  }
`;

export default WelcomeBanner;
