import React, { useState, useEffect } from "react";
import styled from "styled-components";

//components
import WelcomeBanner from "../components/WelcomeBanner";
import Categories from "../components/Categories";
import ContactBanner from "../components/ContactBanner";
import WhyRentBanner from "../components/WhyRentBanner";
import ItemSlider from "../components/ItemSlider";

//redux
import { useSelector } from "react-redux";
import { selectRandomItems } from "../../app/redux/appSlice";

//helmet
import { Helmet } from "react-helmet";

const Home = () => {
  const randomItems = useSelector(selectRandomItems);

  const [randomItemsList, setRandomItemsList] = useState(randomItems);

  useEffect(() => {
    if (randomItems) {
      setRandomItemsList(randomItems);
    }
  }, [randomItems]);

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Celtniecības Instrumentu un Tehnikas Noma - Citrent</title>
        <link rel="canonical" href="http://citrent.lv/" />
        <meta
          property="og:title"
          content="CitRent: Būvtehnikas Noma un Pakalpojumi"
        />
        <meta name="author" content="CitRent" />
        <meta
          name="description"
          content="CitRent ir uzņēmums, kas dibināts 2018. gada 3. maijā, un specializējas būvtehnikas nomas pakalpojumos. Mūsu darbiniekiem ir vairāk kā divdesmit gadu pieredze šajā nozares segmentā. Esam 100% Latvijas uzņēmums, bez ārvalstu investoriem un liela pasaules koncerna piederības."
        />
        <meta
          property="og:description"
          content="CitRent ir uzņēmums, kas dibināts 2018. gada 3. maijā, un specializējas būvtehnikas nomas pakalpojumos. Mūsu darbiniekiem ir vairāk kā divdesmit gadu pieredze šajā nozares segmentā. Esam 100% Latvijas uzņēmums, bez ārvalstu investoriem un liela pasaules koncerna piederības."
        />
        <meta property="og:locale" content="lv_LV" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://citrent.lv/" />
        <meta
          property="og:site_name"
          content="CitRent: Būvtehnikas Noma un Pakalpojumi"
        />
        <meta
          name="twitter:description"
          content="CitRent - uzņēmums ar vairāk kā divdesmit gadu pieredzi būvtehnikas nomas jomā. Mēs piedāvājam kvalitatīvus pakalpojumus un īrējam būvtehniku visdažādākajiem projektiem."
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="CitRent: Būvtehnikas Noma un Pakalpojumi"
        />
        <meta
          name="keywords"
          content="būvtehnikas noma, būvtehnika, būvniecības aprīkojums, celtniecības tehnika, celtniecības iekārtas, celtniecības transporta noma, iekrāvējs, ekskavators, celtnis, betonmaisītājs, būvtehnikas īre, būvtehnikas pakalpojumi, būvtehnikas nomas cena, būvtehnikas nomas kalkulators, būvtehnikas nomas uzņēmumi, būvtehnikas nomas līgumi, būvtehnikas nomas noteikumi, būvtehnikas nomas drošība"
        />
      </Helmet>
      <WelcomeBanner />
      <Categories />
      <ContactBanner />
      <ItemSlider heading={"Iecienītā tehnika"} items={randomItemsList} />
      <WhyRentBanner />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default Home;
