import React from "react";
import styled from "styled-components";

const Banner = ({ Icon, name, count }) => {
  return (
    <Wrapper>
      <div className="banner-top">
        <p>{name}</p>
        {Icon && <Icon className="icon" />}
      </div>
      <h4>{count}</h4>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 250px;
  width: 100%;
  border: 1px solid var(--lightgrey);
  border-radius: 5px;
  padding: 20px;

  @media only screen and (max-width: 650px) {
    max-width: unset;
  }

  .banner-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;

    p {
      font-size: 1rem;
      color: #444444;
    }

    .icon {
      max-width: 24px;
      min-width: 24px;
      width: 100%;
      color: var(--orange);
    }
  }

  h4 {
    font-size: 1.5rem;
  }
`;

export default Banner;
