import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDebounce } from "use-debounce";

//icons
import { Phone } from "@styled-icons/boxicons-solid/Phone";
import { Search } from "@styled-icons/ionicons-solid/Search";
import { ArrowIosDownward } from "@styled-icons/evaicons-solid/ArrowIosDownward";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import { Divide as Hamburger } from "hamburger-react";

//router
import { NavLink, Link } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { selectCategories } from "../../app/redux/appSlice";
import axios from "axios";

//config
import { searchLandingProduct } from "../../app/firebase/globalConfig";

const Header = () => {
  const navigationList = [
    {
      name: "Sākums",
      link: "/",
    },
    {
      name: "Nomas katalogs",
      link: "/rent",
      dropdown: true,
      dropdownOpened: false,
      index: 0,
    },
    // {
    //   name: "Pakalpojumi",
    //   link: "/services",
    //   dropdown: true,
    //   dropdownOpened: false,
    //   index: 1,
    // },
    {
      name: "Par mums",
      link: "/about",
    },
    {
      name: "Kontakti",
      link: "/contact",
    },
  ];

  const categories = useSelector(selectCategories);

  const dropdownsData = [
    {
      name: "Nomas katalogs",
      link: "/rent",
      items: [],
    },
    // {
    //   name: "Pakalpojumi",
    //   link: "/services",
    //   items: [
    //     {
    //       category_name: "Mini ekskavatora pakalpojumi",
    //       link: "/services/mini-ekskavatora-pakalpojumi",
    //     },
    //     {
    //       category_name: "Ūdensvadu izbūve",
    //       link: "/services/udensvadu-izbuve",
    //     },
    //     {
    //       category_name: "Lietus ūdens novadīšanas sistēmas izbūve",
    //       link: "/services/lietus-udens-novadisanas-sistemas-izbuve",
    //     },
    //     {
    //       category_name: "Kanalizācijas sistēmas izbūve",
    //       link: "/services/kanalizacijas-sistemas-izbuve",
    //     },
    //   ],
    // },
  ];

  //dropdown & navigation
  const [navigation, setNavigation] = useState(navigationList);
  const [dropdown, setDropdown] = useState(null);
  const [dropdowns, setDropdowns] = useState(dropdownsData);

  const handleDropdown = (dropdownIndex, navIndex) => {
    const openedDropdown = dropdowns[dropdownIndex];
    const navigationUpdated = navigationList;
    navigationUpdated[navIndex].dropdownOpened = true;

    setDropdown(openedDropdown);
    setNavigation(navigationUpdated);
  };

  //mobile navigation
  const [isOpen, setOpen] = useState(false);

  //navigation search
  const [openSearch, setOpenSearch] = useState(false);
  const [navSearch, setNavSearch] = useState("");
  const [navSearchValue] = useDebounce(navSearch, 400);
  const [navSearchResults, setNavSearchResults] = useState([]);

  const handleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  const updateFirstDropdownItems = (newItems) => {
    // Make a copy of the dropdowns state
    const updatedDropdowns = [...dropdowns];

    // Update the items array of the first object
    updatedDropdowns[0].items = newItems;

    // Set the state with the modified copy
    setDropdowns(updatedDropdowns);
  };

  const searchProduct = async () => {
    const response = await axios.get(
      `${searchLandingProduct}?q=${navSearchValue}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      }
    );
    const data = response.data;
    setNavSearchResults(data?.products);
    // console.log(data);
  };

  //cookies
  const [showCookiesBanner, setShowCookiesBanner] = useState(false);

  const allowCookies = () => {
    window.localStorage.setItem("cookies-allowed", true);
    setShowCookiesBanner(false);
  };

  useEffect(() => {
    const cookiesCheck = window.localStorage.getItem("cookies-allowed");

    if (!cookiesCheck) {
      setShowCookiesBanner(true);
    } else {
      setShowCookiesBanner(false);
    }
  }, []);

  useEffect(() => {
    if (categories) {
      const modifiedArray = categories.map((item) => {
        return { ...item, link: `/rent/${item.link}` };
      });

      const sortedCategories = [...modifiedArray].sort(
        (a, b) => a.category_position - b.category_position
      );

      updateFirstDropdownItems(sortedCategories);
    }

    //eslint-disable-next-line
  }, [categories]);

  useEffect(() => {
    if (isOpen) {
      const nav = document.querySelector(".header-mobile-container");
      setTimeout(() => {
        nav.style.right = 0;
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    if (navSearchValue) {
      searchProduct();
    } else {
      setNavSearch("");
      setNavSearchResults([]);
    }
    //eslint-disable-next-line
  }, [navSearchValue]);

  return (
    <Wrapper>
      <header>
        <div className="header-container">
          <div className="header-left-mobile">
            <a href="tel:+371 28 744 299">
              <Phone className="icon" />
            </a>
          </div>
          <div className="header-left">
            <img src="/images/main/logo.png" alt="logo" />
          </div>
          <div className="header-right">
            <div className="header-right-text">
              <p>Pasūtījumi & informācija</p>
              <div className="header-right-phone">
                <Phone className="icon" />
                <a href="tel:+37128744299">+371 28 744 299</a>
              </div>
            </div>
            <div
              className="header-right-search"
              onClick={() => {
                handleOpenSearch();
              }}
            >
              {openSearch ? (
                <CloseOutline className="icon" />
              ) : (
                <Search className="icon" />
              )}
            </div>
          </div>
          <div className="header-right-mobile">
            <Hamburger toggled={isOpen} toggle={setOpen} color={"#FFFDFA"} />
          </div>
        </div>

        <div className="nav-container">
          {openSearch && (
            <div className="nav-search-container">
              <div className="nav-search">
                <div className="nav-search-input">
                  <input
                    type="text"
                    className="banner-search-input"
                    placeholder="Tehnikas nosaukums"
                    onChange={(e) => setNavSearch(e.target.value)}
                  />

                  <div className="banner-search-right">
                    <Search className="icon" />
                  </div>
                </div>

                {navSearchResults?.length > 0 && (
                  <div className="nav-search-results">
                    <>
                      {navSearchResults?.map((item, index) => {
                        const link = item?.product_image;
                        const cleanedLink = link.replace(/^\/var\/www/, "/");

                        return (
                          <Link
                            key={index}
                            to={`/rent/${item?.product_category_slug}/${item?.product_slug}`}
                            onClick={() => {
                              window.scrollTo(0, 0);
                              setOpenSearch(false);
                            }}
                          >
                            <div className="nav-search-result" key={index}>
                              <img src={cleanedLink} alt="product-img" />
                              <div className="nav-search-result-data">
                                <h3>{item?.product_name}</h3>
                                <p>Preces kods: {item?.product_number}</p>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </>
                  </div>
                )}
              </div>

              <div className="nav-search-fade"></div>
            </div>
          )}

          <nav>
            {navigation?.map((item, index) => (
              <div className="nav-item" key={index}>
                {item?.dropdown ? (
                  <div
                    className="nav-item-dropdown"
                    onClick={() => {
                      handleDropdown(item?.index, index);
                    }}
                  >
                    <p>{item?.name}</p>
                    <ArrowIosDownward
                      className="icon"
                      style={{
                        transform: item?.dropdownOpened
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </div>
                ) : (
                  <NavLink
                    key={index}
                    to={item?.link}
                    className={item?.class}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <p>{item?.name}</p>
                  </NavLink>
                )}
              </div>
            ))}
          </nav>

          {dropdown !== null && (
            <div className="dropdown">
              <div className="dropdown-container">
                <div className="dropdown-container-top">
                  <Link to={dropdown?.link} className="dropdown-container-name">
                    {dropdown?.name}
                  </Link>
                  <CloseOutline
                    className="icon"
                    onClick={() => {
                      setDropdown(null);
                      setNavigation(navigationList);
                    }}
                  />
                </div>

                <div className="dropdown-items">
                  {dropdown?.items?.map((item, index) => (
                    <Link key={index} to={item?.link}>
                      {item?.category_name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {isOpen && (
          <>
            <div className="header-mobile-container">
              {navigationList?.map((item, index) => (
                <Link
                  to={item?.link}
                  className="header-mobile-container-item"
                  key={index}
                  onClick={() => {
                    setOpen(!isOpen);
                    window.scrollTo(0, 0);
                  }}
                >
                  <p>{item?.name}</p>
                </Link>
              ))}
            </div>
          </>
        )}
      </header>

      {showCookiesBanner && (
        <>
          <CookieBanner>
            <div className="cookie-banner-container">
              <div className="cookie-banner-container-left">
                <h3>KONFIDENCIALITĀTE UN DATI</h3>
                <p>
                  Lūdzu, ņemiet vērā, ka šajā mājaslapā tiek izmantotas
                  sīkdatnes.
                </p>
              </div>

              <div className="cookie-banner-container-right">
                <Link to="/privacy-policy">Privātuma politika</Link>
                <div
                  className="cookie-banner-btn"
                  onClick={() => allowCookies()}
                >
                  Es sapratu
                </div>
              </div>
            </div>
          </CookieBanner>
        </>
      )}
    </Wrapper>
  );
};

const CookieBanner = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: var(--white);

  .cookie-banner-container {
    max-width: 1110px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    gap: 20px;

    @media only screen and (max-width: 1140px) {
      padding: 20px;
    }

    @media only screen and (max-width: 740px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .cookie-banner-container-left {
      h3 {
        font-size: 1rem;
      }

      p {
        font-size: 0.8rem;
      }
    }

    .cookie-banner-container-right {
      display: flex;
      gap: 10px;
      align-items: center;

      @media only screen and (max-width: 740px) {
        width: 100%;
        justify-content: space-between;
        padding: 0 0 20px 0;
      }

      a {
        font-size: 0.9rem;
        text-decoration: none;
        color: var(--orange);
        border-bottom: 1px solid var(--white);
      }

      a:hover {
        border-bottom: 1px solid var(--orange);
      }

      .cookie-banner-btn {
        font-size: 0.9rem;
        border: 1px solid var(--lightgrey);
        border-radius: 10px;
        padding: 10px 20px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        text-align: center;
      }

      .cookie-banner-btn:hover {
        background: var(--green);
        color: var(--white);
      }
    }
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  background-color: var(--black);
  position: sticky;
  top: 0;
  z-index: 100;

  .header-mobile-container {
    position: absolute;
    background: var(--black);
    color: white;
    width: 100vw;
    height: 100vh;
    padding: 10px 0;
    transition: right 0.3s ease-in-out;
    right: -100%;

    .header-mobile-container-item {
      display: block;
      width: 80%;
      margin: 0 auto 20px;
      text-decoration: none;
      border: 1px solid var(--grey);
      border-radius: 5px;
      padding: 15px 20px;
      background: var(--white);

      p {
        font-size: 0.9rem;
        color: var(--black);
      }
    }
  }

  .nav-container {
    width: 100%;
    padding: 10px 0;
    background: var(--white);
    backdrop-filter: blur(6px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media only screen and (max-width: 550px) {
      display: none;
    }

    .nav-search-container {
      width: 100%;
      background: var(--white);
      z-index: 80;
      position: relative;
      bottom: 10px;

      .nav-search {
        max-width: 1100px;
        margin: 0 auto;

        .nav-search-results {
          padding: 20px 0px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          overflow-y: scroll;
          max-height: 600px;

          a {
            text-decoration: none;
            min-width: 300px;
            max-width: 353px;
            width: 100%;
            flex: 1 0 0;

            @media only screen and (max-width: 980px) {
              max-width: 460px;
            }

            @media only screen and (max-width: 660px) {
              max-width: 700px;
            }
          }

          @media only screen and (max-width: 1140px) {
            padding: 20px;
          }

          .nav-search-result {
            display: flex;
            gap: 10px;
            flex: 1;
            cursor: pointer;
            transition: 0.3s ease;
            overflow: hidden;

            &:hover {
              background-color: var(--lightgrey);

              img {
                transform: scale(1.2);
              }

              .nav-search-result-data {
                p {
                  color: var(--green);
                }
              }
            }

            img {
              width: 100px;
              height: 100px;
              object-fit: cover;
              transition: 0.3s ease;
              border-radius: 5px;
            }

            .nav-search-result-data {
              padding: 10px;

              h3 {
                font-size: 0.9rem;
                max-width: 200px;
                color: var(--black);
                margin: 0 0 5px 0;
              }

              p {
                font-size: 0.8rem;
                color: var(--grey);
                transition: 0.3s ease;
              }
            }
          }
        }

        .nav-search-input {
          display: flex;
          justify-content: space-between;

          @media only screen and (max-width: 1140px) {
            padding: 0 0 0 20px;
          }

          input {
            border: none;
            outline: none;
            color: var(--black);
            font-size: 1rem;
            margin: 10px 0;
            flex: 1;
          }

          input::placeholder {
            color: var(--grey);
            font-size: 1rem;
          }

          .banner-search-right {
            background-color: var(--orange);
            height: 100%;
            width: 70px;
            height: 69px;
            display: flex;
            justify-content: center;
            cursor: pointer;
            transition: 0.3s ease;

            .icon {
              width: 100%;
              max-width: 24px;
              min-width: 24px;
              color: var(--white);
              transition: 0.3s ease;
            }

            &:hover {
              background-color: var(--white);

              .icon {
                color: var(--orange);
              }
            }
          }
        }
      }

      .nav-search-fade {
        width: 100vw;
        height: 100vh;
        position: absolute;
        background: var(--black);
        opacity: 0.7;
      }
    }

    .dropdown {
      width: 100%;
      background-color: var(--white);

      .dropdown-container {
        max-width: 1100px;
        margin: 25px auto;

        @media only screen and (max-width: 1140px) {
          padding: 0 20px;
        }

        .dropdown-container-top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .dropdown-container-name {
            font-size: 1.3rem;
            color: var(--black);
            font-weight: normal;
            margin: 0 0 10px 0;
            text-decoration: none;
            border-bottom: 1px dashed transparent;
            transition: 0.3s ease;

            &:hover {
              border-color: var(--orange);
            }
          }

          .icon {
            width: 100%;
            max-width: 24px;
            min-width: 24px;
            cursor: pointer;
          }
        }

        .dropdown-items {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          max-width: 100%;

          a {
            font-size: 0.9rem;
            text-decoration: none;
            color: var(--black);
            border-bottom: 1px solid transparent;
            transition: 0.3s ease;
            padding: 0 0 5px 0;
            text-transform: uppercase;

            &:hover {
              border-bottom: 1px solid var(--orange);
            }
          }
        }
      }
    }

    nav {
      display: flex;
      gap: 15px;
      max-width: 1100px;
      margin: 0 auto;
      flex-wrap: wrap;

      @media only screen and (max-width: 1140px) {
        padding: 0 20px;
      }

      .nav-item {
        display: flex;
        align-items: center;
        position: relative;

        .nav-item-dropdown {
          display: flex;
          align-items: center;
          padding: 0 0 4px 0;
          border-bottom: 2px solid transparent;
          transition: border-color 0.3s ease;
          cursor: pointer;

          p {
            color: var(--black);
            text-transform: uppercase;
            font-size: 0.9rem;
          }

          .icon {
            width: 100%;
            max-width: 24px;
            min-width: 24px;
            transition: 0.2s ease;
          }

          &:hover {
            border-color: var(--orange);
          }
        }
      }

      a {
        text-decoration: none;
        color: var(--black);
        text-transform: uppercase;
        font-size: 0.9rem;
        border-bottom: 2px solid transparent;
        transition: border-color 0.3s ease;
        display: flex;
        align-items: center;
        gap: 5px;
        position: relative;
        padding: 0 0 7px 0;
        bottom: -2px;

        p {
          color: var(--black);
          text-transform: uppercase;
          font-size: 0.9rem;
        }

        &:hover {
          border-color: var(--orange);
        }

        .icon {
          width: 100%;
          max-width: 24px;
          min-width: 24px;
        }
      }

      .active {
        border-bottom: 2px solid var(--orange);
      }
    }
  }

  .header-container {
    max-width: 1100px;
    height: 69px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1140px) {
      padding: 0 0 0 20px;
    }

    @media only screen and (max-width: 550px) {
      padding: 0 20px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .header-left {
      img {
        max-width: 160px;
        width: 100%;
      }
    }

    .header-left-mobile {
      display: none;

      @media only screen and (max-width: 550px) {
        display: block;
      }

      a {
        text-decoration: none;
        color: var(--white);

        .icon {
          min-width: 24px;
          max-width: 24px;
          width: 100%;
        }
      }
    }

    .header-right-mobile {
      display: none;

      @media only screen and (max-width: 550px) {
        display: block;
      }
    }

    .header-right {
      display: flex;
      gap: 20px;
      height: 100%;
      align-items: center;

      @media only screen and (max-width: 550px) {
        display: none;
      }

      .header-right-text {
        p {
          font-size: 0.9rem;
          color: var(--white);
        }

        a {
          text-decoration: none;
          color: var(--white);
          font-size: 0.9rem;

          &:hover {
            text-decoration: underline;
          }
        }

        .icon {
          width: 24px;
          min-width: 24px;
          color: var(--white);
        }

        .header-right-phone {
          display: flex;
          align-items: center;
          justify-content: end;
          margin: 5px 0 0 0;
          gap: 5px;
        }
      }

      .header-right-search {
        display: flex;
        height: 100%;
        /* padding: 0 25px; */
        width: 70px;
        background-color: var(--white);
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s ease;

        .icon {
          width: 100%;
          max-width: 24px;
          min-width: 24px;
          color: var(--orange);
          transition: 0.3s ease;
        }

        &:hover {
          background-color: var(--orange);

          .icon {
            color: var(--white);
          }
        }
      }
    }
  }
`;

export default Header;
