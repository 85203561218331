import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

//components
import Header from "../components/Header";
import AppLogin from "./AppLogin";
import Heading from "../components/Heading";
import GridLoader from "react-spinners/GridLoader";
import { toast } from "react-toastify";

//config
import {
  getCategoriesApi,
  checkProductApi,
  updateProductApi,
} from "../firebase/globalConfig";

//icons
import { ArrowIosDownward } from "@styled-icons/evaicons-solid/ArrowIosDownward";
import { ImageNotSupported } from "@styled-icons/material-twotone/ImageNotSupported";

//redux
import { useSelector } from "react-redux";
import { selectUser, selectUserData } from "../redux/appSlice";

const AppUsersEdit = () => {
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const params = useParams();
  const { id } = params;
  const [selectedId] = useState(id);
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [categoriesOptions, setCategoriesOptions] = useState(false);
  const [image, setImage] = useState("");
  const [imageToShow, setImageToShow] = useState("");
  const [loading, setLoading] = useState(true);

  //inputs
  const [productNumber, setProductNumber] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [price, setPrice] = useState(0);

  const [descriptionLength, setDescriptionLength] = useState(1500);

  const calculateDescriptionLength = () => {
    let messageString = description.replace(/\s/g, "");
    setDescriptionLength((messageLength) => 1500 - messageString.length);
  };

  const getCategories = async () => {
    const response = await axios.get(`${getCategoriesApi}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;
    // console.log(data);
    setCategories(data);
  };

  const getProduct = async () => {
    const response = await axios.post(
      `${checkProductApi}`,
      {
        id: selectedId,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      }
    );
    if (response.data.exists) {
      // console.log(response.data.data);
      const data = response.data.data;

      setProductNumber(data?.product_number);
      setName(data?.product_name);
      setLink(data?.product_link);
      setPrice(data?.product_price);

      const currentCategory = categories?.filter(
        (category) => category?.id === data?.product_category_id
      );
      setCategory(currentCategory[0]);
      // console.log(currentCategory[0]);

      const cleanedLink = data?.product_image.replace(/^\/var\/www/, "/");
      setImage(data?.product_image);
      setImageToShow(cleanedLink);

      setDescription(data?.product_description);
      setDescriptionLength(1500 - data?.product_description?.length);

      setLoading(false);
    }
  };

  const editProduct = async () => {
    const created = new Date();
    created.setHours(created.getHours() + 2);
    const formattedCreated = created
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setLoading(true);

    function replaceAccentedCharacters(inputString) {
      const accentedChars = "āčēģīķļņšūž";
      const replacementChars = "acegiklnsuz";

      return inputString.replace(
        new RegExp(`[${accentedChars}]`, "g"),
        (match) => replacementChars.charAt(accentedChars.indexOf(match))
      );
    }

    function createSlug(categoryName) {
      // Replace accented characters
      const slugWithoutAccents = replaceAccentedCharacters(categoryName);

      // Replace spaces with hyphens and convert to lowercase
      const slug = slugWithoutAccents.toLowerCase().replace(/\s+/g, "-");

      // Remove special characters
      const cleanedSlug = slug.replace(/[^a-z0-9-]/g, "");

      // Remove multiple hyphens
      const finalSlug = cleanedSlug.replace(/-+/g, "-");

      return finalSlug;
    }

    const productSlug = createSlug(name);

    const editData = {
      id: `${selectedId}`,
      product_number: `${productNumber}`,
      product_name: `${name}`,
      product_category_id: `${category?.id}`,
      product_category_slug: `${category?.link}`,
      product_slug: `${productSlug}`,
      product_edited: formattedCreated,
      product_image: `${image}`,
      product_description: `${description}`,
      product_link: `${link}`,
      product_price: `${price}`,

      display_name: `${userData?.display_name}`,
      avatar: `${userData?.avatar}`,
      noti_type: "edit_product",
      email: userData?.email,
      noti_text: `Laboja tehnikas informāciju, ${name}, kategorijā ${category?.category_name}.`,
      noti_link: `/rent/${category?.link}/${productSlug}`,
    };

    console.log(editData);

    try {
      const response = await axios.put(`${updateProductApi}`, editData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      console.log(response.data);
      toast.success("Izmaiņas saglabātas veiksmīgi.");

      setTimeout(() => {
        navigate("/app/products");
      }, 2000);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const addCRPrefix = (e) => {
    const prefix = "CR";
    const value = e.target.value;

    if (!value.startsWith(prefix)) {
      setProductNumber(prefix + value);
    } else {
      setProductNumber(value);
    }
  };

  useEffect(() => {
    const prefix = "/var/www/";

    if (!image.startsWith(prefix)) {
      setImageToShow(image);
    }
  }, [image]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (selectedId !== null) {
      getProduct();
    }
    //eslint-disable-next-line
  }, [selectedId, categories]);

  return (
    <Wrapper>
      {user ? (
        <>
          <Header />
          <div className="container">
            <Heading
              name={"Labot tehnikas ierakstu"}
              desc={"Šeit tu vari labot informāciju tehnikai"}
            />

            {loading ? (
              <>
                {" "}
                <LoadingContainer>
                  <GridLoader color="#ff6a00" />
                </LoadingContainer>
              </>
            ) : (
              <>
                <div className="add-container">
                  <>
                    <div className="add-container-input-container">
                      <input
                        type="text"
                        placeholder="Preces Nr. (piem. CR123123)"
                        className="add-container-input"
                        value={productNumber}
                        onChange={(e) => addCRPrefix(e)}
                        disabled
                      />
                    </div>

                    <div className="add-container-input-container">
                      <input
                        type="text"
                        placeholder="Nosaukums"
                        className="add-container-input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      <div className="add-container-input-placeholder">
                        Piemērs, Putekļu sūcējs, 220V, Karcher NT35/1 AP.
                        Kategorija, galvenā specifikācija, produkta Nr.
                      </div>
                    </div>

                    <div
                      className="add-container-options"
                      onClick={() => setCategoriesOptions(!categoriesOptions)}
                    >
                      <p className="add-container-selected">
                        {category?.category_name}
                      </p>
                      <ArrowIosDownward
                        className="icon"
                        style={{
                          transform: categoriesOptions
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </div>

                    {categoriesOptions && (
                      <div className="add-container-option-list">
                        {categories.map((item, index) => (
                          <p
                            key={index}
                            onClick={() => {
                              setCategory(item);
                              setCategoriesOptions(!categoriesOptions);
                            }}
                          >
                            {item?.category_name}
                          </p>
                        ))}
                      </div>
                    )}

                    <div className="add-container-input-container">
                      <div
                        className="add-container-input-placeholder"
                        style={{ marginTop: 0, color: "red" }}
                      >
                        Tehnikas kategorija, pārliecinies, ka tehnika atrodas
                        pareizajā kategorijā un šeit nav tukšs lauks!
                      </div>
                    </div>

                    <div className="add-container-input-container">
                      <input
                        type="text"
                        placeholder="Attēla links"
                        className="add-container-input"
                        value={image}
                        required
                        onChange={(e) => setImage(e.target.value)}
                      />

                      <div className="add-container-input-placeholder">
                        Attēla links
                      </div>
                    </div>

                    <div className="add-container-input-container">
                      {imageToShow ? (
                        <>
                          <div className="add-container-input-image">
                            <img src={imageToShow} alt="tehnikas bilde" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="add-container-input-image">
                            <div className="no-image">
                              <ImageNotSupported className="icon" />
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="add-container-input-container">
                      <input
                        type="text"
                        placeholder="Tehnikas links (atsauce)"
                        className="add-container-input"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        required
                      />

                      <div className="add-container-input-placeholder">
                        Tehnikas links (atsauce)
                      </div>
                    </div>

                    {category?.category_name === "Pārdošana" && (
                      <>
                        <div className="add-container-input-container">
                          <input
                            type="number"
                            placeholder="Cena bez pvn"
                            className="add-container-input"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            min={0}
                            required
                          />

                          <div className="add-container-input-placeholder">
                            Cena{" "}
                            {price > 0 && (
                              <>{`ar PVN 21% €${(price * 1.21).toFixed(2)}`}</>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="add-container-input-container">
                      <textarea
                        placeholder="Apraksts"
                        className="add-container-input-textarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="1500"
                        onKeyUp={calculateDescriptionLength}
                        required
                      />

                      <p style={{ fontSize: ".9rem", textAlign: "end" }}>
                        Atlikušās rakstzīmes {descriptionLength}
                      </p>
                    </div>

                    <div
                      className="add-container-submit-btn"
                      onClick={() => editProduct()}
                    >
                      <p>Labot informāciju</p>
                    </div>
                  </>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <AppLogin />
      )}
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .add-container {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid var(--lightgrey);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .invite-url-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--grey);
        border-radius: 5px;
        margin: 20px 0 0 0;
        width: 100%;

        .invite-url {
          font-size: 0.9rem;
          padding: 0 0 0 15px;
          overflow: hidden;
        }

        .invite-url-copy {
          background: var(--green);
          height: 100%;
          padding: 12px 15px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          cursor: pointer;

          .icon {
            color: var(--white);
          }
        }

        .invite-url-copy:hover {
          opacity: 0.7;
        }
      }

      .invite-sent-alert {
        font-size: 1.2rem;
        color: var(--black);
      }

      .invite-sent-email {
        font-size: 0.9rem;
        color: var(--black);
      }

      .add-container-option-list {
        border: 1px solid var(--lightgrey);
        border-radius: 5px;

        p {
          font-size: 0.9rem;
          color: var(--black);
          padding: 15px 20px;
          cursor: pointer;
          text-transform: capitalize;
        }

        p:hover {
          background: var(--lightgrey);
        }
      }

      .add-container-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--lightgrey);
        border-radius: 5px;
        padding: 15px 20px;
        cursor: pointer;
        position: relative;

        .add-container-selected {
          font-size: 0.9rem;
          color: var(--black);
          text-transform: capitalize;
        }
      }

      .icon {
        max-width: 24px;
        min-width: 24px;
        width: 100%;
        color: var(--black);
        transition: 0.1s ease-in-out;
      }

      .add-container-input-container {
        width: 100%;

        .add-container-input-textarea {
          resize: vertical;
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
          min-height: 250px;
          font-family: Inter;
        }

        .add-container-input-image {
          width: 100%;
          height: 250px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          .no-image {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--lightgrey);

            .icon {
              max-width: 24px;
              min-width: 24px;
              color: var(--black);
            }
          }
        }

        .add-container-input {
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
        }

        .add-container-input-placeholder {
          font-size: 0.9rem;
          margin: 10px 0 20px 0;
          color: var(--grey);
        }
      }
      .add-container-submit-btn {
        width: 100%;
        padding: 15px;
        background: var(--orange);
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        p {
          font-size: 0.9rem;
          color: var(--white);
        }
      }

      .add-container-submit-btn:hover {
        opacity: 0.7;
      }
    }
  }
`;

export default AppUsersEdit;
