import React from "react";
import styled from "styled-components";

//router
import { Link } from "react-router-dom";

//icons
import { Facebook } from "@styled-icons/fa-brands/Facebook";

const Footer = () => {
  const companyData = [
    {
      isLink: false,
      text: "Reģistrācijas nr. 43603082057",
    },
    {
      isLink: false,
      text: "Brāļu Kaudzīšu iela 43, Latgales priekšpilsēta, Rīga, LV-1021, Latvija",
    },
    {
      isLink: true,
      link: "tel:+37128744299",
      text: "+371 28 744 299",
    },
    {
      isLink: true,
      link: "mailto:citrent@citrent.lv",
      text: "citrent@citrent.lv",
    },
  ];

  const pageData = [
    {
      isLink: true,
      link: "/",
      text: "Sākums",
    },
    {
      isLink: true,
      link: "/rent",
      text: "Nomas katalogs",
    },
    // {
    //   isLink: true,
    //   link: "/shop",
    //   text: "Tirdzniecība",
    // },
    {
      isLink: true,
      link: "/about",
      text: "Par mums",
    },
    {
      isLink: true,
      link: "/contact",
      text: "Kontakti",
    },
  ];

  const usefulData = [
    {
      isLink: true,
      link: "/privacy-policy",
      text: "Privātuma politika",
    },
    {
      isLink: true,
      link: "/rent-rules",
      text: "Īres nosacījumi",
    },
    {
      isLink: true,
      link: "/cookie-policy",
      text: "Sīkfailu politika",
    },
  ];

  const socialData = [
    {
      isLink: true,
      link: "https://www.facebook.com/citrent/",
      icon: Facebook,
    },
  ];

  return (
    <Wrapper>
      <footer>
        <div className="footer-column">
          <img
            src="/images/main/logo.png"
            alt="footer-logo"
            className="footer-logo"
          />
          {companyData?.map((item, index) =>
            !item?.isLink ? (
              <p key={index}>{item?.text}</p>
            ) : (
              <div>
                <Link to={item?.link} key={index}>
                  <p>{item?.text}</p>
                </Link>
              </div>
            )
          )}
        </div>

        <div className="footer-column">
          <h4>Lapas karte</h4>
          {pageData?.map((item, index) =>
            !item?.isLink ? (
              <p key={index}>{item?.text}</p>
            ) : (
              <div>
                <Link
                  to={item?.link}
                  key={index}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <p>{item?.text}</p>
                </Link>
              </div>
            )
          )}
        </div>

        <div className="footer-column">
          <h4>Noderīgi</h4>
          {usefulData?.map((item, index) =>
            !item?.isLink ? (
              <p key={index}>{item?.text}</p>
            ) : (
              <div>
                <Link to={item?.link} key={index} onClick={() => { window.scrollTo(0,0); }}>
                  <p>{item?.text}</p>
                </Link>
              </div>
            )
          )}
        </div>

        <div className="footer-column">
          <h4>Sociālie tīkli</h4>
          {socialData?.map((item, index) => (
            <div>
              <Link
                to={item?.link}
                key={index}
                target="_blank"
                rel="noreferrer"
              >
                {<item.icon className="icon" />}
              </Link>
            </div>
          ))}
        </div>
      </footer>

      <div className="copyright">
        <h4>Visas tiesības aizsargātas © CITRENT.lv</h4>
        <p>Mājaslapu izstrādāja</p>
        <Link to="https://jaaigroup.com/" target="_blank" rel="noreferrer">
          jaaigroup.com
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background-color: var(--black);
  padding: 50px 0;

  @media only screen and (max-width: 1150px) {
    padding: 50px 20px;
  }

  .copyright {
    max-width: 1100px;
    margin: 20px auto 0;
    display: flex;
    flex-direction: column;
    align-items: end;

    @media only screen and (max-width: 700px) {
      align-items: center;
    }

    h4,
    p {
      font-size: 0.9rem;
      color: var(--white);
      text-align: center;
    }

    p {
      margin: 10px 0 0 0;
    }

    a {
      text-decoration: none;
      color: var(--green);
      font-size: 0.9rem;
      border-bottom: 1px solid transparent;
      transition: 0.3s ease;

      &:hover {
        border-bottom: 1px solid var(--green);
      }
    }
  }

  footer {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .footer-logo {
      width: 130px;
      margin: 0 0 10px 0;
    }

    .footer-column {
      flex: 1;
      min-width: 250px;
      max-width: 400px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      p {
        color: var(--white);
        font-size: 0.9rem;
      }

      a {
        text-decoration: none;
        transition: 0.3s ease;
        display: inline-block;

        &:hover {
          opacity: 0.5;
        }
      }

      h4 {
        font-size: 1rem;
        font-weight: bold;
        color: var(--white);
        margin: 0 0 10px 0;
      }

      .icon {
        max-width: 24px;
        max-height: 24px;
        width: 24px;
        width: 100%;
        color: var(--white);
      }
    }
  }
`;

export default Footer;
