import React from "react";
import styled from "styled-components";

//components
import PageHeading from "../components/PageHeading";

//hemlet
import { Helmet } from "react-helmet";

const RentRules = () => {
  const text = `
1. Piemērošana
1.1 Šie Vispārējie nomas noteikumi (turpmāk – Noteikumi) piemērojami visiem CitRent SIA (turpmāk – Iznomātājs) noslēgtajiem celtniecības mašīnu (bez vadītāja) un celtniecības aprīkojuma un inventāra (turpmāk – Nomas objekts) nomas līgumiem, kas tiek slēgti ar Iznomātāja klientiem (turpmāk – Nomnieki). Šie Noteikumi ir neatņemama nomas līguma sastāvdaļa. Pušu paraksti uz nomas līguma apliecina, ka tie pilnībā iepazinušies ar šiem Noteikumiem, saprot tos un piekrīt tiem.

2. Nomas objekts un tā nodošana
2.1. Iznomātājs nodod Nomniekam un Nomnieks pieņem lietošanā par atlīdzību Nomas objektu, kas noteikts nomas līgumā. Detalizēti nomas noteikumi tiek atrunāti nomas līgumā.
2.2. Papildus Nomas objekta nomai Iznomātājs par atsevišķu samaksu var sniegt Nomniekam citus pakalpojumus, ieskaitot, bet neaprobežojoties ar Nomas objekta piegādi Nomniekam, uzstādīšanu, demontāžu, remontu, detaļu piegādi utml. (turpmāk – Pakalpojumi). Konkrētie Pakalpojumi un to cenas tiek atrunātas nomas līgumā.
2.3. Nomas objekts visā nomas līguma termiņā paliek Iznomātāja īpašums. Nomniekam nav tiesību nodot Nomas objektu trešajām personām bez Iznomātāja rakstiskas atļaujas, kā arī nav tiesību to ieķīlāt vai kā citādi apgrūtināt.
2.4. Iznomātājs nodod Nomas objektu Nomniekam Iznomātāja atrašanās vietā Rīgā, Brāļu Kaudzīšu ielā 43, ja vien Puses nevienojas citādāk nomas līgumā.
2.5. Nomas objekts tiek nodots Nomniekam nomas līguma noslēgšanas dienā, kā arī saņemts no Nomnieka nomas termiņa beigās ar pieņemšanas - nodošanas aktu, ko paraksta abu pušu pilnvaroti pārstāvji. Pieņemšanas – nodošanas aktā tiek norādīts Nomas objekta sastāvs, tehniskais stāvoklis un defektu apraksts (ja tādi ir).
2.6. Nododot Nomas objektu Nomnieka rīcībā, tam jābūt lietošanas kārtībā, pilnā tehniskā komplektācijā, tīram, kā arī nodrošinātam ar atbilstošu aizsardzības un drošības aprīkojumu.
2.7 Iznomātājam pēc Nomnieka pieprasījuma jāizsniedz Nomniekam instrukcijas latviešu valodā Nomas objekta lietošanai, uzturēšanai un apkopei.
2.8. Nomas objektu nododot atpakaļ Iznomātājam nomas termiņa beigās, tam jābūt tīram un ne sliktākā tehniskā stāvoklī kā nomas termiņa sākumā, neņemot vērā normālu nolietojumu. Citā gadījumā Iznomātājs ir tiesīgs veikt nepieciešamo tīrīšanu un remontu uz Nomnieka rēķina saskaņā ar Iznomātāja Pakalpojumu izcenojumiem.
2.9. Nomniekam un Iznomātājam attiecīgi pieņemot Nomas objektu nomas termiņā sākumā un beigās, ir pienākums pārbaudīt Nomas objekta tehnisko stāvokli un komplektāciju. Jebkuras sūdzības par Nomas objekta tehnisko stāvokli jāiesniedz otrai Pusei rakstveidā ne vēlāk kā nedēļas laikā no brīža, kad Nomas objekts nodots Nomniekam lietošanā, vai atdots Iznomātājam nomas termiņa beigās.

3. Pušu tiesības un pienākumi
3.1. Iznomātājam pirms nomas līguma parakstīšanas ir tiesības pieprasīt Nomniekam iesniegt ziņas par tā juridisko statusu un pārstāvības tiesībām, kā arī finanšu datus un izziņu par nodokļu nomaksu. Ja Nomnieks atsakās sniegt šādu pieprasīto informāciju vai sniegtā informācija Iznomātājam rada šaubas par Nomnieka maksātspēju, Iznomātājam ir tiesības prasīt Nomas maksas priekšapmaksu 100% apmērā.
3.2. Iznomātājam ir tiesības pirms nomas līguma parakstīšanas, kā arī visā nomas līguma darbības laikā ierasties Nomas objekta izmantošanas vietā, lai pārliecinātos par tās atbilstību Nomas objekta lietošanas prasībām. Nomniekam nav tiesību ierobežot Iznomātāja tiesības veikt minētās pārbaudes.
3.3. Iznomātājs atbild par Nomas objekta obligāto tehnisko apskati saskaņā ar normatīvo aktu prasībām.
3.4. Nomnieks sedz visus Nomas objekta lietošanas izdevumus, tajā skaitā degvielas izmaksas, darbaspēka izmaksas, nepieciešamās remonta izmaksas, ja vien Puses nomas līgumā nevienojas citādi, kā arī ir atbildīgs par visas Nomas objektam nepieciešamās ikdienas apkopes veikšanu.
3.5. Iznomātājs pēc Nomnieka pasūtījuma par atsevišķu samaksu piegādā Nomas objekta rezerves daļas un veic atbilstošus Nomas objekta un tā rezerves daļu remontdarbus. Nomas objekta vai tā daļu remonts un apkope, izņemot garantijas remontu, tiek veikti saskaņā ar Iznomātāja pakalpojumu izcenojumiem.
3.6. Nomnieks ir pilnībā atbildīgs par drošu un pareizu Nomas objekta lietošanu saskaņā ar lietošanas instrukcijām, ugunsdrošības, sanitārajiem un higiēnas noteikumiem un citām spēkā esošajām normatīvo aktu prasībām, tajā skaitā par darbu ar bīstamām iekārtām un pilnu civiltiesisku atbildību par zaudējumiem, kas radušies minēto noteikumu neievērošanas rezultātā. Iznomātājs pie nomas līguma noslēgšanas var pieprasīt Nomnieku uzrādīt spēkā esošu atļauju darbam ar bīstamajām iekārtam, kas izdota atbilstoši normatīvo aktu prasībām.
3.7.  Nomnieks ir pilnībā atbildīgs par tiesību aktu prasībām atbilstoša, kvalificēta, speciāli attestēta un apmācīta apkalpojošā personāla nodarbināšanu darbam ar Nomas objektu, tajā skaitā par spēkā esošo noteikumu attiecībā uz darbiniekiem, kas pielaižami pie darba ar bīstamām iekārtām ievērošanu.
3.8. Nomnieks drīkst izmantot Nomas objektu tikai tam paredzētiem darba uzdevumiem un tam piemērotos apstākļos. Nomniekam nav tiesību uz jebkāda pamata nodot Nomas objektu trešajām personām vai mainīt Nomas objekta izmantošanas vietu bez Iznomātāja rakstveida piekrišanas.
3.9. Nomniekam aizliegts lietot Nomas objektu, ja ir atklājies kāds tehnisks defekts kā arī Nomniekam aizliegts remontēt Nomas Objektu pašu spēkiem nesaskaņojot to rakstiski ar Iznomātāja Servisa vadītāju. Šādos gadījumus Nomnieka pienākums ir nekavējoties, bet ne vēlāk kā 24 stundu laikā informēt Iznomātāju par Nomas objektam atklātajiem defektiem.
3.10. Nomnieks drīkst izmantot Nomas objektu vienā maiņā maksimums astoņas (8) stundas, ja puses nav vienojušās par garāku izmantošanas laiku.
3.11. Nomniekam ir pienākums darba laikā Nomas Objektu nodot Nomnieka atbildīgās personas uzraudzībā, neatstājot to bez uzraudzības, un ārpus darba laika Nomas Objektam ir jāatrodas slēgtās telpās, kuras ir konstruktīvi drošas pret zādzībām, t.i., telpām, kurās atrodas Nomas Objekts, ir jābūt aprīkotām ar ugunsdrošības signalizāciju un pretielaušanās signalizāciju, kas pieslēgta pie licencētas apsardzes kompānijas vai arī jābūt licencētas apsardzes kompānijas fiziskai apsardzei.
3.12. Iznomātājs var apdrošināt Nomas objektu. Nomnieka pienākums ir nekavējoties, bet ne vēlāk kā divdesmit četru (24) stundu laikā rakstveidā paziņot Iznomātājam, vai nepieciešamības gadījumā arī tiesībsargājošām iestādēm, par jebkuru negadījumu, kas saistīts ar Nomas objekta lietošanu un kura rezultātā Nomas objekts ir bojāts, nodarīti materiālie zaudējumi vai cietuši cilvēki. Nomnieka pienākums ir veikt visas nepieciešamās un iespējamās darbības, lai izvairīto no vai samazinātu tālāku zaudējumu rašanos Iznomātājam. Nepaziņošanas gadījumā, Nomnieks ir atbildīgs par jebkuriem zaudējumiem, ka Iznomātājam radušies šādas savlaicīgas neziņošanas rezultātā, tajā skaitā par neizmaksātu vai daļēji neizmaksātu apdrošināšanas atlīdzību.
3.13. Nomnieks ir atbildīgs par Nomas objekta tehniskās dokumentācijas (tehniskās pases u.c.) pienācīgu saglabāšanu. Gadījumā, ja dokumentācija tiek pazaudēta, Nomnieka pienākums ir uz sava rēķina veikt tās atjaunošanu.
3.14. Iznomātājs neatbild par zaudējumiem, kas nodarīti trešajām personām Nomas objekta nomas laikā Nomnieka vai trešo personu vainas dēļ un Nomnieks nodrošina Iznomātāju pret jebkuru šādu trešo personu prasījumu.
3.15. Gadījumā, ja Nomnieks nepilda nomas līguma noteikumus, Iznomātājam ir tiesības nodot savas prasījuma tiesības saistībā ar nomas līgumu, kā arī daļēju vai pilnīgu nomas līguma izpildes kontroli trešajām personām, tajā skaitā visu informāciju par Nomnieku, kas ir Iznomātāja rīcībā saistībā ar nomas līgumu. Šādā gadījumā Nomnieks apmaksā arī trešo personu sniegto pakalpojumu izmaksas nomas līguma izpildes nodrošināšanai.
3.16. Puses vienojas, ka attaisnojuma dokumenti, t.sk., rēķini tiek sagatavoti elektroniski un rēķini nomniekam tiek nosūtīti pa pastu parastā vēstulē.
3.17. Nomnieks apņemas regulāri veikt pilnvaroto personu un tiem izsniegto Nomas objektu uzraudzību un garantē, ka pilnvarotās personas darbību vai bezdarbību rezultātā Nomas objektam nodarīto bojājumu, izlaišanas no faktiskā valdījuma (tajā skaitā arī pieļaujot tā zādzību, nolaupīšanu vai piesavināšanos), pilnīgas vai daļējas iznīcināšanas rezultātā veiks pilnīgu Iznomātājam nodarīto zaudējumu apmaksu.
3.18. Persona, kas paraksta nomas līgumu Nomnieka vārdā, piekrīt visiem Noteikumiem, kā arī uzņemas atbildību par nomas līguma izpildi un Noteikumu ievērošanu, un saistību neizpildīšanas gadījumā uzņemas atbildību par nomas līguma un Noteikumu izpildi kā pats parādnieks.

4. Nomas termiņš
4.1. Nomas termiņu rēķina no tās dienas, kad Nomas objekts saskaņā ar nomas līgumu ir nodots Nomniekam, līdz dienai, kad Nomas objekts tiek atdots atpakaļ Iznomātājam, atbilstoši pieņemšanas – nodošanas aktu parakstīšanas datumiem. Ja Nomnieks vilcinās pieņemt Nomas objektu vietā, kas noteikta nomas līgumā, nomas termiņš tiek rēķināts no nomas līguma noslēgšanas dienas.
4.2. Nomas termiņš precīzi tiek noteikts nomas līgumā. Ja nomas līgumā termiņš nav noteikts, par minimālo nomas termiņu uzskatāms ir viens mēnesis (turpmāk – Minimālais termiņš). Nomas termiņā tiek ietvertas visas dienas, izņemot svētdienas un svētku dienas.
4.3. Ja Nomnieks vismaz 3 (trīs) dienas pirms termiņa beigām nav rakstveidā paziņojis par vēlmi izbeigt nomas līgumu termiņa beigās un Nomnieks ir izpildījis visas nomas līguma saistības, Iznomātājs var pagarināt nomas līgumu uz vienu Minimālo termiņu, izdarot atbilstošu atzīmi Nomas līgumā un paziņojot Nomniekam. Šis noteikums attiecas arī uz jebkuru termiņa pagarinājumu.
4.4. Ja nomas līgumā paredzēts nomas termiņš, uzskatāms, ka tas izbeidzas plkst. 16.00 pēdējā nomas termiņa dienā.
4.5. Ja nomas termiņš nav noteikts, tas saskaņā ar omas līguma noteikumiem beidzas nomas līguma izbeigšanas dienā plkst. 16.00. Ja tiek saņemta Iznomātāja atļauja, Nomniekam ir jāatdod Nomas objekts līdz nākamās darba dienas plkst.10:00.
4.6. Ja Nomnieks ir aizkavējis Nomas Objekta atgriešanu ilgāk par 3 (trīs) darba dienām un Iznomātajam nav darījis zināmu kavējuma iemeslu, kā arī Iznomātājs nav pagarinājis nomas līgumu Noteikumu 4.3.punktā aprakstītajā kārtībā, Iznomātājam ir tiesības uzskatīt Nomas objektu par izgājušu no Nomnieka valdījuma un prasīt zaudējumu atlīdzību Noteikumu un Latvijas Republikas tiesību aktu noteiktajā kārtībā.

5. Samaksa
5.1. Nomas maksa tiek aprēķināta un maksāta no brīža, kad Nomas objekts nodots Nomniekam, līdz brīdim, kad tas atdots Iznomātājam saskaņā ar Iznomātāja un Nomnieka parakstītu pieņemšanas - nodošanas aktu.
5.2. Iznomātājs ir tiesīgs vienpusēji piešķirt, pārskatīt vai samazināt Nomas objekta nomai sniegtās atlaides, kas ir tikušas piemērotas Nomniekam.
5.3. Gadījumā, ja Nomas objekts gājis bojā, nozaudēts vai jebkādu citu iemeslu dēļ izgājis no Nomnieka valdījuma, Nomas maksa par Nomas objekta lietošanu tiek aprēķināta līdz brīdim, kad Iznomātājs grāmatvedībā saņēmis Nomnieka rakstveida paziņojumu par Nomas objekta pazušanas vai izbeigšanās faktu. Šāds paziņojums neatbrīvo Nomnieku no zaudējumu atlīdzināšanas pienākuma saskaņā ar nomas līgumu un Noteikumiem.
5.4. Nomas maksas aprēķina periods tiek noteikts nomas līgumā. Gadījumā, ja nomas termiņš ir īsāks par vienu (1) mēnesi, nomas maksas aprēķina periods ir viena darba diena. Nomas maksas aprēķina periods nevar būt mazāks par vienu darba dienu. Nomas maksas aprēķins tiek veikts, pamatojoties uz Iznomātāja pakalpojumu cenu lapu.
5.5 Ja nomas līgumā nav noteikts citādāk, nomas maksa maksājama avansā par visu nomas līgumā noteikto nomas termiņu, ja tas ir mazāks par vienu (1) mēnesi, bet ja Nomas termiņš ir garāks par vienu (1) mēnesi nomas maksa maksājama avansā par pirmo nomas termiņa mēnesi, bet turpmākie maksājumi veicami vienu reizi nomas maksas aprēķina periodā, ne vēlāk kā trešajā kārtējā nomas maksas aprēķina perioda dienā, par iepriekšējo nomas maksas aprēķina periodu.

5.6. Par visiem papildus pakalpojumiem (tajā skaitā Nomas objekta transportēšana uz Nomnieka norādīto adresi un atdošana Iznomātājam termiņa beigās, samontēšana, izjaukšana u.c.), ko veic Iznomātājs, un kuri norādīti nomas līgumā, Nomnieks veic samaksu avansā, ne vēlāk kā trīs (3) dienu laikā pēc Nomas objekta pieņemšanas un/vai Iznomātāja rēķina saņemšanas.
5.7. Papildus jebkuriem maksājumiem Nomnieks maksā pievienotās vērtības nodokli, ja maksājamā summa apliekama ar to. Maksājamais pievienotās vērtības nodokļa apmērs tiek norādīts rēķinā.
5.8. Iznomātāja rēķinos norādītās summas maksājamas ne vēlāk kā līdz datumam, kas norādīts rēķinā. Ja Iznomātāja rēķinā norādītais samaksas termiņš ir vēlāks nekā Noteikumos minētais, nokavējuma procenti par maksājuma kavējumu aprēķināmi no rēķinā norādītās apmaksas pēdējās dienas.

6. Drošības garantijas
6.1. Pirms nomas līguma noslēgšanas Iznomātājam ir tiesības pieprasīt no Nomnieka drošības naudu vai cita veida garantiju. Drošības naudas apmērs tiek noteikts pirms nomas līguma noslēgšanas, kā arī tas tiek norādīts nomas līgumā. 
6.2. Ja Iznomātājs ir pieprasījis Nomniekam maksāt drošības naudu ar bankas pārskaitījumu, Nomnieks drošības naudu maksā saskaņā ar Iznomātāja izrakstītu rēķinu. Drošības nauda Nomniekam ir jāsamaksā rēķinā norādītajā termiņā, ieskaitot to rēķinā norādītajā Iznomātāja kredītiestādes kontā. Ja drošības nauda rēķinā norādītajā termiņā netiek samaksāta, Iznomātājam nav pienākums Nomniekam iznomāt Nomas objektu un attiecīgi noslēgt nomas līgumu.
6.3. Ja drošības nauda no Nomnieku tiek pieņemta skaidrā naudā, tad Nomnieks un Iznomātājs paraksta aktu par skaidras naudas pieņemšanu-nodošanu divos eksemplāros, no kuriem viens glabājas pie Iznomātāja, bet otrs – pie Nomnieka.
6.4. Nomnieka samaksātā drošības nauda tiek atmaksāta Nomniekam piecu (5) darba dienu laikā pēc dienas, kad nomas līgums ir izpildīts, Nomnieks ir veicis visu Iznomātāja izrakstīto rēķinu apmaksu par iznomātajiem Nomas objektiem, un Iznomātājam nav pretenziju pret Nomnieku par Nomas objekta stāvokli (tas ir nodots tādā pašā stāvoklī, kā tika nodots Nomniekam, ņemot vērā Nomas objekta normālu nolietojumu). Drošības nauda tiek atmaksāta ar pārskaitījumu uz Nomnieka kredītiestādes kontu, ja drošības nauda tika samaksāta ar bankas pārskaitījumu. Ja drošības nauda tika saņemta skaidrā naudā, tad drošības nauda tiek atmaksāta brīdī, kad Nomnieks un Iznomātājs paraksta skaidras naudas nodošanas-pieņemšanas aktu.
6.5. Iznomātājam ir tiesības izmantot drošības naudu Nomnieka kavēto maksājuma saistību segšanai, kā arī zaudējumu segšanai. Neizlietotā drošības naudas summa tiek atmaksāta Nomniekam nomas termiņa beigās, vai atbilstošā apmērā ieskaitīta pret Nomnieka maksājuma saistību saskaņā ar nomas līgumu.
6.6. Iznomātājam ir tiesības prasīt Nomniekam trešās personas galvojumu Nomas objekta vērtības pilnā vai daļējā apmērā.

7. Atbildība
7.1. Ja Nomnieks kavē jebkurus no Nomas līgumā paredzētajiem maksājumiem, tas maksā Iznomātājam nokavējuma procentus par kapitāla lietošanu 0,5% (puse no viena procenta) apmērā no nokavētās summas par katru kavējuma dienu. Nokavējuma procentu par kapitāla lietošanu samaksa neatbrīvo Nomnieku no maksājumu saistības izpildes.
7.2. Jebkura Nomnieka iemaksātā summa vispirms tiek ieskaitīta nokavējuma procentu dzēšanai, un tikai pēc tam pamatsummas dzēšanai.
7.3. Iznomātājs pilnībā atbild par izmaksām, kas saistītas ar normāla Nomas objekta nolietojuma sekām.
7.4. Nomnieks pilnībā atbild par zaudējumiem, kas radušies Iznomātājam Nomas objekta nozaudēšanas, bojāejas rezultātā, kā arī par bojājumiem, kuri radušies Nomas objekta lietošanas laikā, un kuri nevar tikt uzskatīti par normālu nolietojumu un kuri nav radušies Iznomātāja vainas dēļ.
7.5. Nomas termiņa ietvaros Nomnieks ir atbildīgs par jebkādiem Nomas objekta bojājumiem vai zaudējumiem, kas radušies Nomnieka vai trešo personu darbības vai bezdarbības dēļ.
7.6. Nomas termiņa ietvaros Nomnieks nes pilnu atbildību kā pats īpašnieks par zaudējumiem, kas Nomas objekta, kā paaugstinātas bīstamības avota, darbības rezultātā nodarīti trešo personu mantai, dzīvībai vai veselībai.
7.7. Gadījumā, ja Nomnieks pārkāpj šo Nomas Noteikumu 2.3. vai 3.8. punktu noteikumus, tas maksā Iznomātājam līgumsodu Nomas objekta vērtības apmērā. Nomas objekta vērtība tiek norādīta nomas līgumā.

8. Līguma izbeigšana
8.1. Puses var jebkurā brīdī izbeigt Nomas līgumu par to savstarpēji rakstveidā vienojoties.
8.2. Ikvienai no Pusēm ir tiesības vienpusēji izbeigt nomas līgumu gadījumā, ja pretējā puse pārkāpj nomas līguma noteikumus un nenovērš pārkāpumu trīs (3) dienu laikā pēc rakstveida pretenzijas saņemšanas.
8.3 Iznomātājs var vienpusēji nekavējoties izbeigt nomas līgumu un izņemt Nomas objektu no Nomnieka valdījuma tā atrašanās vietā, ja:
	8.3.1. nomnieks atzīts par maksātnespējīgu ar tiesas lēmumu;
8.3.2. Nomnieks kavē jebkuru nomas līgumā noteikto maksājumu ilgāk par piecām (5) dienām, vai pārkāpis citus šī līguma punktus;
8.3.3. tiek konstatēts, ka Nomnieks neievēro Nomas objekts lietošanas instrukcijas vai izmanto Nomas objektu tam neparedzētiem mērķiem, vai rada draudus trešo personu dzīvībai, veselībai vai materiālajām interesēm;
8.3.4. Nomnieks ir nodevis Nomas objektu trešajām personām vai pārvietojis Nomas objektu uz citu lietošanas vietu, vai Nomnieks nevar pierādīt, ka Nomas objekts atrodas lietošanas vietā saskaņā ar noslēgto nomas līgumu, vai jebkādi apgrūtinājis to bez Iznomātāja piekrišanas;
8.3.5. Nomnieks ir sniedzis nepatiesas ziņas vai noklusējis faktus, kas ietekmē nomas līguma izpildi.
8.4 Ja nomas līgums tiek izbeigts Noteikumu 8.3.punktā minētajā kārtībā, Nomnieks neatsaucami pilnvaro (ar pārpilnvarojuma tiesībām) Iznomātāju bez papildu juridisku procesu uzsākšanas jebkurā laikā iekļūt Nomnieka telpās vai objektā un pārņemt savā valdījumā Nomas objektu. Šādā gadījumā Nomnieks nevar izvirzīt pretenzijas par materiāliem zaudējumiem saistībā ar Nomas objekta valdījuma maiņu. Papildus Nomnieks pilnībā un bez papildus nosacījumiem sedz visus Iznomātāja izdevumus, kas nepieciešami minēto darbību veikšanai.
8.5. Jebkurā nomas līguma izbeigšanas gadījumā Nomniekam ir pienākums nekavējoties, bet ne vēlāk kā divdesmit četru (24) stundu laikā no nomas līguma izbeigšanas termiņa iestāšanās atdot Nomas objektu Iznomātājam, parakstot pieņemšanas – nodošanas aktu, un veikt nomas maksas samaksu par attiecīgo nomas maksas aprēķina periodu.
8.6. Nomas objekta atgriešana Iznomātājam nomas līguma pirmstermiņa izbeigšanas gadījumā tiek veikta uz Nomnieka rēķina, ja vien puses nevienojas citādāk.

9. Nepārvarama vara
9.1. Puses nav atbildīgas par nomas līguma saistību neizpildi un otrai pusei nodarītajiem zaudējumiem, ja tie radušies nepārvaramas varas rezultātā. Par nepārvaramu varu uzskatāmi, piemēram, karš, masu nemieri, dabas stihijas vai kāds līdzīgs notikums, kas nav atkarīgs no pušu gribas un kuru puses nevarēja paredzēt noslēdzot nomas līgumu.
9.2. Ja nepārvaramas varas apstākļu dēļ nomas līguma izpilde nav iespējama ilgāk par divām (2) nedēļām, ikviena no pusēm var izbeigt nomas līgumu, nosūtot rakstisku paziņojumu otrai pusei.

10.  Konfidencialitāte
10.1. Pušu pienākums ir rūpēties par nomas līguma konfidencialitāti. Pusēm nav tiesības atklāt šo informāciju trešajām personām, ja vien to neparedz Latvijas Republikas tiesību akti.

11. Strīdu izšķiršana
11.1 Puses ir atbildīgas par nomas līguma saistību izpildi atbilstoši Latvijas Republikas tiesību aktiem. Jebkurš strīds, domstarpība, nesaskaņa vai prasība, kas izriet no nomas līguma, skar to vai tā pārkāpšanu, izbeigšanu vai spēkā neesamību jārisina sarunu ceļā. Ja neizdodas panākt mierizlīgumu 14 (četrpadsmit) dienu laikā no strīda rašanās dienas, tad jebkurš strīds, nesaskaņa vai prasība, kas izriet no nomas līguma, tiks galīgi izšķirts Baltijas Apgabala šķīrējtiesā saskaņā ar šīs šķīrējtiesas reglamentu, rakstveida procesa kārtībā, viena šķīrējtiesneša sastāvā, nosakot tiesvedības valodu – latviešu valoda, vai tiesā Latvijas Republikas tiesību aktos noteiktajā kārtībā.

12. Citi noteikumi
12.1. Gadījumā, ja nomas līgumu parakstījusi persona, kura nav bijusi pilnvarota pārstāvēt attiecīgo pusi, viņa pati kā fiziska persona uzņemas attiecīgās puses līgumsaistības un atbild par to izpildi ar visu savu īpašumu.

13. Personas datu apstrāde
13.1. Iznomātājam un Nomniekam ir tiesības apstrādāt vienam no otra iegūtos fizisko personu datus tikai ar mērķi nodrošināt nomas līguma noteikto saistību izpildi, ievērojot normatīvajos aktos (t.sk. starptautiskajos) noteiktās prasības šādu datu apstrādei, izmantošanai un aizsardzībai.
13.2 Ja Nomnieks nepilda nomas līguma noteikumus un savlaicīgi neveic maksājumus, ar nomas līguma parakstīšanu Nomnieks piekrīt, ka Iznomātājs ir tiesīgs kavētos maksājumus nodot trešajām personām iekasēšanai pirmstiesas ceļā (inkasso) vai parāda piedziņu veikt šķīrējtiesas vai tiesas ceļā, un Nomnieks apņemas segt visus izdevumus, kas saistīti ar kavēto maksājumu iekasēšanu vai parāda piedziņu. Nomnieks piekrīt, ka Iznomātājs nodod trešajām personām datus par Nomnieku (un galvotāju, ja tāds ir bijis), kas ir Iznomātāja rīcībā, maksājumu iekasēšanas vai parāda piedziņas procedūras nodrošināšanai, kas ir uzskatāmas par Iznomātāja leģitīmo interešu realizēšanu saistībā ar noslēgto nomas līgumu.

14. Paziņojumi
14.1. Visi Pušu paziņojumi vai dokumenti otrai Pusei (ieskaitot rēķinus) nogādājami rokās pret parakstu vai nosūtāmi uz šajā Līgumā norādītajām Pušu adresēm, nosūtot ierakstītā vēstulē, izmantojot pasta pakalpojumus, nogādājami ar kurjeru vai nosūtāmi uz pušu e-pasta adresēm elektroniskā dokumenta veidā. Minētie paziņojumi un dokumenti uzskatāmi par nogādātiem otrai pusei brīdī, ka kad tie nogādāti rokās pret parakstu, vai trīs (3) dienu laikā, ja sūtīti ar kurjeru vai ierakstītā vēstulē, izmantojot pasta pakalpojumus, vai divu (2) dienu laikā, ja tie nosūtīti uz otras puses e-pasta adresi. Nomnieks apņemas nodrošināt, ka rēķinus vai citus dokumentus būs iespējams saņemt Latvijas Republikas Komercreģistrā norādītajā juridiskajā adresē vai nomas līgumā norādītajā e-pasta adresē (ja tiek norādīts), kā arī neizvirzīt pretenzijas Iznomātājam par rēķina vai citu dokumentu nesaņemšanu, ja Iznomātāja rīcībā ir paziņojums par reģistrētu iekšzemes pasta sūtījumu vai nosūtītu e-pasta sūtījumu. Puses ir atbildīgas par nomas līgumā norādītās adreses un e-pasta pareizību un korespondences saņemšanu norādītajā adresē. Par jebkurām izmaiņām Pušu adresē vai citos rekvizītos, otrai Pusei nosūtāms paziņojums šajā punktā norādītajā kārtībā.
  `;

  
  const paragraphs = text.split('\n').map((line, index) => (
    <p key={index} style={{ marginTop: line.trim().match(/^\d+\.\s/) ? '1em' : 0, fontWeight: line.trim().match(/^\d+\.\s/) ? 'bold' : 'normal', fontSize: line.trim().match(/^\d+\.\s/) ? '1.2rem' : '.9rem' }}>
      {line}
    </p>
  ));

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Īres nosacījumi - Citrent</title>
      </Helmet>
      <PageHeading
        pageName={"Īres nosacījumi"}
        pageImage={"./images/pageHeading/about.png"}
      />
      <div className="wrapper">{paragraphs}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 0 200px 0;

    @media only screen and (max-width: 1140px) {
      margin: 0 20px;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.6;
    }
  }
`;

export default RentRules;
