import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";

//config
import {
  getCategoriesApi,
  deleteCategoryApi,
  updateCategoryPositionApi,
} from "../firebase/globalConfig";

//components
import Header from "../components/Header";
import AppLogin from "./AppLogin";
import Heading from "../components/Heading";
import Banner from "../components/Banner";
import GridLoader from "react-spinners/GridLoader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import UserBar from "../components/UserBar";

//icons
import { Category } from "@styled-icons/material-twotone/Category";

//redux
import { useSelector } from "react-redux";
import { selectUser, selectUserData } from "../redux/appSlice";

const AppCategories = () => {
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const [categories, setCategories] = useState([]);
  const [categoriesFromDb, setCategoriesFromDb] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState("");
  let selectedCategory = null;

  const getCategories = async () => {
    const response = await axios.get(`${getCategoriesApi}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;
    // console.log(data);
    setCategories(data);
    setCategoriesFromDb(data);
    setLoading(false);
  };

  const searchCategory = (name) => {
    if (name?.length > 0) {
      const filteredData = categoriesFromDb.filter((item) => {
        return item.category_name.toLowerCase().includes(name.toLowerCase());
      });

      setCategories(filteredData);
    } else {
      setCategories(categoriesFromDb);
    }
  };

  const deleteCategory = async () => {
    const created = new Date();
    created.setHours(created.getHours() + 2);

    // Format the date as needed
    const formattedCreated = created
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const deleteData = {
      id: selectedCategory?.id,
      email: user?.email,
      display_name: userData?.display_name,
      noti_type: "category_deleted",
      avatar: user?.photoURL,
      noti_text: `Izdzēsa kategoriju ${selectedCategory?.category_name} un datus iekš šīs kategorijas.`,
      created: formattedCreated,
    };

    try {
      await axios.post(`${deleteCategoryApi}`, deleteData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      getCategories();
      toast.success("Kategorija izdzēsta.");
    } catch (error) {
      toast.error("Nevarējām izdzēst kategoriju. Mēģini vēlreiz.");
    }
  };

  const options = {
    title: "Vai dzēst kategoriju?",
    message:
      "Kategorija un dati iekš šīs kategorijas tiks izdzēsti neatgriezeniski.",
    buttons: [
      {
        label: "Dzēst",
        onClick: () => {
          deleteCategory();
        },
      },
      {
        label: "Atcelt",
        onClick: () => {
          selectedCategory = null;
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    // willUnmount: () => {},
    // afterClose: () => {},
    // onClickOutside: () => {},
    // onKeypress: () => {},
    // onKeypressEscape: () => {},
    overlayClassName: "confirm-modal",
  };

  //drag and drop handling
  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    // Create a copy of the categories array
    const updatedCategories = [...categories];

    // Reorder the array
    const [removed] = updatedCategories.splice(startIndex, 1);
    updatedCategories.splice(endIndex, 0, removed);

    // Update the category_position for each item in the array
    const updatedCategoriesWithPosition = updatedCategories.map(
      (item, index) => ({
        ...item,
        category_position: index,
      })
    );

    // Make a request to your backend to update the category_position
    try {
      await axios.post(`${updateCategoryPositionApi}`, {
        updatedCategories: updatedCategoriesWithPosition,
      }, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });

      // Update the state with the new order
      setCategories(updatedCategoriesWithPosition);

      toast.success("Kategoriju secība atjaunota!");
    } catch (error) {
      toast.error("Kļūda atjaunojot kategoriju secību. Mēģini vēlreiz.");
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    searchCategory(searchName);
    //eslint-disable-next-line
  }, [searchName]);

  return (
    <Wrapper>
      {user ? (
        <>
          <Header />
          <div className="container">
            <Heading name={"Kategorijas"} desc={"Kategoriju saraksts"} />
            {loading ? (
              <>
                <LoadingContainer>
                  <GridLoader color="#ff6a00" />
                </LoadingContainer>
              </>
            ) : (
              <>
                <div className="container-top">
                  <Banner
                    Icon={Category}
                    name={"Kategorijas"}
                    count={categories?.length}
                  />
                  <div className="search-box">
                    <div className="search-box-input">
                      <h3>Meklēt</h3>
                      <input
                        type="text"
                        className="search-input"
                        placeholder="Meklēt pēc kategorijas nosaukuma"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                      />
                    </div>

                    <div className="search-box-btn">
                      <Link to="/app/categories/add">
                        <div className="search-box-btn-action">
                          <p>Pievienot</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="container-bottom">
                  <div className="table-top">
                    <p>Nr. p. k.</p>
                    <p>Ikona</p>
                    <p>Nosaukums</p>
                    <p>Pievienoja</p>
                    <p>Iespējas</p>
                  </div>

                  <div className="table-data">
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="categories">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {categories
                              ?.sort(
                                (a, b) =>
                                  a.category_position - b.category_position
                              )
                              .map((item, index) => {
                                // console.log(item);
                                const created = `${item?.created}`;
                                const edited = `${item?.edited}`;
                                const createdDate = new Date(created);
                                const editedDate = new Date(edited);

                                const createdYear = createdDate.getFullYear();
                                const createdMonth = String(
                                  createdDate.getMonth() + 1
                                ).padStart(2, "0"); // Month is zero-based
                                const createdDay = String(
                                  createdDate.getDate()
                                ).padStart(2, "0");
                                const createdHours = String(
                                  createdDate.getHours()
                                ).padStart(2, "0");
                                const createdMinutes = String(
                                  createdDate.getMinutes()
                                ).padStart(2, "0");

                                const editedYear = editedDate.getFullYear();
                                const editedMonth = String(
                                  editedDate.getMonth() + 1
                                ).padStart(2, "0");
                                const editedDay = String(
                                  editedDate.getDate()
                                ).padStart(2, "0");
                                const editedHours = String(
                                  editedDate.getHours()
                                ).padStart(2, "0");
                                const editedMinutes = String(
                                  editedDate.getMinutes()
                                ).padStart(2, "0");

                                const formattedCreated = `${createdDay}.${createdMonth}.${createdYear} ${createdHours}:${createdMinutes}`;
                                const formattedEdited = `${editedDay}.${editedMonth}.${editedYear} ${editedHours}:${editedMinutes}`;

                                let editedDatetime = null;

                                if (formattedCreated !== formattedEdited) {
                                  editedDatetime = `${formattedEdited}`;
                                }

                                return (
                                  <Draggable
                                    key={item?.id}
                                    draggableId={item?.id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div
                                          className="table-data-single"
                                          key={index}
                                        >
                                          <div className="table-data-single-box">
                                            {index + 1}.
                                          </div>
                                          <div className="table-data-single-box">
                                            <img
                                              src={`/images/categories/${item?.category_icon}`}
                                              alt="category-img"
                                              className="category-img"
                                            />
                                          </div>
                                          <div className="table-data-single-box">
                                            <Link
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              to={`/rent/${item?.link}`}
                                            >
                                              <p className="table-data-single-box-top">
                                                {item?.category_name}
                                              </p>
                                            </Link>
                                            <p className="table-data-single-box-bottom">
                                              Pievienots: {formattedCreated}
                                            </p>
                                            <p className="table-data-single-box-bottom">
                                              {editedDatetime !== null &&
                                                ` Labots: ${formattedEdited}`}
                                            </p>
                                          </div>
                                          <div className="table-data-single-box">
                                            <UserBar
                                              img={item?.avatar}
                                              name={item?.display_name}
                                            />
                                          </div>
                                          <div className="table-data-single-box">
                                            {item?.category_name !==
                                              "Pārdošana" && (
                                              <>
                                                <Link
                                                  to={`/app/categories/edit/${item?.id}`}
                                                >
                                                  <div className="table-data-single-box-btn">
                                                    Labot
                                                  </div>
                                                </Link>
                                                <div
                                                  className="table-data-single-box-btn"
                                                  onClick={() => {
                                                    confirmAlert(options);
                                                    selectedCategory = item;
                                                  }}
                                                >
                                                  Dzēst
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <AppLogin />
      )}
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .container-bottom {
      margin: 20px 0 0 0;
      border: 1px solid var(--lightgrey);
      border-radius: 5px;
      overflow: scroll;
      max-height: 600px;

      .table-top {
        padding: 20px;
        border-bottom: 1px solid var(--lightgrey);
        display: flex;
        gap: 10px;
        position: sticky;
        top: 0px;
        background: var(--white);
        min-width: 750px;

        p {
          font-size: 0.9rem;
          font-weight: 600;
          max-width: 200px;
          min-width: 120px;
          width: 100%;
        }

        p:nth-child(1) {
          max-width: 80px;
          min-width: 80px;
        }

        p:nth-child(2) {
          max-width: 120px;
          min-width: 120px;
        }

        p:nth-child(3) {
          max-width: 300px;
          min-width: 200px;
        }

        p:nth-child(4) {
          max-width: 200px;
          min-width: 200px;
        }
      }

      .table-data {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .table-data-single {
          display: flex;
          gap: 10px;
          padding: 15px 0;
          background: white;

          .table-data-single-box {
            font-size: 0.9rem;
            max-width: 200px;
            min-width: 120px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            .category-img {
              max-width: 32px;
              object-fit: contain;
            }

            a {
              color: var(--black);
              text-decoration: none;
              transition: 0.3s ease-in-out;
            }

            a:hover {
              opacity: 0.5;
            }

            .product-img {
              width: 100%;
              height: 60px;
              object-fit: contain;
            }

            .table-data-single-box-top {
              font-size: 0.9rem;
              font-weight: 600;
              margin: 0 0 5px 0;
            }

            .table-data-single-box-bottom {
              font-size: 0.7rem;
              color: var(--grey);
            }

            .table-data-single-box-btn {
              font-size: 0.8rem;
              background: var(--black);
              color: var(--white);
              border-radius: 5px;
              display: inline-block;
              padding: 5px 10px;
              cursor: pointer;
              transition: 0.3s ease-in-out;
              margin: 0 10px 10px 0;
            }

            .table-data-single-box-btn:hover {
              opacity: 0.7;
            }
          }

          .table-data-single-box:nth-child(1) {
            max-width: 80px;
            min-width: 80px;
          }

          .table-data-single-box:nth-child(2) {
            max-width: 120px;
            min-width: 120px;
          }

          .table-data-single-box:nth-child(3) {
            max-width: 300px;
            min-width: 200px;
          }

          .table-data-single-box:nth-child(4) {
            max-width: 200px;
            min-width: 200px;
          }
        }
      }
    }

    .container-top {
      display: flex;
      gap: 10px;

      @media only screen and (max-width: 650px) {
        flex-direction: column;
      }

      .search-box {
        display: flex;
        flex: 1 0 0;
        gap: 10px;

        .search-box-input {
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
          justify-content: end;

          h3 {
            font-size: 1.2rem;
            color: var(--black);
            margin: 0 0 10px 0;
          }

          input {
            width: 100%;
            outline: none;
            border: 1px solid var(--lightgrey);
            border-radius: 5px;
            font-size: 0.9rem;
            padding: 10px 20px;
          }

          input::placeholder {
            font-size: 0.9rem;
            color: var(--grey);
          }
        }

        .search-box-btn {
          display: flex;
          align-items: flex-end;

          a {
            text-decoration: none;
            color: var(--white);
          }

          .search-box-btn-action {
            background: var(--orange);
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            transition: 0.3s ease-in-out;

            p {
              color: var(--white);
              font-size: 0.9rem;
            }
          }

          .search-box-btn-action:hover {
            background: var(--lightorange);
          }
        }
      }
    }
  }
`;

export default AppCategories;
