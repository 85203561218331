import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axios from "axios";

//components
import PageHeading from "../components/PageHeading";
import GridLoader from "react-spinners/GridLoader";

//icons
import { Facebook } from "@styled-icons/fa-brands/Facebook";
import { Phone } from "@styled-icons/boxicons-solid/Phone";
import { Mail } from "@styled-icons/entypo/Mail";
import { RemoveCircle } from "@styled-icons/ionicons-sharp/RemoveCircle";
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline";
import { CheckCircle } from "@styled-icons/bootstrap/CheckCircle";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectContactItems,
  updateContactItems,
} from "../../app/redux/appSlice";

//config
import { sendEmailApi } from "../../app/firebase/globalConfig";

//hemlet
import { Helmet } from "react-helmet";

const Contact = () => {
  const dispatch = useDispatch();
  const contactItems = useSelector(selectContactItems);
  // console.log(contactItems);

  const removeContactItem = (item) => {
    const updatedContactItems = contactItems?.filter(
      (i) => i?.product_number !== item?.product_number
    );
    dispatch(updateContactItems(updatedContactItems));
  };

  //inputs
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  //inputs check
  const [alert, setAlert] = useState(null);
  const [messageLength, setMessageLength] = useState(500);

  //email sending progress
  //compose, being-sent, sent, error
  const [emailProgress, setEmailProgress] = useState("compose");

  const handleEmail = (e) => {
    e.preventDefault();

    if (name === "" || email === "" || message === "") {
      setAlert("Lūdzu pārbaudiet vai nepieciešamās ailes ir aizpildītas.");
    } else {
      setAlert(null);
      const formSubmitData = {
        name: name,
        email: email,
        phone_number: number,
        email_message: message,
      };

      sendEmailFunc(formSubmitData, contactItems);
    }
  };

  const sendEmailFunc = async (formData, contactItems) => {
    try {
      setEmailProgress("being-sent");
      const dataToSend = { formData, contactItems };

      const response = await axios.post(
        sendEmailApi,
        JSON.stringify(dataToSend),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );

      // Check the response status
      if (response.status === 200) {
        const data = response.data;
        setEmailProgress("sent");
        // console.log(data);
        // Process and use the data here, e.g., update the UI
      } else {
        setEmailProgress("error");
        // console.error("Request failed with status:", response.status);
        // Handle errors, e.g., display an error message to the user
      }
    } catch (error) {
      setEmailProgress("error");
      // console.error("Error:", error);
      // Handle errors, e.g., display an error message to the user
    }
  };

  const resetEmailInputs = () => {
    setName("");
    setEmail("");
    setNumber("");
    setMessage("");
    dispatch(updateContactItems([]));
    setEmailProgress("compose");
  };

  const calculateMessageLength = () => {
    let messageString = message.replace(/\s/g, "");
    setMessageLength((messageLength) => 500 - messageString.length);
  };

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kontakti - Citrent</title>
      </Helmet>
      <PageHeading
        pageName={"Kontakti"}
        pageImage={"./images/pageHeading/contact.png"}
      />

      <div className="wrapper">
        <div className="wrapper-left">
          <div className="text-block">
            <h3>Vai jums ir kādi jautājumi vai komentāri?</h3>
            <p>
              Vai arī jūs redzat, ko uzlabot? Mēs labprāt dzirdētu no jums!
              Sazinieties ar mūsu klientu apkalpošanas nodaļu.
            </p>
          </div>

          <div className="text-block">
            <h3>Jautājumi par produktiem</h3>
            <p>
              Ja Jums rodas jautājumi par mūsu produktiem vai lai rezervētu
              aprīkojuma nomu, zvaniet uz mūsu tālruņa nr, vai rakstiet e-pastu
              izmantojot formu. Par aprīkojuma jautājumiem, Jums ir iespēja
              izvēlēties interesējošo tehniku, attiecīgajai tehnikai uzspiežot
              "Uzzināt vairāk", tas tiks pievienots jūsu e-pasta ziņā. Paldies!
            </p>
          </div>

          <div className="text-block">
            <h3>Sazinieties ar mums</h3>

            <div className="contact-links">
              <Link to="tel:+37128744299">
                <div className="contact-link">
                  <Phone className="icon" />
                  <p>+371 28 744 299</p>
                </div>
              </Link>

              <Link to="mailto:citrent@citrent.lv">
                <div className="contact-link">
                  <Mail className="icon" />
                  <p>citrent@citrent.lv</p>
                </div>
              </Link>

              <Link
                to="https://www.facebook.com/citrent/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="contact-link">
                  <Facebook className="icon" />
                  <p>Sazinies izmantojot Facebook</p>
                </div>
              </Link>
            </div>
          </div>

          <div className="divider"></div>

          <p className="work-hours">Darba dienās no 7:30 līdz 17:00</p>
          <p className="work-hours">Sestdienās, svētdienās slēgts</p>
        </div>

        <div className="wrapper-right">
          <h3>Kontaktforma</h3>
          {emailProgress === "compose" && (
            <>
              {contactItems?.length > 0 && (
                <>
                  <div className="selected-contact-items">
                    <h4>Izvēlētie produkti saziņai</h4>
                    <div className="selected-contact-items-list">
                      {contactItems?.map((item, index) => {
                        const link = item?.product_image;
                        const cleanedLink = link.replace(/^\/var\/www/, "/");

                        return (
                          <div className="selected-contact-item" key={index}>
                            <Link
                              to={`/rent/${item?.product_category_slug}/${item?.product_slug}`}
                            >
                              <div className="selected-contact-item-data">
                                <img
                                  src={cleanedLink}
                                  alt="select-contact-img"
                                />
                                <div className="selected-contact-item-name">
                                  <h5>{item?.product_name}</h5>
                                  <p>Preces kods: {item?.product_number}</p>
                                </div>
                              </div>
                            </Link>
                            <div className="selected-contact-item-remove">
                              <RemoveCircle
                                className="icon"
                                onClick={() => removeContactItem(item)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              <form onSubmit={handleEmail}>
                {alert && (
                  <>
                    <div
                      style={{
                        padding: 10,
                        width: "100%",
                        textAlign: "center",
                        backgroundColor: "#D0312D",
                        marginBottom: 20,
                      }}
                    >
                      <p style={{ fontSize: ".9rem", color: "var(--white)" }}>
                        {alert}
                      </p>
                    </div>
                  </>
                )}

                <div className="input">
                  <input
                    type="text"
                    placeholder="Vārds*"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>

                <div className="input">
                  <input
                    type="email"
                    placeholder="E-pasts*"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="input">
                  <input
                    type="number"
                    placeholder="Tālruņa nr."
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>

                <div className="input">
                  <textarea
                    maxLength="500"
                    placeholder="Jūsu ziņa*"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyUp={calculateMessageLength}
                    disabled={messageLength === 0}
                    required
                  ></textarea>
                  <p style={{ fontSize: ".9rem", textAlign: "end" }}>
                    Atlikušās rakstzīmes {messageLength}
                  </p>
                </div>

                <button type="submit" className="btn">
                  <p>Nosūtīt ziņu</p>
                </button>
              </form>
            </>
          )}

          {emailProgress === "being-sent" && (
            <>
              <LoadingContainer>
                <GridLoader color="#ff6a00" />
                <p>Nosūtam ziņu</p>
              </LoadingContainer>
            </>
          )}

          {emailProgress === "error" && (
            <>
              <LoadingContainer>
                <CloseOutline className="icon" style={{ color: "#D0312D" }} />
                <p style={{ marginBottom: 30 }}>Nevarējām nosūtīt e-pastu.</p>

                <div
                  className="btn"
                  onClick={() => setEmailProgress("compose")}
                >
                  <p>Nosūtīt ziņu atkārtoti</p>
                </div>
              </LoadingContainer>
            </>
          )}

          {emailProgress === "sent" && (
            <>
              <LoadingContainer>
                <CheckCircle className="icon" style={{ color: "#34B864" }} />
                <p style={{ marginBottom: 30 }}>Jūsu e-pasts ir nosūtīts.</p>

                <div className="btn" onClick={() => resetEmailInputs()}>
                  <p>Nosūtīt jaunu ziņu</p>
                </div>
              </LoadingContainer>
            </>
          )}
        </div>
      </div>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2176.688873004883!2d24.21884407722232!3d56.93699810016467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eecdef226be831%3A0xd553b626073f5d16!2zQnLEgcS8dSBLYXVkesSrxaF1IGllbGEgNDMsIExhdGdhbGVzIHByaWVrxaFwaWxzxJN0YSwgUsSrZ2EsIExWLTEwMjEsIExhdHZpYQ!5e0!3m2!1sen!2sfi!4v1704485176681!5m2!1sen!2sfi"
        width="600"
        height="450"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="map"
      ></iframe>
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 40px 0 0 0;

  .icon {
    min-width: 32px;
    max-width: 32px;
    width: 100%;
  }

  p {
    font-size: 0.9rem;
  }
`;

const Wrapper = styled.div`
  iframe {
    width: 100vw;
    height: 450px;
    border: none;
    margin: 20px 0 0 0;
    padding: 0;
  }

  .wrapper {
    max-width: 1100px;
    margin: 60px auto;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media only screen and (max-width: 1140px) {
      margin: 60px 20px;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      margin: 20px !important;

      .wrapper-left,
      .wrapper-right {
        width: 100% !important;
      }
    }

    .work-hours {
      font-size: 0.9rem;
      color: var(--grey);
      margin: 0 0 10px 0;
    }

    .divider {
      width: 100%;
      height: 2px;
      background: var(--grey);
      margin: 20px 0;
    }

    .wrapper-left {
      width: 49%;
      margin-bottom: 20px;

      .text-block {
        margin: 0 0 40px 0;

        .contact-links {
          display: flex;
          flex-direction: column;
          gap: 10px;

          a {
            text-decoration: none;
            color: var(--black);
            transition: 0.3s ease;

            .contact-link {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                max-width: 24px;
                max-height: 24px;
                width: 100%;
              }
            }
          }

          a:hover {
            opacity: 0.7;
          }
        }

        h3 {
          font-size: 1.2rem;
          margin: 0 0 20px 0;
        }

        p {
          font-size: 0.9rem;
        }
      }
    }

    .wrapper-right {
      width: 49%;
      max-width: 500px;

      .selected-contact-items {
        margin: 0 0 20px 0;

        h4 {
          font-size: 0.9rem;
          margin: 0 0 20px 0;
        }

        .selected-contact-items-list {
          .selected-contact-item {
            display: flex;
            gap: 20px;
            justify-content: space-between;
            margin: 0 0 20px 0;

            a {
              text-decoration: none;
              color: var(--black);

              .selected-contact-item-data {
                display: flex;
                gap: 10px;

                img {
                  max-width: 100px;
                  min-width: 100px;
                  max-height: 80px;
                  min-height: 80px;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 5px;
                }

                .selected-contact-item-name {
                  h5 {
                    font-size: 0.9rem;
                    max-width: 90%;
                  }

                  p {
                    font-size: 0.9rem;
                    color: var(--grey);
                  }
                }
              }

              &:hover {
                opacity: 0.8;
              }
            }

            .selected-contact-item-remove {
              .icon {
                max-width: 24px;
                width: 100%;
                color: var(--black);
                transition: 0.3s ease-in-out;
                cursor: pointer;
              }

              .icon:hover {
                color: #d0312d;
              }
            }
          }
        }
      }

      h3 {
        font-size: 1.2rem;
        margin: 0 0 20px 0;
      }
      .input {
        width: 100%;
        margin-bottom: 10px;

        input,
        textarea {
          width: 100%;
        }

        input {
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          outline: none;
          padding: 15px 20px;
          font-size: 0.9rem;
          color: var(--black);
        }

        textarea {
          min-height: 200px;
          padding: 15px 20px;
          border-radius: 5px;
          outline: none;
          border: 1px solid var(--lightgrey);
          resize: vertical;
          color: rgb(18, 19, 21);
          font-size: 0.8rem;
          transition: all 0.4s ease-in-out 0s;
          font-family: Inter, sans-serif;
          font-weight: 400;
          width: 100% !important;
          font-size: 0.9rem;
          color: var(--black);
        }

        input::placeholder,
        textarea::placeholder {
          font-size: 0.9rem;
          color: grey;
        }
      }

      .btn {
        padding: 10px 25px;
        background-color: var(--orange);
        display: inline-block;
        border-radius: 5px;
        transition: 0.3s ease;
        width: 100%;
        text-align: center;
        cursor: pointer;
        outline: none;
        border: none;

        p {
          color: var(--white);
          font-weight: bold;
          transition: 0.3s ease;
          font-size: 0.9rem;
        }
      }

      .btn:hover {
        opacity: 0.7;
      }
    }
  }
`;

export default Contact;
