import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import axios from "axios";

//config
import { getUsersApi, deleteUserApi } from "../firebase/globalConfig";

//components
import Header from "../components/Header";
import AppLogin from "./AppLogin";
import Heading from "../components/Heading";
import Banner from "../components/Banner";
import GridLoader from "react-spinners/GridLoader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import UserBar from "../components/UserBar";

//icons
import { Users } from "@styled-icons/heroicons-outline/Users";

//redux
import { useSelector } from "react-redux";
import { selectUser } from "../redux/appSlice";

const AppUsers = () => {
  const user = useSelector(selectUser);
  const [users, setUsers] = useState([]);
  const [usersFromDb, setUsersFromDb] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState("");
  let selectedUser = null;

  const getUsers = async () => {
    const response = await axios.get(`${getUsersApi}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      },
    });
    const data = response?.data;
    setUsers(data);
    setUsersFromDb(data);
    setLoading(false);
  };

  const searchUser = (name) => {
    if (name?.length > 0) {
      const filteredData = usersFromDb.filter((item) => {
        return item.display_name.toLowerCase().includes(name.toLowerCase());
      });

      setUsers(filteredData);
    } else {
      setUsers(usersFromDb);
    }
  };

  const options = {
    title: "Vai dzēst lietotāju?",
    message: "Lietotājs tiks izdzēsts neatgriezeniski.",
    buttons: [
      {
        label: "Dzēst",
        onClick: () => {
          deleteUser();
        },
      },
      {
        label: "Atcelt",
        onClick: () => {
          selectedUser = null;
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    // willUnmount: () => {},
    // afterClose: () => {},
    // onClickOutside: () => {},
    // onKeypress: () => {},
    // onKeypressEscape: () => {},
    overlayClassName: "confirm-modal",
  };

  const deleteUser = async () => {
    const created = new Date();
    created.setHours(created.getHours() + 2);

    // Format the date as needed
    const formattedCreated = created
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const deleteData = {
      google_id: selectedUser.google_id,
      email: user?.email,
      display_name: user?.displayName,
      noti_type: "user_deleted",
      avatar: user?.photoURL,
      noti_text: `Izdzēsa lietotāju ${selectedUser.display_name}`,
      created: formattedCreated,
    };

    try {
      await axios.post(`${deleteUserApi}`, deleteData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      getUsers();
      toast.success("Lietotājs dzēsts.");
    } catch (error) {
      toast.error("Nevarējām izdzēst lietotāju. Mēģini vēlreiz.");
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    searchUser(searchName);
    //eslint-disable-next-line
  }, [searchName]);

  return (
    <Wrapper>
      {user ? (
        <>
          <Header />
          <div className="container">
            <Heading name={"Lietotāji"} desc={"Lietotāju saraksts"} />
            {loading ? (
              <LoadingContainer>
                <GridLoader color="#ff6a00" />
              </LoadingContainer>
            ) : (
              <>
                <div className="container-top">
                  <Banner
                    Icon={Users}
                    name={"Lietotāji"}
                    count={users?.length}
                  />
                  <div className="search-box">
                    <div className="search-box-input">
                      <h3>Meklēt</h3>
                      <input
                        type="text"
                        className="search-input"
                        placeholder="Meklēt lietotāju pēc vārda"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                      />
                    </div>

                    <div className="search-box-btn">
                      <Link to="/app/users/add">
                        <div className="search-box-btn-action">
                          <p>Pievienot</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="container-bottom">
                  <div className="table-top">
                    <p>Nr. p. k.</p>
                    <p>Kategorija</p>
                    <p>Vārds</p>
                    <p>E-pasts</p>
                    <p>Pievienoja</p>
                    <p>Iespējas</p>
                  </div>

                  <div className="table-data">
                    {users?.map((item, index) => {
                      const {
                        email,
                        google_id,
                        display_name,
                        invited_by,
                        avatar,
                        joined,
                      } = item;

                      const datetimeString = `${joined}`;
                      const date = new Date(datetimeString);

                      const year = date.getFullYear();
                      const month = String(date.getMonth() + 1).padStart(
                        2,
                        "0"
                      ); // Month is zero-based
                      const day = String(date.getDate()).padStart(2, "0");

                      const hours = String(date.getHours()).padStart(2, "0");
                      const minutes = String(date.getMinutes()).padStart(
                        2,
                        "0"
                      );

                      const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}`;

                      return (
                        <div className="table-data-single" key={index}>
                          <div className="table-data-single-box">
                            {index + 1}.
                          </div>
                          <div className="table-data-single-box">
                            {item?.class}
                          </div>
                          <div className="table-data-single-box">
                            <div className="user-name">
                              <Link to={`/app/users/${google_id}`}>
                                <UserBar img={avatar} name={display_name} />
                              </Link>
                            </div>
                            <p className="user-added" style={{ marginTop: 5 }}>
                              Pievienojies {formattedDate}
                            </p>
                          </div>
                          <div className="table-data-single-box">{email}</div>
                          <div className="table-data-single-box">
                            {invited_by}
                          </div>
                          <div className="table-data-single-box">
                            {item?.class !== "dev" && (
                              <Link to={`/app/users/edit/${google_id}`}>
                                <div className="table-data-single-box-btn">
                                  Labot
                                </div>
                              </Link>
                            )}
                            {google_id !== user?.uid &&
                              item?.class !== "dev" && (
                                <div
                                  className="table-data-single-box-btn"
                                  onClick={() => {
                                    confirmAlert(options);
                                    selectedUser = item;
                                  }}
                                >
                                  Dzēst
                                </div>
                              )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <AppLogin />
      )}
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  overflow-x: hidden;

  .react-confirm-alert-body {
    width: 300px !important;
    /* font-family: Roboto; */

    h1 {
      margin: 0 0 10px 0 !important;
    }
  }

  .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .container-bottom {
      margin: 20px 0 0 0;
      border: 1px solid var(--lightgrey);
      border-radius: 5px;
      overflow: scroll;
      max-height: 600px;

      .table-top {
        padding: 20px;
        border-bottom: 1px solid var(--lightgrey);
        display: flex;
        gap: 10px;
        position: sticky;
        top: 0px;
        background: var(--white);
        min-width: 750px;

        p {
          font-size: 0.9rem;
          font-weight: 600;
          max-width: 200px;
          min-width: 150px;
          width: 100%;
        }

        p:nth-child(1) {
          max-width: 80px;
          min-width: 80px;
        }

        p:nth-child(2) {
          max-width: 100px;
          min-width: 100px;
        }
      }

      .table-data {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .table-data-single {
          display: flex;
          gap: 10px;

          .table-data-single-box {
            font-size: 0.9rem;
            max-width: 200px;
            min-width: 150px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: no-wrap;

            .user-name {
              font-size: 0.9rem;
              display: flex;
              flex-direction: column;
              gap: 5px;

              a {
                color: var(--black);
                text-decoration: none;
                transition: 0.3s ease-in-out;
              }

              a:hover {
                opacity: 0.5;
              }

              .user-avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                object-fit: contain;
              }
            }

            .user-added {
              font-size: 0.7rem;
              color: var(--grey);
            }

            .table-data-single-box-btn {
              font-size: 0.8rem;
              background: var(--black);
              color: var(--white);
              border-radius: 5px;
              display: inline-block;
              padding: 5px 10px;
              cursor: pointer;
              transition: 0.3s ease-in-out;
              margin: 0 10px 10px 0;
            }

            .table-data-single-box-btn:hover {
              opacity: 0.7;
            }
          }

          .table-data-single-box:nth-child(1) {
            max-width: 80px;
            min-width: 80px;
          }

          .table-data-single-box:nth-child(2) {
            max-width: 100px;
            min-width: 100px;
            text-transform: capitalize;
          }
        }
      }
    }

    .container-top {
      display: flex;
      gap: 10px;

      @media only screen and (max-width: 650px) {
        flex-direction: column;
      }

      .search-box {
        display: flex;
        flex: 1 0 0;
        gap: 10px;

        .search-box-input {
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
          justify-content: end;

          h3 {
            font-size: 1.2rem;
            color: var(--black);
            margin: 0 0 10px 0;
          }

          input {
            width: 100%;
            outline: none;
            border: 1px solid var(--lightgrey);
            border-radius: 5px;
            font-size: 0.9rem;
            padding: 10px 20px;
          }

          input::placeholder {
            font-size: 0.9rem;
            color: var(--grey);
          }
        }

        .search-box-btn {
          display: flex;
          align-items: flex-end;

          a {
            text-decoration: none;
            color: var(--white);
          }

          .search-box-btn-action {
            background: var(--orange);
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            transition: 0.3s ease-in-out;

            p {
              color: var(--white);
              font-size: 0.9rem;
            }
          }

          .search-box-btn-action:hover {
            background: var(--lightorange);
          }
        }
      }
    }
  }
`;

export default AppUsers;
