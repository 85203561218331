import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

//icon
import { ArrowIosDownward } from "@styled-icons/evaicons-solid/ArrowIosDownward";
import { Exit } from "@styled-icons/icomoon/Exit";

//components
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//redux
import { useSelector } from "react-redux";
import { selectUser, selectUserData } from "../redux/appSlice";

//firebase
import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const navigate = useNavigate();

  const logOut = () => {
    signOut(auth);
    navigate("/app");
  };

  return (
    <Wrapper>
      <div className="wrapper">
        <img src="/images/main/logo.png" alt="app-logo" className="app-logo" />

        <nav>
          <Link to="/app">Panelis</Link>
          <Link to="/app/categories">Kategorijas</Link>
          <Link to="/app/products">Tehnika</Link>
          {userData?.class === "admin" && (
            <>
              <Link to="/app/users">
                Lietotāji <span>Admin</span>
              </Link>
            </>
          )}

          {userData?.class === "dev" && (
            <>
              <Link to="/app/users">
                Lietotāji <span>Developer</span>
              </Link>
            </>
          )}
        </nav>

        <div className="user" onClick={() => setIsOpen(!isOpen)}>
          <img src={user?.photoURL} alt="user-avatar" className="user-avatar" />
          <ArrowIosDownward
            className="icon"
            style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}
          />

          {isOpen && (
            <div className="user-options">
              <div className="user-options-mobile">
                <Link to="/app">Panelis</Link>
                <Link to="/app/categories">Kategorijas</Link>
                <Link to="/app/products">Tehnika</Link>
                {userData?.class === "admin" && (
                  <>
                    <Link to="/app/users">Lietotāji</Link>
                  </>
                )}

                {userData?.class === "dev" && (
                  <>
                    <Link to="/app/users">Lietotāji</Link>
                  </>
                )}
              </div>

              <div className="user-option" onClick={() => logOut()}>
                <p>Iziet</p> <Exit className="icon" />
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  background: var(--black);
  padding: 0 20px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;

  .wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;

    .app-logo {
      max-width: 160px;
      width: 100%;
      object-fit: contain;
    }

    nav {
      display: flex;
      gap: 15px;
      align-items: center;

      @media only screen and (max-width: 740px) {
        display: none;
      }

      a {
        text-decoration: none;
        padding: 8px 15px;
        color: var(--white);
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        position: relative;
        font-size: 0.9rem;

        span {
          position: absolute;
          background: var(--orange);
          color: var(--white);
          font-size: 0.6rem;
          padding: 4px 8px;
          border-radius: 5px;
          top: -10px;
          right: -15px;
        }
      }

      a:hover {
        background: #ff6a0038;
        color: var(--orange);
      }
    }

    .user {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      padding: 3px 6px;
      border-radius: 5px;
      position: relative;

      .user-options {
        position: absolute;
        background: var(--white);
        bottom: -45px;
        left: -25px;
        width: 110px;
        border-radius: 5px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        @media only screen and (max-width: 740px) {
          bottom: -195px;
        }

        .user-options-mobile {
          flex-direction: column;
          display: none;

          @media only screen and (max-width: 740px) {
            display: flex;
          }

          a {
            text-decoration: none;
            padding: 10px 15px;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            border-radius: 5px;
            font-size: 0.9rem;
            color: var(--black);
          }

          a:hover {
            font-weight: 600;
          }
        }

        .user-option {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 15px;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          border-radius: 5px;

          p {
            font-size: 0.9rem;
            color: var(--black);
          }

          .icon {
            min-width: 24px;
            max-width: 24px;
            width: 100%;
            color: var(--black);
          }
        }

        .user-option:hover {
          p {
            font-weight: 600;
          }
        }
      }

      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }

      .icon {
        max-width: 24px;
        min-width: 24px;
        color: var(--white);
        transition: 0.1s ease-in-out;
      }
    }

    .user:hover {
      background: #80808052;
    }
  }
`;

// max-width: 160px;
// width: 100%;

export default Header;
