import React from "react";
import styled from "styled-components";

//components
import PageHeading from "../components/PageHeading";

//hemlet
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  const text = `
  Pēdējās izmaiņas: 13.12.2023

  Sveicināti uzņēmumā CitRent!
  
  Šī privātuma politika ir izstrādāta, lai informētu mūsu klientus un lietotājus par to, kā mēs apkopojam, izmantojam un aizsargājam personas datus. Mēs pilnībā apzināmies jūsu tiesības uz privātumu un pielietojam visus nepieciešamos pasākumus, lai to nodrošinātu.
  
  1. Informācija par uzņēmumu CitRent
  Uzņēmums CitRent tika dibināts 2018. gada 3. maijā, un tas specializējas būvtehnikas nomā. Mūsu darbinieki apvieno vairāk nekā divdesmit gadu pieredzi nozares segmentā. CitRent ir 100% Latvijas uzņēmums, kuram nav ārvalstu investoru, un tas nav kāda liela pasaules koncerna meitas uzņēmums.
  
  2. Kāda informācija tiek apkopota?
  Mēs varam apkopot un apstrādāt šādu informāciju:
  
  Klientu dati: Vārds, uzvārds, kontaktinformācija un citas saistītās personas datu kategorijas, kas nepieciešamas mūsu pakalpojumu sniegšanai.
  
  Vietnes lietotāju dati: Informācija par lietotāja pieredzi mūsu vietnē, tostarp sīkfaili un citi identifikatori.
  
  Saziņas dati: Informācija, kas tiek apkopota, ja jūs sazināties ar mums, izmantojot mūsu vietni, e-pastu vai citus saziņas līdzekļus.
  
  Statistikas dati: Informācija par to, kā jūs izmantojat mūsu pakalpojumus, lai uzlabotu lietotāja pieredzi.
  
  3. Kāpēc mēs apkopojam šo informāciju?
  Mēs apkopjam jūsu personas datus, lai:
  
  Nodrošinātu mūsu pakalpojumu sniegšanu un uzlabotu to kvalitāti.
  
  Pārvaldītu klientu attiecības un nodrošinātu klientu atbalstu.
  
  Personalizētu mūsu pakalpojumus un piedāvātu pielāgotu saturu.
  
  Veiktu statistisko analīzi un uzlabotu mūsu vietnes funkcionalitāti.
  
  4. Kā mēs aizsargājam jūsu informāciju?
  Mēs ļoti nopietni uztveram jūsu datu aizsardzību. Mēs pielietojam drošas tehnoloģijas un procedūras, lai novērstu neatļautu piekļuvi, izmaiņas, iznīcināšanu vai atklāšanu.
  
  5. Ar ko mēs varētu dalīties?
  Mēs nekādos apstākļos nedalīsimies ar jūsu personas datiem trešajām personām bez jūsu piekrišanas, izņemot gadījumus, kad to pieprasa likums vai tiesas nolēmums.
  
  6. Jūsu tiesības un izvēles
  Jums ir tiesības:
  
  Pieprasīt piekļuvi savai personīgajai informācijai.
  
  Labot vai dzēst jūsu personas datus.
  
  Ierobežot vai iebilst datu apstrādei.
  
  7. Saistībā ar sīkdatnēm
  Mūsu vietne izmanto sīkdatnes, lai uzlabotu jūsu pieredzi. Sīkāku informāciju par sīkdatnēm varat atrast mūsu Sīkdatņu politikā.
  
  8. Kontakti
  Ja jums ir jautājumi par mūsu privātuma politiku vai jūsu personas datiem, lūdzu, sazinieties ar mums:
  
  CitRent SIA
  Adrese: Brāļu Kaudzīšu iela 43, Latgales priekšpilsēta, Rīga, LV-1021, Latvija
  E-pasts: citrent@citrent.lv
  Tālrunis: +371 28 744 299
  
  Mēs paturam tiesības periodiski atjaunināt šo privātuma politiku, un jauniem noteikumiem stājas spēkā, tie tiks publicēti mūsu vietnē.
  
  Paldies, ka izvēlējāties CitRent!
  `;

  const paragraphs = text.split("\n").map((line, index) => (
    <p
      key={index}
      style={{
        marginTop: line.trim().match(/^\d+\.\s/) ? "1em" : 0,
        fontWeight: line.trim().match(/^\d+\.\s/) ? "bold" : "normal",
        fontSize: line.trim().match(/^\d+\.\s/) ? "1.2rem" : ".9rem",
      }}
    >
      {line}
    </p>
  ));

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privātuma politika - Citrent</title>
      </Helmet>
      <PageHeading
        pageName={"Privātuma politika"}
        pageImage={"./images/pageHeading/about.png"}
      />
      <div className="wrapper">{paragraphs}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 0 200px 0;

    @media only screen and (max-width: 1140px) {
      margin: 0 20px;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.6;
    }
  }
`;

export default PrivacyPolicy;
