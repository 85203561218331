import React, { useEffect, useState } from "react";
import styled from "styled-components";

//router
import { Link } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { selectCategories } from "../../app/redux/appSlice";

const Categories = () => {
  const categoriesFromRedux = useSelector(selectCategories);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const sortedCategories = [...categoriesFromRedux].sort(
      (a, b) => a.category_position - b.category_position
    );

    setCategories(sortedCategories);
  }, [categoriesFromRedux]);

  return (
    <Wrapper>
      <div className="categories-wrapper">
        <div className="categories">
          {categories?.length > 0 &&
            categories?.map((item, index) => (
              <Link
                key={index}
                to={`/rent/${item?.link}`}
                onClick={() => window.scrollTo(0, 0)}
              >
                <div className="category">
                  <img
                    src={`/images/categories/${item?.category_icon}`}
                    alt="category-icon"
                  />
                  <div className="category-name">
                    <p>{item?.category_name}</p>
                    <div className="category-slide" />
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 50px auto 30px;

  .categories-wrapper {
    @media only screen and (max-width: 1140px) {
      padding: 0 20px;
    }

    .categories {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      a {
        flex: 1 0 0;
        min-width: 200px;
        max-width: 300px;
        width: 100%;
        text-decoration: none;

        @media only screen and (max-width: 600px) {
          min-width: 45%;
          max-width: 50%;
        }
      }

      a:nth-last-child(1) {
        max-width: 212px;

        @media only screen and (max-width: 1097px) {
          max-width: 300px;
        }
      }

      .category {
        background-color: var(--lightgrey);
        padding: 30px 15px 10px;
        cursor: pointer;
        transition: 0.3s ease;
        height: 100%;
        min-height: 150px;
        max-height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        border-radius: 5px;

        img {
          width: 64px;
          height: 64px;
          transition: 0.3s ease;

          @media only screen and (max-width: 740px) {
            width: 42px;
            height: 42px;
          }
        }

        .category-name {
          margin: 5px 0 0 0;
          overflow: hidden;

          p {
            color: var(--black);
            font-size: 0.9rem;
            text-transform: uppercase;
            font-weight: bold;
            transition: 0.3s ease;
          }

          .category-slide {
            width: 50%;
            height: 3px;
            background-color: var(--orange);
            margin-top: 5px;
            transition: 0.3s ease;
          }
        }

        &:hover {
          background-color: var(--lightorange);

          img {
            filter: invert(1);
          }

          p {
            color: var(--white);
          }

          .category-slide {
            background-color: var(--white);
          }
        }
      }
    }
  }
`;

export default Categories;
