import React from "react";
import styled from "styled-components";

//icons
import { ArrowCircleDownOutline } from "@styled-icons/evaicons-outline/ArrowCircleDownOutline";

const PageHeading = ({ pageName, pageImage }) => {
  return (
    <Wrapper>
      <img src={pageImage} alt="page-img" />

      <div className="page-heading-text">
        <h3>{pageName}</h3>
        <ArrowCircleDownOutline className="icon" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1110px;
  height: 300px;
  position: relative;
  margin: 0 auto 20px;

  @media only screen and (max-width: 736px) {
    height: 200px;
  }


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(.5);
}

  .page-heading-text {
    position: absolute;
    bottom: 30px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);

    h3 {
        color: var(--white);
        font-size: 1.6rem;
        margin: 0 0 10px 0;
    }

    .icon {
        max-width: 24px;
        max-height: 24px;
        width: 100%;
        height: 100%;
        color: var(--white);
    }
}
`;

export default PageHeading;
