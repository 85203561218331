import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAwcKpgbVrbDiTJxQr1QLdMUkd4q1NWnv8",
  authDomain: "citrent.firebaseapp.com",
  projectId: "citrent",
  storageBucket: "citrent.appspot.com",
  messagingSenderId: "381238384445",
  appId: "1:381238384445:web:32e5160d86524140d69754",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
