import React, { useState, useEffect } from "react";
import styled from "styled-components";

//components
import { Carousel } from "@trendyol-js/react-carousel";
import { Link } from "react-router-dom";

//icons
import { ArrowRightShort } from "@styled-icons/bootstrap/ArrowRightShort";
import { ShoppingCart } from "@styled-icons/heroicons-solid/ShoppingCart";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  updateContactItems,
  selectContactItems,
} from "../../app/redux/appSlice";

const ItemSlider = ({ heading, items }) => {
  const [itemList, setItemList] = useState(items);
  const [showItems, setShowItems] = useState(() => calculateShowItems());

  function calculateShowItems() {
    const viewportWidth = window.innerWidth;

    if (viewportWidth >= 1050) {
      return 3.6;
    } else if (viewportWidth >= 600) {
      return 2.6;
    } else if (viewportWidth >= 530) {
      return 1.8;
    } else {
      return 1.5;
    }
  }

  const updateShowItems = () => {
    setShowItems(calculateShowItems());
  };

  //redux
  const dispatch = useDispatch();
  const contactItems = useSelector(selectContactItems);

  const addContactItem = (item) => {
    window.scrollTo(0, 0);
    const contactItemsList = contactItems;

    const contactItemsFiltered = contactItemsList.filter(
      (i) => i?.product_number === item?.product_number
    );

    if (contactItemsFiltered?.length < 1) {
      const contactItemsUpdated = [...contactItemsList, item];

      dispatch(updateContactItems(contactItemsUpdated));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateShowItems);

    return () => {
      window.removeEventListener("resize", updateShowItems);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (items) {
      // console.log(items);
      setItemList(items);
    }
  }, [items]);

  return (
    <Wrapper>
      {itemList?.length > 0 && (
        <>
          <h3 className="heading">{heading}</h3>

          <div className="carousel-slider">
            {itemList?.length > 0 && (
              <>
                <Carousel show={showItems} slide={2} swiping={true}>
                  {itemList?.map((item, index) => {
                    const link = item?.product_image;
                    const cleanedLink = link.replace(/^\/var\/www/, "/");

                    return (
                      <div className="carousel-item" key={index}>
                        <div className="carousel-img-container">
                          <Link
                            to={`/rent/${item?.product_category_slug}/${item?.product_slug}`}
                            className="carousel-item-name"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            <img src={cleanedLink} alt="carousel-img" />
                          </Link>
                        </div>
                        <div className="carousel-item-data">
                          <p className="carousel-item-code">
                            Preces kods: {item?.product_number}
                          </p>
                          <Link
                            to={`/rent/${item?.product_category_slug}/${item?.product_slug}`}
                            className="carousel-item-name"
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            <h3>{item?.product_name}</h3>
                            <ArrowRightShort className="icon arrow-right-short" />
                          </Link>

                          <div className="product-price-amount-wrapper">
                            {parseInt(item?.product_price) === 0 ? (
                              <>
                                <p className="product-price-amount">
                                  Cena pēc pieteikuma
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="product-price-amount-large">
                                  €{item?.product_price}
                                </p>

                                <p className="product-price-amount-small">
                                  {`ar PVN 21% €${(
                                    item?.product_price * 1.21
                                  ).toFixed(2)}`}
                                </p>
                              </>
                            )}
                          </div>

                          <Link
                            to="/contact"
                            onClick={() => addContactItem(item)}
                          >
                            <div className="carousel-btn">
                              <ShoppingCart className="icon" />
                              <p className="carousel-btn-text">
                                Uzzināt vairāk
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </>
            )}
          </div>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 30px auto;

  .carousel-slider {
    .styles-module_carousel-arrow__26sRw {
      z-index: 30;
    }

    button[data-direction="right"] {
      background: url("/images/main/arrow-right.svg") no-repeat;
      background-color: var(--white);
      background-position: center;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      height: 44px;
      width: 44px;
      outline: 0;
      position: absolute;
      top: 140px;
      right: 30px;
      transition: 0.2s ease-in-out;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px;

      &:hover {
        background-color: var(--orange);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      }
    }

    button[data-direction="left"] {
      background: url("/images/main/arrow-left.svg") no-repeat;
      background-color: var(--white);
      background-position: center;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      height: 44px;
      width: 44px;
      outline: 0;
      position: absolute;
      top: 140px;
      left: 30px;
      z-index: 1;
      transition: 0.2s ease-in-out;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
        rgba(0, 0, 0, 0.22) 0px 15px 12px;

      &:hover {
        background-color: var(--orange);
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      }
    }
  }

  .carousel-item {
    max-width: 270px;
    min-height: 400px;
    overflow: hidden;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-price-amount-wrapper {
      .product-price-amount-large {
        font-size: 1.2rem;
        color: var(--black);
        font-weight: bold;
      }
    }

    &:hover {
      /* transform: scale(1.1);
      box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */

      .carousel-img-container {
        img {
          transform: scale(1.2);
        }
      }
    }

    .carousel-img-container {
      height: 200px;
      width: 100%;
      overflow: hidden;
      border-radius: 5px;

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        transition: 0.3s ease-in-out;
        border-radius: 5px;
      }
    }

    .carousel-item-data {
      margin: 20px 0 0 0;
      overflow: hidden;

      h3 {
        font-size: 0.9rem;
        max-width: 200px;
        color: var(--black);
        margin: 0 0 5px 0;
      }

      p {
        font-size: 0.8rem;
        color: var(--grey);
        transition: 0.3s ease;
      }

      a {
        text-decoration: none;
      }

      .carousel-item-name {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 15px 0;
        transition: 0.3s ease-in-out;

        .icon {
          min-width: 24px;
          max-width: 24px;
          width: 100%;
          color: var(--orange);
          transition: 0.3s ease-in-out;
          position: relative;
          right: 10px;
        }

        &:hover {
          opacity: 0.7;

          .icon {
            right: 0;
          }
        }
      }

      .carousel-btn {
        border: 1px solid var(--lightgrey);
        border-radius: 10px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        gap: 15px;
        margin: 20px 0 0 0;
        transition: 0.3s ease-in-out;

        .carousel-btn-text {
          color: var(--black);
          font-weight: 600;
        }

        .icon {
          min-width: 24px;
          max-width: 24px;
          width: 100%;
          color: var(--black);
          transition: 0.3s ease-in-out;
        }
      }

      .carousel-btn:hover {
        background: var(--green);

        .carousel-btn-text,
        .icon {
          color: var(--white);
        }
      }
    }
  }

  .heading {
    font-size: 1.1rem;
    margin: 0 0 40px 0;
  }

  @media only screen and (max-width: 1140px) {
    margin: 30px 20px;
  }
`;

export default ItemSlider;
