import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import axios from "axios";

//components
import Header from "../components/Header";
import AppLogin from "./AppLogin";
import Heading from "../components/Heading";
import GridLoader from "react-spinners/GridLoader";

//config
import { checkUserApi, getUserNotifications } from "../firebase/globalConfig";

//redux
import { selectUser } from "../redux/appSlice";
import { useSelector } from "react-redux";

const AppUsersSingle = () => {
  const params = useParams();
  const user = useSelector(selectUser);

  const { id } = params;
  const [userId] = useState(id);

  const [userData, setUserData] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUserData = async () => {
    // console.log("getting user data");
    const response = await axios.post(
      `${checkUserApi}`,
      {
        googleId: userId,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      }
    );

    if (response.data.exists) {
      // If the Google ID exists in the database
      const userFirebase = {
        displayName: response?.data?.userData?.display_name,
        email: response?.data?.userData?.email,
        photoURL: response?.data?.userData?.avatar,
      };

      setUserData(userFirebase);
      //   console.log(userFirebase);
    }
  };

  const getActivities = async () => {
    const response = await axios.get(
      `${getUserNotifications}?email=${userData?.email}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      }
    );
    // console.log(response.data.notifications);
    setNotifications(response.data.notifications);
    setLoading(false);
  };

  useEffect(() => {
    if (userId) {
      getUserData();
    }

    //eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (userData) {
      getActivities();
    }

    //eslint-disable-next-line
  }, [userData]);

  return (
    <Wrapper>
      {user ? (
        <>
          <Header />
          <div className="container">
            <Heading
              name={"Lietotāja informācija"}
              desc={"Šeit vari redzēt lietotāja pēdējās aktivitātes sistēmā"}
            />

            {loading ? (
              <>
                <LoadingContainer>
                  <GridLoader color="#ff6a00" />
                </LoadingContainer>
              </>
            ) : (
              <>
                <div className="add-container">
                  <div
                    className="add-container-input-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 20,
                    }}
                  >
                    <img
                      src={userData?.photoURL}
                      alt="user-avatar"
                      className="user-image"
                    />
                  </div>
                  <div className="add-container-input-container">
                    <input
                      type="text"
                      placeholder="E-pasts"
                      className="add-container-input"
                      value={userData?.displayName}
                      disabled
                    />
                  </div>

                  <div className="add-container-input-container">
                    <input
                      type="email"
                      placeholder="E-pasts"
                      className="add-container-input"
                      value={userData?.email}
                      disabled
                    />
                  </div>

                  {notifications?.length > 0 && (
                    <h3>Lietotāja pēdējās aktivitātes</h3>
                  )}

                  <div
                    className="add-container-input-container"
                    style={{ overflowY: "scroll", maxHeight: "400px" }}
                  >
                    {notifications?.map((item, index) => {
                      const created = `${item?.created}`;
                      const createdDate = new Date(created);
                      const createdYear = createdDate.getFullYear();
                      const createdMonth = String(
                        createdDate.getMonth() + 1
                      ).padStart(2, "0"); // Month is zero-based
                      const createdDay = String(createdDate.getDate()).padStart(
                        2,
                        "0"
                      );
                      const createdHours = String(
                        createdDate.getHours()
                      ).padStart(2, "0");
                      const createdMinutes = String(
                        createdDate.getMinutes()
                      ).padStart(2, "0");

                      return (
                        <div className="info-boxes-item" key={index}>
                          <div
                            className="info-boxes-item-data"
                            style={{ maxWidth: "400px" }}
                          >
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              to={item?.noti_link}
                            >
                              <div className="info-boxes-notification-text">
                                <p>{item?.noti_text}</p>
                              </div>
                            </Link>
                            <p>{`${createdHours}:${createdMinutes} ${createdDay}.${createdMonth}.${createdYear}`}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <AppLogin />
      )}
    </Wrapper>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  .container {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .add-container {
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid var(--lightgrey);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      h3 {
        font-size: 0.9rem;
        margin: 20px 0;
      }

      .user-image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      .add-container-input-container {
        width: 100%;

        .add-container-input {
          width: 100%;
          outline: none;
          border: 1px solid var(--lightgrey);
          border-radius: 5px;
          font-size: 0.9rem;
          padding: 15px 20px;
        }

        .info-boxes-item {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          border-bottom: 1px solid var(--lightgrey);
          padding: 0 0 20px 0;
          margin: 0 0 20px 0;

          a {
            color: var(--black);
            text-decoration: none;
            transition: 0.3s ease-in-out;
          }

          a:hover {
            opacity: 0.5;
          }

          p {
            font-size: 0.9rem;
            color: var(--grey);
          }

          .info-boxes-notification-text {
            margin: 0 0 10px 0;

            p {
              color: var(--black) !important;
              line-height: 1.4;
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
`;

export default AppUsersSingle;
